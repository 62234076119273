import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { getUserDetailsAPI } from '../../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import { useParams } from 'react-router-dom';
import moment  from 'moment' ;
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ActionCell } from '../../../../components/common/dataGrid/ActionCell'
import { getAssessmentCompletedScoreListAPI } from '../../../../services/userService';
import { MatDataGrid } from '../../../../components/common/dataGrid/DataGrid'
import { Level1 } from './level1';
import { DetailsLevel } from '../levels/details';

const customTheme = createTheme({
    typography: {
        fontFamily: '"Source Sans Pro", sans-serif',
        htmlFontSize: 17,
    },
    palette: {
        primary: {
            main: '#EB0024',
        },
        text: {
            primary: '#000000',
            secondary: '#999999',
        }
    }
});

export const DetailsAssessment = () => {
    
    const [userData,setUserData] = useState({})
    const [mutation, setMutation] = useState(true);
    const {id} = useParams(); 
    const [userId, setUserId] = useState(id);
    const [loading, setLoading] = useState(true)

    const [selectedValue, setSelectedValue] = useState('');
    
    const [page, setPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage,setCurrentPage] = useState(1)
    const [sortColumn,setSortColumn] = useState('id');
    const [sortDirection,setSortDirection] = useState('desc');
    const [userEventData,setUserEventData] = useState({});
    const [selectedDisplayLevel, setSelectedDisplayLevel] = useState(null);
    const [categoryId_1, setCategoryId_1] = useState(null);



    const columns: any = [
        // {
        //     field: 'member_name',
        //     headerName: 'Name',
        //     editable: false,
        //     flex: 1,
        //     minWidth: 250,
        //     maxWidth: 250,
        //     disableColumnMenu: true,
        //     sortable: false,
        //     //renderCell: (props) => { return <span> `${member_name}`</span> }
            
        // },
        {
            field: 'Attachmentlevel',
            headerName: 'Level',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
            renderCell: (props) => { return props.row.Attachmentlevel !='-' ?   <button className='btn btn-link' onClick={() => { setSelectedDisplayLevel(props.row.Attachmentlevel);  setCategoryId_1('1'); } }>{props.row.Attachmentlevel}</button> : '-' }
        },
        {
            field: 'AttachmentResults',
            headerName: 'Results',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
        },
        {
            field: 'AttachmentDate',
            headerName: 'Date',
            editable: false,
            flex: 0.60,
            minWidth: 105,
            maxWidth: 105,
            sortable: false,
            align: 'center',
        },
        {
            field: 'Busynesslevel',
            headerName: 'Level',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
            renderCell: (props) => { return props.row.Busynesslevel != '-' ? <button className='btn btn-link' onClick={() => { setSelectedDisplayLevel(props.row.Busynesslevel);  setCategoryId_1('2'); } }>{props.row.Busynesslevel}</button>  : '-' }
    
        },
        {
            field: 'BusynessResults',
            headerName: 'Results',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
        },
        {
            field: 'BusynessDate',
            headerName: 'Date',
            editable: false,
            flex: 0.60,
            minWidth: 105,
            maxWidth: 105,
            sortable: false,
            align: 'center',
        },
        {
            field: 'ExecutiveFunctionlevel',
            headerName: 'Level',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
            renderCell: (props) => { return props.row.ExecutiveFunctionlevel != '-' ? <button className='btn btn-link' onClick={() => { setSelectedDisplayLevel(props.row.ExecutiveFunctionlevel);  setCategoryId_1('4'); } }>{props.row.ExecutiveFunctionlevel}</button> : '-' }
        },
        {
            field: 'ExecutiveFunctionResults',
            headerName: 'Results',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
        },
        {
            field: 'ExecutiveFunctionDate',
            headerName: 'Date',
            editable: false,
            flex: 0.60,
            minWidth: 105,
            maxWidth: 105,
            sortable: false,
            align: 'center',
        },
        {
            field: 'Neurodivergencelevel',
            headerName: 'Level',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
            renderCell: (props) => { return props.row.Neurodivergencelevel != '-' ? <button className='btn btn-link' onClick={() => { setSelectedDisplayLevel(props.row.Neurodivergencelevel);  setCategoryId_1('5'); } }>{props.row.Neurodivergencelevel}</button> : '-' }
        },
        {
            field: 'NeurodivergenceResults',
            headerName: 'Results',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
        },
        {
            field: 'NeurodivergenceDate',
            headerName: 'Date',
            editable: false,
            flex: 0.60,
            minWidth: 105,
            maxWidth: 105,
            sortable: false,
            align: 'center',
        },
        {
            field: 'Depressionlevel',
            headerName: 'Level',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
            renderCell: (props) => { return props.row.Depressionlevel != '-' ? <button className='btn btn-link' onClick={() => { setSelectedDisplayLevel(props.row.Depressionlevel);  setCategoryId_1('3'); } }>{props.row.Depressionlevel}</button> : '-' }
        },
        {
            field: 'DepressionResults',
            headerName: 'Results',
            editable: false,
            flex: 0.60,
            minWidth: 100,
            maxWidth: 100,
            sortable: false,
            align: 'center',
        },
        {
            field: 'DepressionDate',
            headerName: 'Date',
            editable: false,
            flex: 0.60,
            minWidth: 105,
            maxWidth: 105,
            sortable: false,
            align: 'center',
        }
        
    ];

 
    const columnGroupingModel: any = [
        {
            groupId: 'group_1',
            headerName: 'Attachment',
            description: '',
            children: [{  field: 'Attachmentlevel' }, { field: 'AttachmentResults' }, { field: 'AttachmentDate' }],
        },
        {
            groupId: 'group_2',
            headerName: 'Busyness',
            description: '',
            children: [{ field: 'Busynesslevel' }, { field: 'BusynessResults' }, { field: 'BusynessDate' }],
        },
        {
            groupId: 'group_3',
            headerName: 'Executive Function',
            description: '',
            children: [{ field: 'ExecutiveFunctionlevel' }, { field: 'ExecutiveFunctionResults' }, { field: 'ExecutiveFunctionDate' }],
        },
        {
            groupId: 'group_4',
            headerName: 'Neurodiversity',
            description: '',
            children: [{ field: 'Neurodivergencelevel' }, { field: 'NeurodivergenceResults' }, { field: 'NeurodivergenceDate' }],
        },
        {
            groupId: 'group_5',
            headerName: 'Depression',
            description: '',
            children: [{ field: 'Depressionlevel' }, { field: 'DepressionResults' }, { field: 'DepressionDate' }],
        }
    ];
    

    // Get user details
    const fetchUsersDetails = (userId) =>{
        getUserDetailsAPI(userId)
        .then(response => {
            setUserData(response.data)
            setLoading(false)
        })
    }

    // On page load fetch user list 
    useEffect(() => {
        fetchUsersDetails(userId)
        
    },[mutation]);

     
    // Get user details list
    const fetchUsersTransactions = (params) =>{
        getAssessmentCompletedScoreListAPI({...params})
        .then(response => {
            setUserEventData(response.userResponseArray)
            setPage(response.page)
            setCurrentPage(response.page)
            setRowPerPage(response.limit)
            setLoading(false)
        })
    }

    // On page load fetch user list 
    useEffect(() => {
        fetchUsersTransactions({
                    user_id : userId,
                    page : page,
                    limit: rowPerPage,
                    sortColumn: sortColumn,
                    sortDirection: sortDirection
                })
        
    },[mutation]);

    // Above user detail
    const detailsData = [
        {
            title: 'Full Name',
            detail: userData.first_name
        },
        {
            title: 'Email',
            detail: userData.email
        },
        {
            title: 'Date of Birth',
            detail: userData.dob && userData.dob !=null && userData.dob !='' ?  moment(userData.dob).format('MM/DD/YYYY') : 'N/A'
        },
        {   
            title: 'Plan Purchased',
            detail: userData?.subscriptionData ? 'Yes' : 'No'
            
        },
        
    ]
    return (
        <>
        {selectedDisplayLevel && <>
            {/* <DetailsLevel setSelectedDisplayLevel={setSelectedDisplayLevel} /> */}
            <DetailsLevel setSelectedDisplayLevel={setSelectedDisplayLevel} categoryId={categoryId_1} /> 
            </>
        }
        {!selectedDisplayLevel && <>
                <Row className="py-4">
                    {detailsData.map((data) => {
                        const { title, detail } = data;
                        return (
                            <Col key={title} sm={6} lg={4} xl={3}>
                                {/* <pre>{JSON.stringify(userData?.subscriptionData?.length,null,2)}</pre> */}
                                <Form.Group className="pb-1 mb-5 lh-sm">
                                    <Form.Label className="mb-1 fw-normal labelColor fs-13">{title}</Form.Label>
                                    <div className="static-data fs-16">{detail}</div>
                                </Form.Group>
                            </Col>
                        )
                    })}
                </Row>
                
                <h5 className="mb-0 position-relative fs-18">
                    Details of Assessment
                </h5>
                {/* <MatDataGrid controlId={'id'} columns={columns} data={userEventData} /> */}
                <MatDataGrid  controlId={'id'} columns={columns}  data={userEventData} columnGroupingModel={columnGroupingModel} />
                <Loader isLoading={loading} />
                </>}
        </>
        
    )
}

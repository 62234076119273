import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';

import { ViewContact } from './View/View';
import { EditContact } from './Edit/Edit';


export const Contactus = () => {

    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
            <Route path="/view/:contactus_id" element={<ViewContact />} /> 
            <Route path="/edit/:contactus_id" element={<EditContact />} /> 
        </Routes>
    )
};
export const LOGIN_API = `/auth/login`;
export const FORGOT_API = `/auth/forgot-password`;
export const RESET_API = `/auth/reset-password`;
export const REFRESH_TOKEN = `/auth/refresh-tokens`;
export const VERIFICATION = `/auth/mfa`;
export const LOGOUT = `/auth/logout`;
export const RESEND_CODE = `/auth/mfa-resend`;
export const GET_DASHBOARD_COUNT_API = `/dashboard/get-dashboard-count`;
export const GET_USER_LIST_API = `/users/list`;
export const GET_TRANSACTIONS_LIST_API = `/users/transaction`;
export const GET_EVENT_LIST_API = `/users/event-list`;
export const GET_USER_DETAILS_API = `/users/user-details`;
export const CHANGE_USER_STATUS_API = `/users/user-status-update`;
export const DELETE_USER_API = `/users/delete`;
export const GET_ONBOARDING_LIST_API = `/dashboard/onbording/list`;
export const ADD_ONBOARDING_API = `/dashboard/onbording/add`;
export const VIEW_ONBOARDING_API = `/dashboard/onbording/view`;
export const SET_NEW_PASSWORD_API = `/auth/change-password`;
export const GET_CMS_LIST_API = `/dashboard/cms/list`;
export const ADD_CMS_API = `/dashboard/cms/submit`;
export const VIEW_CMS_API = `/dashboard/cms/view`;
export const GET_PAYMENT_LIST_API = `/dashboard/payment/list`;
export const GET_CONTACTUS_LIST_API = `/dashboard/contact/list`;
export const REPLY_CONTACTUS_API = `/dashboard/contact/reply`;
export const VIEW_CONTACTUS_API = `/dashboard/contact/view`;
export const GET_QOUTES_LIST_API = `/dashboard/qoutes/list`;
export const SUBMIT_QOUTES_API = `/dashboard/qoutes/submit`;
export const VIEW_QOUTES_API = `/dashboard/qoutes/view`;
export const DELETE_QOUTES_API = `/dashboard/qoutes/delete`;
export const GET_ARTICAL_LIST_API = `/dashboard/artical/list`;
export const SUBMIT_ARTICAL_API = `/dashboard/artical/submit`;
export const VIEW_ARTICAL_API = `/dashboard/artical/view`;
export const STATUS_ARTICAL_API = `/dashboard/artical/change-status`;
export const DELETE_ARTICAL_API = `/dashboard/artical/delete`;
export const IMAGE_UPLOAD_API = `/user/image/upload`;
export const VIDEO_UPLOAD_API = `/user/video/upload`;
export const GET_VIDEO_LIST_API = `/dashboard/video/list`;
export const SUBMIT_VIDEO_API = `/dashboard/video/submit`;
export const VIEW_VIDEO_API = `/dashboard/video/view`;
export const STATUS_VIDEO_API = `/dashboard/video/change-status`;
export const DELETE_VIDEO_API = `/dashboard/video/delete`;
export const GET_MESSAGE_CATEGORY_LIST_API = `/dashboard/message-category/list`;
export const SUBMIT_MESSAGE_CATEGORY_API = `/dashboard/message-category/submit`;
export const VIEW_MESSAGE_CATEGORY_API = `/dashboard/message-category/view`;
export const GET_MESSAGE_CATEGORY_LISTING_API = `/dashboard/message-category/listing`;
export const GET_MESSAGE_LIST_API = `/dashboard/message-template/list`;
export const SUBMIT_MESSAGE_API = `/dashboard/message-template/submit`;
export const VIEW_MESSAGE_API = `/dashboard/message-template/view`;
export const GET_ASSESSMENT_CATEGORY_LIST_API = `/dashboard/assessment-category/list`;
export const VIEW_ASSESSMENT_API = `/dashboard/assessment/view`;
export const LIST_ASSESSMENT_API = `/dashboard/assessment/list`;
export const SUBMIT_ASSESSMENT_API = `/dashboard/assessment/submit`;
export const VIEW_NOTIFICATION_SETTINGS_API = `/dashboard/notification-settings/view`;
export const SUBMIT_NOTIFICATION_SETTINGS_API = `/dashboard/notification-settings/submit`;
export const VIEW_ASSESSMENT_COMPLETED_QUESTIONS_API = `/dashboard/completed-assessment/list`;
export const GET_ASSESSMENT_COMPLETED_SCORE_API = `/dashboard/completed-assessment-score/list`;
export const VIEW_LEVEL_API = `/dashboard/level/view`;
export const LIST_LEVEL_API = `/dashboard/level/list`;
export const SUBMIT_LEVEL_API = `/dashboard/level/submit`;
export const LISTING_LEVEL_API = `/dashboard/level/listing`;
export const LIST_CATEGORY_TYPE_API = `/dashboard/message-category-type/list`;
export const SUBMIT_CATEGORY_TYPE_API = `/dashboard/message-category-type/submit`;
export const LISTING_CATEGORY_TYPE_API = `/dashboard/message-category-type/listing`;
export const VIEW_CATEGORY_TYPE_API = `/dashboard/message-category-type/view`;
export const SUBMIT_DEFAULT_ALERT_TIME_API = `/dashboard/default-alert-time/submit`;
export const VIEW_DEFAULT_ALERT_TIME_API = `/dashboard/default-alert-time/view`;
export const GET_EMAIL_CONTENT_API = `/dashboard/email-content/list`;
export const VIEW_EMAIL_CONTENT_API = `/dashboard/email-content/view`;
export const SUBMIT_EMAIL_CONTENT_API = `/dashboard/email-content/submit`;

export const GET_PAYMENT_HISTORY_API = "/payment-history";

import { Col, Image, Nav, Navbar } from "react-bootstrap"
import { Link, NavLink } from "react-router-dom"
import { NavItems } from "./NavItems"
import "./sidebar.scss"
import Logo from "../../../assets/images/logo-white.svg"
import LogoSmall from "../../../assets/images/white-logo-icon.png"

/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 1:59:33 PM
 *
 * @param {{ isActiveSidebar: any; }} {
  isActiveSidebar
}
 * @returns {*}
 */
export const Sidebar = ({ isActiveSidebar }) => {
  return (
    <Col
      xs="auto"
      className={`sidebarMenu h-100 ${isActiveSidebar ? "sidebarAction" : ""}`}
    >
      <Navbar bg="dark" variant="dark" expand="xxl" className="w-100 h-100 p-0">
        <div className="d-flex flex-column w-100 h-100">
          <div className="d-flex py-2 px-4 align-items-center justify-content-center sidebarLogo border-0 border-opacity-25">
            <Link to="/" className="d-inline-block py-2">
              <Image
                className="img-fluid showInSidebar logoImgSidebar"
                src={Logo}
                alt="Logo"
                width={122}
                height={46}
              />
              <Image
                className="img-fluid showInSmallSidebar"
                src={LogoSmall}
                alt="Logo"
                width={33}
                height={33}
              />
            </Link>
          </div>
          {/*<div className="border-bottom px-4 border-light border-opacity-25 d-flex fs-14 navHeader py-2 text-nowrap text-opacity-50 text-uppercase text-white w-100">
            <span className="py-1 showInSidebar">MAIN NAVIGATION</span>
            <span className="py-1 showInSmallSidebar">Menu</span>
          </div>*/}
          <div className="overflow-x-hidden overflow-y-auto sidebarList">
            <Nav defaultActiveKey="/dashboard" as="ul" className="flex-column">
              {NavItems.map(elem => {
                const { id, menuName, menuIcon, path } = elem
                return (
                  <Nav.Item as="li" key={id}>
                    <Nav.Link
                      as={NavLink}
                      to={path}
                      className="align-items-center d-flex px-0 sidebarLink text-nowrap"
                    >
                      <span className="py-1 text-center min-w-48 sidebarIcon">
                        {menuIcon}
                      </span>
                      <span className="hideInSmallSidebar">{menuName}</span>
                    </Nav.Link>
                  </Nav.Item>
                )
              })}
            </Nav>
          </div>
        </div>
      </Navbar>
    </Col>
  )
}

import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Loader from "./components/common/Loader";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./router";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material";
import storage from "./helpers/storage";
import { refreshTokenCALL } from "./services/authService";

const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#17B0B2",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

function App() {
  const refreshTokenData = storage.getData("refresh-token");

  //const data = {'refreshToken' :refreshTokenData?.token}

  // useEffect(() => {

  //   if (storage.getToken() !== null) {
  //    refreshTokenCALL(data);
  //   }
  //   const refreshInterval = setInterval(() => {
  //     const refreshTokenData = storage.getData("refresh-token");
  //     const tokenTime = new Date(
  //       refreshTokenData ? refreshTokenData.expires : ""
  //     );
  //     const now = Date.now();
  //     const diff = tokenTime.getTime() - now || 0;
  //     if (!Number.isNaN(tokenTime.getTime()) && diff <= 120000) {
  //       refreshTokenCALL(data);
  //     }
  //   }, 5000);
  //   return () => {
  //     clearInterval(refreshInterval);
  //   };
  // }, []);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
    <React.Suspense fallback={<Loader isLoading={true} />}>
      <ThemeProvider theme={customTheme}>
        <Router basename={baseUrl}>
          <AppRoutes />
          <ToastContainer autoClose={5000} />
        </Router>
      </ThemeProvider>
    </React.Suspense>
  );
}

export default App;

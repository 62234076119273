import { Route, Routes } from 'react-router-dom';
import { AllUsers } from './List/List';

import { ViewUser } from './View/View';
import { EditUser } from './Edit/Edit';

export const Users = () => {
    return (  
        <Routes>
            <Route path="/" element={<AllUsers />} />
            <Route path="list" element={<AllUsers />} />
            <Route path="view/:id" element={<ViewUser />} />
            <Route path="edit/:id" element={<EditUser />} />
        </Routes>
    )
};
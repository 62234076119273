import {
    FaAd,
    FaClipboardList,
    FaFileAlt,
    FaFileVideo,
    FaMailBulk,
    FaMoneyBillWave,
    FaQuestionCircle,
    FaUserFriends,
    FaAddressCard,
    FaDollarSign,
    FaBlogger,
    FaConciergeBell,
    FaBell,
  } from "react-icons/fa"
  import { MdSettings, MdDialpad, MdDashboard, MdAssessment, MdLibraryBooks, MdInsertDriveFile, MdVideoLibrary, MdSupervisorAccount } from "react-icons/md"
  import { HiUsers, HiCurrencyDollar } from "react-icons/hi2";
  import { AiFillQuestionCircle } from "react-icons/ai";
  import { IoChatbubbles } from "react-icons/io5";
  import { ImVideoCamera } from "react-icons/im";
  
  export const NavItems = [
    {
      id: 1,
      menuName: "Dashboard",
      menuIcon: <MdDashboard size={24} />,
      path: "/app/dashboard"
    },
    {
      id: 2,
      menuName: "User Management",
      menuIcon: <MdSupervisorAccount size={23} />,
      path: "/app/users"
    },
    {
      id: 3,
      menuName: "Assessments",
      menuIcon: <MdAssessment size={24} />,
      path: "/app/assessment-category"
    },
    {
      id: 4,
      menuName: "Onboarding Questions",
      menuIcon: <AiFillQuestionCircle size={21} />,
      path: "/app/onboarding"
    },
    {
      id: 5,
      menuName: "Levels",
      menuIcon: <MdDialpad size={17} />,
      path: "/app/level"
    },
    {
      id: 6,
      menuName: "Message Templates",
      menuIcon: <IoChatbubbles size={20} />,
      path: "/app/message-category-type"
      //path: "/app/message-category"
    },
    {
      id: 7,
      menuName: "Payment History",
      menuIcon: <span className="material-symbols-outlined fs-20">paid</span>,
      path: "/app/payment"
    },

    {
      id: 8,
      menuName: "Articles",
      menuIcon: <span className="material-symbols-outlined fs-20">edit_document</span>,
      path: "/app/artical"
    },

    {
      id: 9,
      menuName: "Videos",
      menuIcon: <MdVideoLibrary size={20} />,
      path: "/app/video"
    },

    {
      id: 10,
      menuName: "Quotes & Background",
      menuIcon: <span className="material-symbols-outlined fs-20">edit_document</span>,
      path: "/app/quotes"
    },

    {
      id: 11,
      menuName: "CMS Pages",
      menuIcon: <MdInsertDriveFile size={20} />,
      path: "/app/cms"
    },
    {
      id: 12,
      menuName: "Contact Us Inquiries",
      menuIcon: <FaConciergeBell size={17} />,
      path: "/app/contactus"
    },
    {
      id:13,
      menuName: "Email Content",
      menuIcon: <MdInsertDriveFile size={20} />,
      path: "/app/email-content"
    },
    {
      id: 14,
      menuName: "Default Alert Time",
      menuIcon: <FaBell size={17} />,
      path: "/app/default-notification-alert"
    }
    
  ]
  
import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack, Spinner } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { AddCategorySchema } from '../../../validations/ValidationSchema';
import { addMessageCategoryAPI, viewMessageCategoryAPI, categoryTypeListingAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios"
import storage from "../../../helpers/storage"
import { IMAGE_UPLOAD_API } from '../../../constants/paths';
import { FaUserAlt, FaImage } from "react-icons/fa";


export const Edit = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let { cms_id } = useParams();
    const [fileKey, setFileKey] = useState()
    const [fileFullUrl, setFileFullUrl] = useState()
    const [imageLoading,setImageLoading] = useState(false);
    // icon
    const [iconLoading,setIconLoading] = useState(false);
    const [fileIconKey, setFileIconKey] = useState()
    const [fileIconFullUrl, setFileIconFullUrl] = useState();
    const [MessageCategoryData, setMessageCategoryData] = useState([]);

    const location = useLocation();
    const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
    const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
    const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;
    const backUrl = location?.state?.backUrl ? location?.state?.backUrl : '';
     

    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };
    
    const ImageUplodUrl = process.env.REACT_APP_API_URL+'/api'+IMAGE_UPLOAD_API;
    
    // add header
    function authRequestInterceptor(config) {
        const token = storage.getToken()
        if (token) {
          config.headers.authorization = `bearer ${token}`
        }
        config.headers.Accept = "application/json"
        return config
    }

    // upload Icon
    const handleUploadIcon = async (event, setFieldValue ) =>{
        
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        
        // Apply authorization header to formData using the interceptor function
        const config = authRequestInterceptor({
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        
        setIconLoading(true)
        //setFieldValue('image','blank')
        setFieldValue('icon','blank')

        axios.interceptors.request.use(authRequestInterceptor)

        formData.append('file', selectedFile);
        formData.append('image', selectedFile);
        formData.append('userId', '46');

        try {
            const response = await axios.post(ImageUplodUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            });
            console.log("response",response)
            if(response.data){

                // Handle the server response here.
                setFileIconKey(response.data.data.key)
                setFileIconFullUrl(response.data.full_image)
                setIconLoading(false)
            }
        } catch (error) {
            setIconLoading(false)
            toast.error('Uploaded file is not a valid image. Only JPG, PNG and JPEG files are allowed.')
        }
    }

    // upload Image
    const handleUploadImage = async (event, setFieldValue ) =>{
        
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        
        // Apply authorization header to formData using the interceptor function
        const config = authRequestInterceptor({
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        
        setImageLoading(true)
        setFieldValue('image','blank')
        setFieldValue('icon','blank')

        axios.interceptors.request.use(authRequestInterceptor)

        formData.append('file', selectedFile);
        formData.append('image', selectedFile);
        formData.append('userId', '46');

        try {
            const response = await axios.post(ImageUplodUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            });
            console.log("response",response)
            if(response.data){

                // Handle the server response here.
                setFileKey(response.data.data.key)
                setFileFullUrl(response.data.full_image)
                setImageLoading(false)
            }
        } catch (error) {
            setImageLoading(false)
            toast.error('Uploaded file is not a valid image. Only JPG, PNG and JPEG files are allowed.')
        }
    }

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
            if(cms_id){
                editFormData(value)
            }else{
                addFormData(value)
            }
    }

    const editFormData = async(value) =>{

        setLoading(true)
        
        const category = value.category
        const category_type = value.category_type

        addMessageCategoryAPI({
            row_id : cms_id,
            image : fileKey,
            icon : fileIconKey,
            category : category.toString(),  
            category_type : category_type.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            // navigate("/app/message-category-type");
            navigate(backUrl, { state: {pageNumber, searchedValue, rowPerPage}});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const handleCancel = () =>{
        navigate(backUrl, { state: {pageNumber, searchedValue, rowPerPage}});
    }

    const addFormData = async(value) =>{

        const category = value.category
        const category_type = value.category_type
        
        addMessageCategoryAPI({
            image : fileKey,
            icon : fileIconKey,
            category : category.toString(), 
            category_type : category_type.toString(),  
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            // navigate("/app/message-category-type");
            navigate(backUrl, { state: {pageNumber, searchedValue, rowPerPage}});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    // Get onboarding 
    useEffect(() => {
        if(cms_id){
            viewMessageCategoryAPI({row_id : cms_id})
                .then(response => {
                setData(response?.data)
                setFileKey(response.data.image)
                setFileFullUrl(response.data.full_image_url) // image

                setFileIconKey(response.data.icon)
                setFileIconFullUrl(response.data.full_icon_url) // icon
                setLoading(false)
            
            })
        }else{
            setLoading(false)
        }

        categoryTypeListingAPI({search : ''})
                .then(response => {
                    setMessageCategoryData(response?.data)
                    setLoading(false)
                }) 
    },[]);


    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={AddCategorySchema}
          initialValues={
            {   
                category_type: data.category_type ? data.category_type : "",
                category :data.category ?data.category : "" , 
                image :data.image ?data.image : ""  ,
                icon :data.icon ?data.icon : ""  
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                    { cms_id ? 'Edit Message Sub-Category' : 'Add Message Sub-Category'}
                                  
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                  
                                <Row className="gx-xl-5">
                                        {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          
                                            <CustomSelect
                                                options={ MessageCategoryData.map((categorysObj) => ({
                                                            label: categorysObj.category_type,
                                                            value: categorysObj.id,
                                                        }))}
                                                controlId="category_type"
                                                label="Select the category"
                                                value={values.category_type}
                                                name= "category_type"
                                                errorsField={errors.category_type}
                                                touched={touched.category_type}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                onChange={handleChange}
                                                // disabled={true}        
                                            />
                                            <span className='text-danger answer-type-error fs-11'>{getIn(errors,`category_type`)}</span>
                                      </Col>
                                      
                                  </Row>
                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(values,null,2)}</pre>
                                  <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          <InputText
                                              controlId="category"
                                              label="Category"
                                              value={values.category}
                                              name= "title"
                                              maxLength= "50"
                                              errorsField={errors.category}
                                              touched={touched.category}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                          />
                                      </Col>
                                    </Row>
                                    <Row className="gx-xl-5">
                                            <Col sm={12} md={12} lg={12} className="pt-5 mb-3 pb-1 d-flex align-items-end profileTop">
                                                    <div id='' className='height-200 width-400 d-flex justify-content-center align-items-center profilebg rounded-2 me-3'>
                                                        {!fileFullUrl &&  <FaImage size={30} className='profileuser' /> }
                                                        {imageLoading ? <Spinner size='sm' /> : <img src={fileFullUrl}  className='img-thumb'/>}
                                                    </div>
                                                    <label className='position-relative'>
                                                        <span variant="contained" component="label" className='text-primary text-decoration-underline fs-13' >
                                                        Upload Banner Image                                           
                                                        </span>
                                                        <input 
                                                            type="file"  
                                                            name="image"
                                                            onChange={(e) => { handleUploadImage(e,setFieldValue)} }
                                                            className='opacity-0 position-absolute start-0 w-64' 
                                                            id='browse'
                                                        />
                                                    </label>
                                                    <p className='m-0 ms-3 fs-12 labelColor'>
                                                        Maximum size to upload a image is 100 mb. Supported format are png, jpeg and jpg
                                                    </p>
                                            </Col>
                                            <span className='text-danger answer-type-error fs-11'>{getIn(errors,`image`)}</span>
                                            <Col sm={12} md={12} lg={12} className="">
                                                <p className='m-0 fs-12 labelColor'>
                                                <b>Note:</b> Image dimensions should be more than (990 pixels in height x 582 pixels in width).
                                                </p>
                                            </Col>
                                            
                                        
                                    </Row>

                                    <Row className="gx-xl-5">
                                            <Col sm={12} md={12} lg={12} className="pt-5 mb-3 pb-1 d-flex align-items-end profileTop">
                                                    <div id='' className='height-100 width-100 d-flex justify-content-center align-items-center profilebg rounded-2 me-3'>
                                                        {!fileIconFullUrl &&  <FaImage size={30} className='profileuser' /> }
                                                        {iconLoading ? <Spinner size='sm' /> : <img src={fileIconFullUrl}  className='img-thumb'/>}
                                                    </div>
                                                    <label className='position-relative'>
                                                        <span variant="contained" component="label" className='text-primary text-decoration-underline fs-13' >
                                                            Upload Icon                                           
                                                        </span>
                                                        <input 
                                                            type="file"  
                                                            name="icon"
                                                            onChange={(e) => { handleUploadIcon(e,setFieldValue)} }
                                                            className='opacity-0 position-absolute start-0 w-64' 
                                                            id='browse'
                                                        />
                                                    </label>
                                                    <p className='m-0 ms-3 fs-12 labelColor'>
                                                        Maximum size to upload a image is 100 mb. Supported format are png, jpeg and jpg
                                                    </p>
                                            </Col>
                                            <span className='text-danger answer-type-error fs-11'>{getIn(errors,`icon`)}</span>
                                            <Col sm={12} md={12} lg={12} className="">
                                                <p className='m-0 fs-12 labelColor'>
                                                <b>Note:</b> Image dimensions should be more than (200 pixels in height x 200 pixels in width).
                                                </p>
                                            </Col>
                                        
                                    </Row>

                                 

                                  <Stack direction="horizontal" gap={3} className="my-3">
                                      <Button 
                                          variant="primary"
                                          onClick={handleSubmit}
                                          className="rounded-xs fs-15"
                                          type="submit"
                                      >Save</Button>
                                      {/* <Link to="/app/message-category-type"  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                                      <div className="btn btn-outline-secondary rounded-xs fs-15" onClick={handleCancel}>Cancel</div>  
                                  </Stack>
                              </div>
                          </div>
                      </div>
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}
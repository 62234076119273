import React, { useState } from 'react'
import { Button, Card, Col, Row, Stack } from 'react-bootstrap'
import { MdAssessment } from 'react-icons/md'
import { MatDataGrid } from '../../../components/common/dataGrid/DataGrid'
import { ActionCell } from '../../../components/common/dataGrid/ActionCell';
import { TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: '#17B0B2',
    },
    text: {
      primary: '#000000',
      secondary: '#777777',
    }
  }
});

const columns = [
  {
    field: 'serial',
    headerName: 'S. No.',
    editable: false,
    flex: 0.05,
    minWidth: 100,
  },
  {
    field: 'name',
    headerName: 'Assessment Questions',
    editable: false,
    flex: 0.60,
    minWidth: 160,
  },
  {
    field: 'email',
    headerName: 'Level',
    editable: false,
    flex: 0.60,
    minWidth: 160,
  },
  {
    field: 'date',
    headerName: 'Last Updated',
    editable: false,
    flex: 0.50,
    minWidth: 150,
  },

  {
    field: 'action',
    headerName: 'Action',
    editable: false,
    flex: 0.20,
    minWidth: 120,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (props) => { return <ActionCell controlId='tenant-management' isEditEnable={false} isViewEnable={true} isLogInEnable={true} redirectID={props.row.site_id} rowData={props.row} /> }
  }
];


const data = [
  {
    "site_id": 1,
    "serial": "1",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 2,
    "serial": "2",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 3,
    "serial": "3",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 4,
    "serial": "4",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 5,
    "serial": "5",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 6,
    "serial": "6",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 7,
    "serial": "7",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 8,
    "serial": "8",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 9,
    "serial": "9",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 10,
    "serial": "10",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 11,
    "serial": "11",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 12,
    "serial": "12",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },
  {
    "site_id": 13,
    "serial": "13",
    "name": "Do you receive complaints about how busy you are ?",
    "email": "4",
    "date": "03/01/2023",
    "action": '',
  },




];

export const AvoidanceList = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValue1, setSelectedValue1] = useState('');

  const handleValueChange = (value) => {
    setSelectedValue(value);
    setSelectedValue1(value);
  };


  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      {/* <div className="contentHeader px-1 pt-1 pb-3">
        <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between">
          <h1 className="fw-semibold h4 mb-0">Tenant Management</h1>
          <Breadcrumb className="fs-12 fw-semibold">
            <li className="breadcrumb-item">
              <Link className="text-decoration-none">Tenant Management</Link>
            </li>
            <Breadcrumb.Item active>Tenant Management</Breadcrumb.Item>
          </Breadcrumb> 
        </Stack>
      </div>*/}
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
              <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <MdAssessment size={40} />
                </div>
                Avoidance List
              </h5>
              <Button variant="primary" className="fs-14">Add New</Button>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
            <div className="theme-data-table-filter px-1">
              <ThemeProvider theme={customTheme}>
                <Row className="justify-content-end">
                  <Col sm={5} lg={2} className="mb-3 pb-1">
                    <TextField
                      id="search-filter"
                      label="Search"
                      variant="standard"
                      fullWidth
                    />
                  </Col>
                  {/*<Col sm="auto">
                    <Stack direction="horizontal" className="justify-content-between gap-3 gap-md-4">
                      <CustomSelect
                        minWidth="94px"
                        label="All Profile"
                        options={[
                          { label: 'Profile 1', value: 'Profile1' },
                          { label: 'Profile 2', value: 'Profile2' },
                          { label: 'Profile 3', value: 'Profile3' },
                        ]}
                        value={selectedValue}
                        onChange={handleValueChange}
                      />
                      <CustomSelect
                        minWidth="150px"
                        label="All Tenant Category"
                        options={[
                          { label: 'Tenant 1', value: 'Tenant1' },
                          { label: 'Tenant 2', value: 'Tenant2' },
                          { label: 'Tenant 3', value: 'Tenant3' },
                        ]}
                        value={selectedValue1}
                        onChange={handleValueChange}
                      />

                    </Stack>
                  </Col>*/}
                </Row>
              </ThemeProvider>
            </div>
            <MatDataGrid controlId={'site_id'} columns={columns} data={data} />
          </div>
        </Card>
      </div>
    </div>
  )
}

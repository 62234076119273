// import FormSelect from 'Components/FormSelect';
import React, { useState } from 'react';
import { CustomSelect } from './CustomSelect';

const ColorPickerDropdown = ({handleChange, defaultColor, keyvalue=0, setFieldValue, name}) => {
   const [selectedColor, setSelectedColor] = useState(defaultColor);
  const [customColor, setCustomColor] = useState(defaultColor);
  keyvalue = parseInt(keyvalue)
 
  const handleColorChange = (value) => {
   // const color = event.target.value;
       
   const color = value.props.value;
    setSelectedColor(color);
    if (color === 'custom') {
      setCustomColor('');
    }

    setFieldValue(name,color)
    //handleChange(color)
  };

  const handleCustomColorChange = (event) => {
    setCustomColor(event.target.value);
    handleChange(event.target.value);
   // setSelectedColor('custom');
   setFieldValue(name,event.target.value)
  };

  const colorOptions = [
    { value: '#CD132F', label: <span className='d-flex align-items-center'>Red  <div style={{ backgroundColor: '#CD132F', width: '18px', height: '15px', marginLeft : '25px' }}></div></span> },
    { value: '#FBDB03', label: <span className='d-flex align-items-center'>Yellow  <div style={{ backgroundColor: '#FBDB03', width: '18px', height: '15px', marginLeft : '10px' }}></div></span> },
    { value: '#000000', label:<span className='d-flex align-items-center'>Black  <div style={{ backgroundColor: '#000000', width: '18px', height: '15px', marginLeft : '20px' }}></div></span> },
    { value: '#ffffff', label:<span className='d-flex align-items-center'>White  <div style={{ border: '1px #000000  solid', backgroundColor: '#ffffff', width: '18px', height: '15px', marginLeft : '20px' }}></div></span> },
    { value: '#009618', label: <span className='d-flex align-items-center'>Green  <div style={{ backgroundColor: '#009618', width: '18px', height: '15px', marginLeft : '8px' }}></div></span> },
    { value: '#0073B9', label:<span className='d-flex align-items-center'>Blue  <div style={{ backgroundColor: '#0073B9', width: '18px', height: '15px', marginLeft : '8px' }}></div></span> },
    // { value: 'custom', label: 'Custom' },
  ];

  return (
    <div className='colorBox'>
    
     <div className='d-flex customColor'>
     {selectedColor === 'custom' && (
        <input type="color" value={customColor} onChange={handleCustomColorChange} className='colorShowinput' id="colorselector" />
      )}
      {selectedColor !== 'custom' && selectedColor && (
        <div style={{ backgroundColor: selectedColor }} className='colorShow'></div>
      )}
        <div className='seletCover'>
          
        

                                                  <CustomSelect
                                                      className="w-100"
                                                      options={colorOptions}
                                                      controlId="text_color"
                                                      label="Select the text color"
                                                      value={selectedColor}
                                                      name="text_color"
                                                    
                                                      onChange={(name, value)=>{
                                                        handleColorChange(value)
                                                      }}
                                                      isHtmlLabel={true} // This is important to render HTML in labels
                                                /> 

        
      </div>
     </div>
    </div>
  );
};

export default ColorPickerDropdown;

import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { AddEmailContentSchema } from '../../../validations/ValidationSchema';
import { addEmailContentAPI, viewEmailContentAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const EditCms = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let { cms_id } = useParams();
    const location = useLocation();
    const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
    const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
    const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;
    
  

    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
            if(cms_id){
                editCms(value)
            }else{
                addCms(value)
            }
    }

    const editCms = async(value) =>{

        setLoading(true)
        
        const content = value.content
        const email_type = value.email_type
        
        addEmailContentAPI({
            cms_id: cms_id,
            content: content.toString(),
            email_type: email_type.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate(`/app/email-content`, { state: {pageNumber, searchedValue, rowPerPage}});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const addCms = async(value) =>{

        const content = value.content
        const email_type = value.email_type
        
        addEmailContentAPI({
            content: content.toString(),
            email_type: email_type.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate(`/app/email-content`, { state: {pageNumber, searchedValue, rowPerPage}});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const handleCancel = () =>{
        navigate(`/app/email-content`, { state: {pageNumber, searchedValue, rowPerPage}});
    }
    
    // Get onboarding 
    useEffect(() => {
        if(cms_id){
            viewEmailContentAPI({cms_id : cms_id})
                .then(response => {
                setData(response?.data)
                setLoading(false)
            
            })
        }else{
            setLoading(false)
        }
    },[]);

    const modules = {
        toolbar: [
        //   [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        //   [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        //   ['link', 'image',],
        ['link'],
        [{ 'script': 'sub' }, { 'script': 'super' }], // Add super and sub options here
        ['clean']
        ],
        clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
        },
    };

    const formats = [
        // 'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent',
        // 'link', 'image', 'video',
        'link',
        'script', // Include the script format
    ];

    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={AddEmailContentSchema}
          initialValues={
            {   
               
                email_type: data.email_type ? data.email_type : "",
                content: data.content ? data.content : "" 
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                    { cms_id ? 'Edit Email Content' : 'Add Email Content'}
                                  
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                  
                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={8} className="mb-4 pb-1">
                                            <CustomSelect
                                                options={[
                                                    { label: 'Send Message', value: 'SEND_MESSAGE' },
                                                    { label: 'Watch video and article', value: 'WATCH_VIDEO_AND_ARTICLE' },
                                                    { label: 'How Use The App Better', value: 'HOW_USE_THE_APP_BETTER' },
                                                ]}
                                                controlId="email_type"
                                                label="Email Type*"
                                                value={values.email_type}
                                                name="email_type"
                                                errorsField={errors.email_type}
                                                touched={touched.email_type}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={true}

                                            />
                                      </Col>
                                      
                                  </Row>

                                  <Row className="gx-xl-5">
                                               
                                                            <>
                                                                <Col sm={6} md={6} lg={8} className="mb-4 pb-1">
                                                                <Form.Label className="mb-1 fw-normal labelColor fs-13">Description</Form.Label>
                                                                    <ReactQuill
                                                                        modules={modules}
                                                                        formats={formats}
                                                                        name="content"
                                                                        theme="snow"
                                                                        value={values.content}
                                                                        onChange={(value) => setFieldValue('content', value)}
                                                                    />
                                                                    <span className='text-danger answer-type-error fs-11'>{getIn(errors, `content`)}</span>
                                                                </Col>    
                                                            </>
                                </Row>



                                  <Stack direction="horizontal" gap={3} className="my-3">
                                      <Button 
                                          variant="primary"
                                          onClick={handleSubmit}
                                          className="rounded-xs fs-15"
                                          type="submit"
                                      >Save</Button>
                                      <div onClick={handleCancel}  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</div>
                                  </Stack>
                              </div>
                          </div>
                      </div>
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}
import React, { useEffect, useState } from 'react'
import { viewSettingsAPI, viewCompletedAssessmentQuestionsAPI } from '../../../../services/userService';
import { Breadcrumb, Button, Form, Card, Col, Row, Stack, Spinner } from 'react-bootstrap';
import Loader from "../../../../components/common/Loader";
import { Formik, FieldArray, Field, getIn } from "formik"
import {  AddQuotesSchema } from '../../../../validations/ValidationSchema';
import { Link, useLocation, useParams } from 'react-router-dom';


export const Level1 = ({setSelectedDisplayLevel, level, category}) => {
    
    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    let { id } = useParams();
   
    // Get 
    useEffect(() => {
            viewCompletedAssessmentQuestionsAPI({user_id : id, category_id : category.toString(), level_id : level.toString()})
                .then(response => {
                setData(response?.userResponseArr)
                setLoading(false)
            })
    },[]);
 
    return (
        <>
        <div className='align-items-center d-flex justify-content-between'>
            <h5 className="mt-4 position-relative fs-20 bold mb-3">
                Questions list
            </h5>
            <button onClick={() => setSelectedDisplayLevel(null)}  className='btn btn-primary back-btn-bg'>Back</button>
        </div>
       
        <ul className='assesment-qus-ans'>
            <li>
                { 
                    data.length === 0 ? (
                        <div>No records found</div>
                    ) : (
                        data.map((row) => (
                            <>
                                <div className='assesment-qus-text'><span>Q.</span>{row.question}</div>
                                <div className='assesment-ans-text'><span>Ans.</span>{row.answer_text} .</div>
                            </>                  
                        ))
                    )
                }
            </li>
        </ul>

        </>
        
    )
}

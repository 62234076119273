import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Row, Stack } from "react-bootstrap";
import { MdHandshake, MdSupervisorAccount, MdClear } from "react-icons/md";
import { MatDataGrid } from "../../../components/common/dataGrid/DataGrid";
import { MatDataGridWithPagination } from "../../../components/common/dataGrid/DataGridWithPagination";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { SwitchCell } from "../../../components/common/dataGrid/SwitchCell";
import { TextField } from "@mui/material";
import CustomSelect from "../../../components/common/CustomSelect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getAssessmentListAPI,
  deleteUser,
} from "../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";

const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#17B0B2",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

export const AllList = () => {
  const cookies = new Cookies();

  cookies.set("page_heading", "Onboarding Questions"); // page heading

  const location = useLocation();

  //const [selectedValue, setSelectedValue] = useState('');

  const params = new URLSearchParams(location.search);
  const messageCategory = params.get("category");
  const messageCategoryId = params.get("id");

  const [selectedValue, setSelectedValue] = useState(
    location?.state?.searchedValue ? location?.state?.searchedValue : ""
  );
  const [page, setPage] = useState(
    location?.state?.pageNumber ? location?.state?.pageNumber : "1"
  );

  const [rowPerPage, setRowPerPage] = useState(
    location?.state?.rowPerPage ? location?.state?.rowPerPage : 10
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("level_id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState();
  const [userStatus, setUserStatus] = useState();
  const [userData, setUserData] = useState([]);
  const [mutation, setMutation] = useState(true);
  const [totalReults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const columns = [
    {
      field: "sr_no",
      headerName: "S. No.",
      editable: false,
      sortable: false,
      flex: 0.05,
      minWidth: 100,
    },
    {
      field: "question",
      headerName: "Assessment Questions",
      editable: false,
      flex: 0.6,
      minWidth: 550,
      maxWidth: 550,
    },

    {
      field: "level_text",
      headerName: "Level",
      editable: false,
      flex: 0.5,
      minWidth: 150,
      maxWidth: 250,
      sortable: false,
    },

    {
      field: "updatedAt",
      headerName: "Last Updated",
      editable: false,
      flex: 0.5,
      minWidth: 150,
      maxWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      maxWidth: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/assessment"
            isViewEnable={true}
            isEditEnable={false}
            isDeleteEnable={false}
            deleteFunctionName={deleteRow}
            isLogInEnable={true}
            redirectID={props.row.id}
            rowData={props.row}
            pageNumber={page}
            searchedValue={selectedValue}
            backUrl={`/app/assessment?category=${messageCategory}&id=${messageCategoryId}`}
            rowPerPage={rowPerPage}
          />
        );
      },
    },
  ];

  // Delete
  const deleteRow = (userId) => {
    setLoading(true);
    console.log(userId);
    deleteUser({
      userId: userId.toString(),
    })
      .then((response) => {
        setMutation(!mutation);
        toast.success(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  // Searching and listing user list
  const handleValueChange = (value) => {
    setSelectedValue(value);
    fetchUsers({
      page: page,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      category_id: messageCategoryId,
      ...(value ? { search: value } : {}),
    });
  };

  const handleClear = () => {
    setSelectedValue("");
    fetchUsers({
      page: page,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      category_id: messageCategoryId,
      search: "",
    });
  };

  // Get list details
  const fetchUsers = (params) => {
    getAssessmentListAPI({ ...params }).then((response) => {
      setUserData(response.userResult);
      setPage(response.page);
      setCurrentPage(response.page);
      setRowPerPage(response.limit);
      setTotalResults(response.totalResults);
      setTotalPages(response.totalPages);
      setLoading(false);
    });
  };

  // Pagination work Start
  const handlePageChange = (params) => {
    setLoading(true);
    setPage(params.page + 1);
    setCurrentPage(params.page + 1);
    setRowPerPage(params.pageSize);
    const urlParams = new URLSearchParams(location.search);
    debugger;
    urlParams.set(page, "5");
    //params.get('page')
  };
  const handleSortModelChange = (params) => {
    setLoading(true);
    console.log("params", params[0]);

    setSortColumn(params[0]?.field);
    setSortDirection(params[0]?.sort);
  };
  const handlePageSizeChange = (params) => {
    setLoading(true);
    setRowPerPage(params.pageSize);
  };
  // Pagination work End

  // On page load fetch user list
  useEffect(() => {
    //setLoading(true)
    fetchUsers({
      page: page,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      category_id: messageCategoryId,
      ...(selectedValue ? { search: selectedValue } : {}),
    });
  }, [mutation, page, rowPerPage, sortColumn, sortDirection, currentPage]);

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        {loading ? (
          "Loading..."
        ) : (
          <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <MdSupervisorAccount size={40} />
                    </div>
                    {messageCategory} Assessment List
                  </h5>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        navigate("/app/assessment/add");
                      }}
                      variant="primary"
                    >
                      Add New
                    </button>
                    <button
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        navigate("/app/assessment-category");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
                <div className="theme-data-table-filter px-1">
                  <ThemeProvider theme={customTheme}>
                    <Row className="justify-content-end">
                      <Col sm={5} lg={2} className="mb-3 pb-1">
                        <TextField
                          id="search-filter"
                          label="Search"
                          variant="standard"
                          fullWidth
                          value={selectedValue}
                          onChange={(e) => {
                            handleValueChange(e.target.value);
                          }}
                        />
                        <MdClear
                          className="clear-postion"
                          onClick={handleClear}
                        ></MdClear>
                      </Col>
                    </Row>
                  </ThemeProvider>
                </div>
                {/* <MatDataGrid controlId={'id'} columns={columns} data={userData} /> */}
                <MatDataGridWithPagination
                  controlId={"id"}
                  columns={columns}
                  data={userData}
                  onPaginationModelChange={handlePageChange}
                  handleSortModelChange={handleSortModelChange}
                  pageSize={rowPerPage}
                  totalReults={totalReults}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageSizeChange={handlePageSizeChange}
                  sortModel={
                    sortColumn
                      ? [
                          {
                            field: sortColumn,
                            sort: sortDirection,
                          },
                        ]
                      : []
                  }
                />
              </div>
            </Card>
          </div>
        )}
      </div>
      <Loader isLoading={loading} />
    </>
  );
};

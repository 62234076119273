import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { AddCmsSchema } from '../../../validations/ValidationSchema';
import { addCmsAPI, viewContactusAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const ViewContact = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let { contactus_id } = useParams();
    const location = useLocation();
    const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
    const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
    const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;
  

    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
                addCms(value)
            
    }

    const handleCancel = () =>{
        
        navigate(`/app/contactus`, { state: {pageNumber, searchedValue, rowPerPage}});
      //  navigate(-1, {state: {pageNumber: pageNumber}} )
    }

    const addCms = async(value) =>{

       const title = value.title
        const content = value.content
        const page_type = value.page_type
        
        addCmsAPI({
                title : title.toString(),
                content : content.toString(),
                page_type : page_type.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate("/app/cms");
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    
    // Get onboarding 
    useEffect(() => {
        if(contactus_id){
            
            viewContactusAPI({contactus_id : contactus_id})
                .then(response => {
                setData(response?.data)
                setLoading(false)
            
            })
        }else{
            setLoading(true)
        }
    },[]);


    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={AddCmsSchema}
          initialValues={
            {   
                page_type :data.page_type ?data.page_type : "" , 
                title :data.title ?data.title : ""  ,
                content :data.content ?data.content : ""  
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                  Contact Details
                                  
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                  <Row className="gx-xl-5">
                                  
                                      <Col sm={6} md={6} lg={4} className=" pb-1">
                                        <Form.Group className="mb-4 lh-sm">
                                            <Form.Label className="mb-1 fw-normal labelColor fs-13">Full Name</Form.Label>
                                            <div className="static-data fs-16">{data.name}</div>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6} md={6} lg={4} className=" pb-1">
                                        <Form.Group className="mb-4 lh-sm">
                                            <Form.Label className="mb-1 fw-normal labelColor fs-13">Email Address</Form.Label>
                                            <div className="static-data fs-16">{data.email}</div>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6} md={6} lg={4} className=" pb-1">
                                        <Form.Group className="mb-4 lh-sm">
                                            <Form.Label className="mb-1 fw-normal labelColor fs-13">Subject</Form.Label>
                                            <div className="static-data fs-16">{data.subject}</div>
                                        </Form.Group>
                                      </Col>
                                  </Row>
                                  <Row className="gx-xl-5">
                                      <Col sm={6} md={6} lg={7} className="mb-1 pb-1">
                                            <Form.Group className="pb-1 mb-1 lh-sm">
                                                <Form.Label className="mb-1 fw-normal labelColor fs-13">Message</Form.Label>
                                                <div className="static-data fs-16">{data.message}</div>
                                            </Form.Group>
                                      </Col>
                                      
                                  
                                        {data?.reply !='' && data?.reply != null ? 
                                            
                                                <Col sm={6} md={6} lg={7} className="mb-4 pb-1">
                                                    <Form.Group className="pb-1 mb-5 lh-sm">
                                                        <Form.Label className="mb-1 fw-normal labelColor fs-13">Reply</Form.Label>
                                                        <div className="static-data fs-16">{data.reply}</div>
                                                    </Form.Group>
                                                </Col>
                                            
                                        : ''} 
                                   </Row>

                                  {errors && errors.weight_type && typeof(errors.weight_type)=='string' && <span className='text-danger'>{errors?.weight_type}</span>}

                                  <Stack direction="horizontal" gap={3} className="my-3">
                                      <div onClick={handleCancel}  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</div>
                                  </Stack>
                              </div>
                          </div>
                      </div>
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}
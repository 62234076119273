import { useRoutes } from "react-router-dom";
import storage from "../helpers/storage";
import { nameBasedProtectedRoutes, protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import { roles } from "../constants/commonValue";

/**
 *
 * @returns Routes Elements
 */
//   export default function AppRoutes() {
//   const mainRoutes = protectedRoutes;
//   const element = useRoutes([...publicRoutes,...mainRoutes]);
//   return <>{element}</>;
// }

export default function AppRoutes() {
    const userInfo = storage.getData('user-info');
    const userRole = userInfo?userInfo.role:'';
    const mainProtectedRoutes = (userRole==roles.SUPERADMIN)?nameBasedProtectedRoutes[0].dashborad.path:[];
  
    // const mainRoutes = (storage.getToken() != null) ? mainProtectedRoutes : [];
    // const element = useRoutes([...publicRoutes, ...mainRoutes]);

    const mainRoutes = protectedRoutes;
    const element = useRoutes([...publicRoutes,...mainRoutes]);
    return <>{element}</>;
};



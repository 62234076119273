import { MainLayout } from '../components/layouts/MainLayout';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { Users } from '../pages/Users/routes';
import { Assessment } from '../pages/Assessment/routes';
import { OnbordingQuestions } from '../pages/Onbording/routes';
import { Message } from '../pages/Message/routes';
import { Profile } from '../pages/Profile/AddProfile';
import { Cms } from '../pages/Cms/routes';
import { Contactus } from '../pages/Contactus/routes';
import { Payment } from '../pages/Payment/routes'
import { Artical } from '../pages/Artical/routes';
import { Qoutes } from '../pages/Qoutes/routes';
import { Videos } from '../pages/Video/routes';
import { Messagecategory } from '../pages/Messagecategory/routes';
import { MessagecategoryType } from '../pages/MessagecategoryType/routes';
import { Userassessment } from '../pages/Userassessment/routes';
import { Levels } from '../pages/Levels/routes';
import { DefaultNotificationAlert } from '../pages/Default-notification-alert/DefaultNotificationAlert';
import { EmailContent } from '../pages/EmailContent/routes';

const basePath = process.env.REACT_APP_BASE_URL;

/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 */
export const protectedRoutes = [
    {
    path: '/app',
        element: <MainLayout />,
        children: [
            { path: '', element: <Dashboard /> },
            { path: 'dashboard', element: <Dashboard /> },
            { path: 'users/*', element: <Users /> },
            { path: 'assessment-category/*', element: <Assessment /> },
            { path: 'onboarding/*', element: <OnbordingQuestions /> },
            { path: 'cms/*', element: <Cms /> },
            { path: 'contactus/*', element: <Contactus /> },
            { path: 'message/*', element: <Message /> },
            { path: 'payment/*', element: <Payment /> },
            { path: 'profile', element: <Profile /> },
            { path: 'artical/*', element: <Artical /> },
            { path: 'quotes/*', element: <Qoutes /> },
            { path: 'video/*', element: <Videos /> },
            { path: 'message-category/*', element: <Messagecategory/> },
            { path: 'message-category-type/*', element: <MessagecategoryType/> },
            { path: 'assessment/*', element: <Userassessment/> },
            { path: 'level/*', element: <Levels/> },
            { path: 'default-notification-alert/*', element: <DefaultNotificationAlert/> },
            { path: 'email-content/*', element: <EmailContent/> },
        ],
    }
];

// Named Routes

export const nameBasedProtectedRoutes = [
  {  
    userDashboard: {
        name: "Dashboard",
        path: `/`,
    },
    
    dashborad: {
        name: "Dashboard",
        path: `/app/dashboard`
    } 
  } 
]

import React,{useEffect} from "react"
import { Formik } from "formik"
import { Button, Col, Row } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { Link, useNavigate } from "react-router-dom"
import { AuthHeader } from "../authHeader/AuthHeader"

import { LoginSchema } from "../../../validations/Auth"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import Cookies from "universal-cookie";

import { useRef, useState } from "react"
import Loader from "../../../components/common/Loader"
import storage from "../../../helpers/storage"
import { toast } from "react-toastify"
import { CAPTCH_INVALID } from "../../../validations/ValidationErrors"
import { login } from "../../../services/authService"
import {roles } from "../../../constants/commonValue"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import InputText from "../../../components/common/InputText"
import { nameBasedRoutes } from "../../../router/public"
import { nameBasedProtectedRoutes } from "../../../router/protected"
//import Visibility from '@mui/icons-material/Visibility';
//import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const Login = () => {

  const cookies = new Cookies();
  const navigate = useNavigate()
    // data
    const [data, setData] = useState({
      email: "",
      password: "",
      captcha: "",
    });

  // remember me
  const rememberMeEmail = cookies.get("admin_email");
  const rememberMePassword = cookies.get("admin_password");
  const alreadyChecked = cookies.get("admin_checked");
  const [rememberMe, setRemembeMe] = useState(!!alreadyChecked);

  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [captchaCode, setCaptchaCode] = useState("")
  const recaptchaRef = useRef(null)
  const cpatchaKey = process.env.REACT_APP_CPATCHA_KEY
  // const rememberMe = storage?.getData("remember-me")
  const [showEye, setShowEye] = useState(false)
  const initialValues = {
    email: rememberMeEmail ? rememberMeEmail : "",
    password: rememberMePassword ? rememberMePassword : "",
    captcha: "",
    rememberCheck: false
  }


  const onChangeCaptchaCode = value => {
    setCaptchaCode(value)
  }

  // For remember details
  useEffect(() => {
    if (rememberMeEmail && rememberMePassword) {
      console.log("rememberMeEmail", rememberMeEmail);
      setData((data) => ({ ...data, email: rememberMeEmail }));
      setData((data) => ({ ...data, password: rememberMePassword }));
      setTimeout(() => {}, 100);
      setLoading(true)
    }
    setLoading(false)
  }, []);

  // to restricet admin only 
  useEffect(() => {
    if (storage.getToken() != null) {
      const userInfo = storage.getData("user-info");
      const role = userInfo ? userInfo?.role : "";
      if (role == 'SUPER_ADMIN') {
         return navigate(nameBasedProtectedRoutes[0].dashborad.path);
      }
    }
  }, [navigate]);

  /**
   * Submit Form
   *
   * @async
   * @param {string} email
   * @param {string} password
   * @param {*} captcha
   * @param {*} actions
   * @returns {*}
   */
  const handleSubmit = async (email, password, captcha, actions) => {
   
    setLoading(true)

    if (rememberMe === false) {
      cookies.remove("admin_email");
      cookies.remove("admin_password");
      cookies.remove("admin_checked");
    }
    const role =  roles.CUSTOMER;
    login({ email, password, role })
      .then(response => {
        setLoading(false)
        cookies.set("page_heading", 'Dashboard'); // page heading
        navigate("/app/dashboard");
        if (rememberMe === true) {
          cookies.set("admin_email", email);
          cookies.set("admin_password", password);
          cookies.set("admin_checked", rememberMe);
        }
        storage.setToken(response.data.tokens.access.token)
        storage.setRefreshToken(response.data.tokens.refresh.token)
        storage.setData("user-info", response.data.user)
        storage.setData("userType", response.data.user.role)
        storage.setData("refresh-token", response.data.tokens.refresh)
      })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.message)
      })
  }

  const showToggle = event => {
    setShowEye(event)
  }

  return (
    <>
      <Formik
        validationSchema={LoginSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false)
          handleSubmit(values.email, values.password, values.captcha, actions)
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors
        }) => (
          <div className="d-flex flex-column p-3 min-vh-100 w-100 theme-body-bg">
            <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
              <div className="bg-white d-inline-block mt-3 p-4 rounded-3 text-start w-100 shadow-sm">
               
                  <div className="fw-normal text-center mb-3">
                    <AuthHeader />
                  </div>

                 {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}

                <InputText 
                  controlId = "email"
                  label ="Email Address *"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errorsField={errors.email}
                  touched={touched.email}
                  className="w-100 fs-16 mb-4 custom-text-field"
                  InputLabelProps={{
                    className: "fs-15 grey-color"
                  }}
                  value={values.email}
                  name= "email"
                 
                 />
                <div className={`position-relative ${showEye ? "form-right-icon" : ""}`} >
                  <InputText 
                    controlId="password"
                    label="Password *"
                    type={showEye ? "text" :"password" }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errorsField={errors.password}
                    touched={touched.password}
                    value={values.password}
                    name= "password"
                    multiline={false}
                    className="w-100 fs-16 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color"
                      }}
                  />
                  
                    
                    <div onClick={() => setShowEye(!showEye)}>
                      {showEye ? (
                            <FaEye size="22" 
                            className="eyePosition"
                            onClick={() => showToggle(true)} />
                          ) : (
                            <FaEyeSlash
                              size="22"
                              className="eyePosition"
                              onClick={() => showToggle(true)}
                            />
                          )}
                    </div>
                  </div>   
                  <Form.Group className="mt-4 pt-2 mb-3">
                    <Row
                      xs="auto"
                      className="align-items-center justify-content-between gx-0"
                    >
                      <Col>
                        <Form.Check
                          id="rememberCheck"
                          className="fs-14 lh-lg mh-auto mb-0 user-select-none customCheck"
                          type="checkbox"
                          label="Remember me"
                          checked={rememberMe}
                          onChange={(e) => setRemembeMe(e.target.checked)}
                          //checked={isChecked}
                          //onChange={onChangeCheckbox}
                        />
                      </Col>
                      <Col>
                        <Link
                          className="fs-14 text-decoration-none fw-bold"
                          to={nameBasedRoutes.forgotPassword.path}
                        >
                          Forgot Password?
                        </Link>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Button
                    className="fw-semibold fs-18 text-white min-h-48 mt-2 w-100"
                    variant="primary"
                    onClick={handleSubmit}
                    type="submit"
                  >
                    Login
                  </Button>
               
              </div>
            </div>
          </div>
        )}
      </Formik>
      <Loader isLoading={loading} />
    </>
  )
}

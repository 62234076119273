import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';


export const Payment = () => {

    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
        </Routes>
    )
};
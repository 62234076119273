import * as React from 'react';
import dayjs from 'dayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
const Datepicker = ({date, name, handleDateChange, minDate, maxDate, slotProps, placeholder }) => {
    const [value, setValue] = React.useState(dayjs(date));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label=""
          name={name}
          value={value}
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{ textField: { placeholder: placeholder } }}
          placeholder={placeholder}
          showToolbarPlaceholder
        />
      </LocalizationProvider>
    );
}
 
export default Datepicker;
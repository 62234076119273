import React from 'react'
import { Button, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { MdDelete, MdRemoveRedEye } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const SwitchCell = (props) => {
    const navigate = useNavigate();
    return (
        // <Switch className="customSwitch" {...props} defaultChecked />
        <Switch className="customSwitch" {...props} />
    )
}

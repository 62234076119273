import React, { useEffect, useState } from 'react'
import { viewSettingsAPI, submitSettingsAPI } from '../../../../services/userService';
import { Breadcrumb, Button, Form, Card, Col, Row, Stack, Spinner } from 'react-bootstrap';
import Loader from "../../../../components/common/Loader";
import { Formik, FieldArray, Field, getIn } from "formik"
import {  AddQuotesSchema } from '../../../../validations/ValidationSchema';
import { Link, useLocation, useParams } from 'react-router-dom';


export const SendMessage = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    let { id } = useParams();

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
        
    }
    
    // Get 
    useEffect(() => {
            viewSettingsAPI({user_id : id, type : 'MESSAGE'})
                .then(response => {
                    
                setData(response?.data[0])
                setLoading(false)
            
            })
    },[]);
    
    return (
        <>
        {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <Formik
            validationSchema={AddQuotesSchema}
            initialValues={
                {   
                    
                }
            }
            onSubmit={(values, actions) => {
                actions.setSubmitting(false)
                handleSubmit(values, actions)
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                touched,
                isValid,
                errors
            }) => (
                <section>
                    <div className=''>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='set-frequency-title'>Set the Frequency</div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-auto me-5'>
                                <div class="form-check check-radio-btn-cover">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={data?.frequency_type == 'DEFAULT' ? 1 : 0}/>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Default Recommended System
                                        </label>
                                </div>
                            </div>
                            <div className='col-md-auto me-5'>
                                <div class="form-check check-radio-btn-cover">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={data?.frequency_type == 'EVERYDAY' ? 1 : 0}/>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Everyday
                                        </label>
                                </div>
                            </div>
                            <div className='col-md-auto me-5'>
                                <div class="form-check check-radio-btn-cover">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={data?.frequency_type == 'SPECIFIC' ? 1 : 0}/>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            On Specific Days
                                        </label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div class="weekDays">
                                <label for="chekbox1">
                                    <input id="chekbox1" class="d-none" name="tourSchedule.0.weekdays[0]" checked={data?.sun == '1' ? 1 : 0}  type="checkbox" />
                                    <span class="active">Sun</span>
                                </label>
                                <label for="chekbox2">
                                    <input id="chekbox2" class="d-none" name="tourSchedule.0.weekdays[1]" checked={data?.mon == '1' ? 1 : 0}  type="checkbox" />
                                    <span class="active">Mon</span>
                                </label>
                                <label for="chekbox3">
                                    <input id="chekbox3" class="d-none" name="tourSchedule.0.weekdays[2]" checked={data?.tue == '1' ? 1 : 0}  type="checkbox" 
                                    /><span class="active">Tue</span>
                                </label>
                                <label>
                                    <input class="d-none" name="tourSchedule.0.weekdays[3]" type="checkbox" checked={data?.wed == '1' ? 1 : 0} 
                                    /><span class="">Wed</span></label>
                                <label>
                                    <input class="d-none" name="tourSchedule.0.weekdays[4]" type="checkbox" checked={data?.thu == '1' ? 1 : 0} 
                                    /><span class="">Thu</span>
                                </label>
                                <label>
                                    <input class="d-none" name="tourSchedule.0.weekdays[5]" type="checkbox" checked={data?.fri == '1' ? 1 : 0} 
                                    /><span class="">Fri</span>
                                </label>
                                <label>
                                    <input class="d-none" name="tourSchedule.0.weekdays[6]" type="checkbox" checked={data?.sat == '1' ? 1 : 0} 
                                    /><span class="">Sat</span>
                                </label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4 col-sm-2  col-lg-1'>
                                <div className='date-select-cover'>
                                    <label>Time</label>
                                    <input className="" value={data?.time} />
                                </div>
                            </div>
                            
                            <div className='col-4 col-sm-2  col-lg-1'>
                                <div className='date-select-cover'>
                                    <label>AM/PM</label>
                                    <input className=""  value={data?.time_format}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div class="main-check-cover">
                                <label class="checkcontainer mb-3">
                                    <input name="contractExpires" type="checkbox" class="check-work" checked={data?.email_alert == '1' ? true : false}  />
                                    <span class="checkmark"></span>Add Email 
                                </label>
                                {/* <label class="checkcontainer mb-3">
                                    <input name="contractExpires" type="checkbox" class="check-work"  checked={data?.text_alert == '1' ? true : false} />
                                    <span class="checkmark"></span>Add Text
                                </label> */}
                                <label class="checkcontainer mb-3">
                                    <input name="contractExpires" type="checkbox" class="check-work"  checked={data?.notification_alert == '1' ? true : false} />
                                    <span class="checkmark"></span>Push Notification
                                </label>
                            </div>
                        </div>
                        
                    </div>
                </section>
            )}
            </Formik>
      
        </div>}
        </>
        
    )
}

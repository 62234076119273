import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { OnboardingSchema } from '../../../validations/ValidationSchema';
import { addOnboardingAPI, viewOnboardingAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';

export const ViewOnbordingQuestions = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let { onbording_id } = useParams();
    const location = useLocation();
    const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
    const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
    const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;
    

    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
            if(onbording_id){
                editOnboarding(value)
            }else{
                addOnboarding(value)
            }
    }

    const editOnboarding = async(value) =>{

        setLoading(true)
        console.log('value',value);
        //console.log('q',value.question);
        console.log('answerType',value.weight_type[0].answerType);
        console.log('answer',value.weight_type[0].answer);
        const question = value.question
        const answer_1 = value.weight_type[0].answer
        const answer_2 = value.weight_type[1].answer
        const answer_3 = value.weight_type[2].answer
        const answer_4 = value.weight_type[3].answer

        addOnboardingAPI({
                question : question,
                answer_1 : answer_1.toString(),
                answer_2 : answer_2.toString(),
                answer_3 : answer_3.toString(),
                answer_4 : answer_4.toString(),
                onbording_id : onbording_id.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate("/app/onboarding");
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const addOnboarding = async(value) =>{

        setLoading(true)
        console.log('value',value);
        //console.log('q',value.question);
        console.log('answerType',value.weight_type[0].answerType);
        console.log('answer',value.weight_type[0].answer);
        const question = value.question
        const answer_1 = value.weight_type[0].answer
        const answer_2 = value.weight_type[1].answer
        const answer_3 = value.weight_type[2].answer
        const answer_4 = value.weight_type[3].answer

        // let weight_typeLength = value.weight_type.length;
        // if(weight_typeLength > 4 || weight_typeLength < 4){
        //     toast.error('You have to submit 4 different weightage for each answer')
        //     return true;
        // }
        
        addOnboardingAPI({
                question : question,
                answer_1 : answer_1.toString(),
                answer_2 : answer_2.toString(),
                answer_3 : answer_3.toString(),
                answer_4 : answer_4.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate("/app/onboarding");
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const handleCancel = () =>{
        navigate(`/app/onboarding`, { state: {pageNumber, searchedValue, rowPerPage}});
    }

    
    // Get onboarding 
    useEffect(() => {
        if(onbording_id){
            viewOnboardingAPI({onbording_id : onbording_id})
                .then(response => {
                    //setDashboardData(response)
                setData(response?.data)
                setLoading(false)
            
                })
        }else{
            setLoading(false)
        }
    },[]);


    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={OnboardingSchema}
          initialValues={
            {   weight_type: [  
                    {answerType : data?.answer_1_text?data?.answer_1_text :"", answer : data?.answer_1?data?.answer_1 : 1},
                    {answerType : data?.answer_2_text?data?.answer_2_text :"", answer : data?.answer_2?data?.answer_2 : 1},
                    {answerType : data?.answer_3_text?data?.answer_3_text :"", answer : data?.answer_3?data?.answer_3 : 1},
                    {answerType : data?.answer_4_text?data?.answer_4_text :"", answer : data?.answer_4?data?.answer_4 : 1}],
                question :data.question ?data.question : ""  
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                    { onbording_id ? 'View Questions' : 'Add Questions'}
                                  
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                  <Row className="gx-xl-5">

                                       <Col sm={6} md={6} lg={12} className=" pb-1">
                                        <Form.Group className="mb-4 lh-sm">
                                            <Form.Label className="mb-1 fw-normal labelColor fs-13">Question Title</Form.Label>
                                            <div className="static-data fs-16">{values.question}</div>
                                        </Form.Group>
                                      </Col> 
                                      <Col sm={6} md={6} lg={3} className=" pb-1">
                                        <Form.Group className="mb-4 lh-sm">
                                            <Form.Label className="mb-1 fw-normal labelColor fs-13">{data.answer_1_text}</Form.Label>
                                            <div className="static-data fs-16">{data.answer_1}</div>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6} md={6} lg={3} className=" pb-1">
                                        <Form.Group className="mb-4 lh-sm">
                                            <Form.Label className="mb-1 fw-normal labelColor fs-13">{data.answer_2_text}</Form.Label>
                                            <div className="static-data fs-16">{data.answer_2}</div>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6} md={6} lg={3} className=" pb-1">
                                        <Form.Group className="mb-4 lh-sm">
                                            <Form.Label className="mb-1 fw-normal labelColor fs-13">{data.answer_3_text}</Form.Label>
                                            <div className="static-data fs-16">{data.answer_3}</div>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6} md={6} lg={3} className=" pb-1">
                                        <Form.Group className="mb-4 lh-sm">
                                            <Form.Label className="mb-1 fw-normal labelColor fs-13">{data.answer_4_text}</Form.Label>
                                            <div className="static-data fs-16">{data.answer_4}</div>
                                        </Form.Group>
                                      </Col>
                                      
                                      
                                  </Row>
                                  
                                  {errors && errors.weight_type && typeof(errors.weight_type)=='string' && <span className='text-danger'>{errors?.weight_type}</span>}

                                  <Stack direction="horizontal" gap={3} className="my-3">
                                      
                                      {/* <Link to="/app/onboarding"  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                                      <div onClick={handleCancel}  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</div>
                                  </Stack>
                              </div>
                          </div>
                      </div>  
                      
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { getUserDetailsAPI } from "../../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import { useParams } from "react-router-dom";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ActionCell } from "../../../../components/common/dataGrid/ActionCell";
import { getUserEventListAPI } from "../../../../services/userService";
import { MatDataGrid } from "../../../../components/common/dataGrid/DataGrid";

const customTheme = createTheme({
  typography: {
    fontFamily: '"Source Sans Pro", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#EB0024",
    },
    text: {
      primary: "#000000",
      secondary: "#999999",
    },
  },
});

export const DetailsTenantManagement = () => {
  const [userData, setUserData] = useState({});
  const [mutation, setMutation] = useState(true);
  const { id } = useParams();
  const [userId, setUserId] = useState(id);
  const [loading, setLoading] = useState(true);

  const [selectedValue, setSelectedValue] = useState("");

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [userEventData, setUserEventData] = useState({});

  const columns = [
    {
      field: "member_name",
      headerName: "Profile Name",
      editable: false,
      flex: 0.6,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "name",
      headerName: "Event Name",
      editable: false,
      flex: 0.6,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "date",
      headerName: "Event Date",
      editable: false,
      flex: 0.3,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "action",
      //headerName: "Start & End Time",
      headerName: "Start Time",
      editable: false,
      flex: 0.3,
      minWidth: 300,
      maxWidth: 300,
      disableColumnMenu: true,
      sortable: false,
      // renderCell: (props) => { return `${props.row.start_time} to ${props.row.end_time}` }
      // renderCell: (props) => {
      //   return `${props.row.start_time != "" ? props.row.start_time : "N/A"}  ${
      //     props.row.end_time != "" ? `to ${props.row.end_time}` : ""
      //   }`;
      // },
      renderCell: (props) => {
        return `${props.row.start_time != "" ? props.row.start_time : "N/A"}`;
      },
    },
  ];

  // Get user list details
  const fetchUsersDetails = (userId) => {
    getUserDetailsAPI(userId).then((response) => {
      setUserData(response.data);
      setLoading(false);
    });
  };

  // On page load fetch user list
  useEffect(() => {
    fetchUsersDetails(userId);
  }, [mutation]);

  // Get user list details
  const fetchUsersTransactions = (params) => {
    getUserEventListAPI({ ...params }).then((response) => {
      setUserEventData(response.userResult);
      setPage(response.page);
      setCurrentPage(response.page);
      setRowPerPage(response.limit);
      setLoading(false);
    });
  };

  // On page load fetch user list
  useEffect(() => {
    fetchUsersTransactions({
      userId: userId,
      page: page,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    });
  }, [mutation]);

  const detailsData = [
    {
      title: "Full Name",
      detail: userData.first_name,
    },
    {
      title: "Email",
      detail: userData.email,
    },
    {
      title: "Mobile",
      detail: userData?.phone_number ? userData?.phone_number : "N/A",
    },
    {
      title: "Date of Birth",
      detail:
        userData.dob && userData.dob != null && userData.dob != ""
          ? moment(userData.dob).format("MM/DD/YYYY")
          : "N/A",
    },
    {
      title: "Plan Purchased",
      detail: userData?.subscriptionData ? "Yes" : "No",
    },
    {
      title: "Plan Type / Name",
      detail: userData?.subscriptionData
        ? userData?.subscriptionData?.purchased_plan?.title
        : "N/A",
    },
    {
      title: "Purchased Date",
      detail: userData?.subscriptionData
        ? moment(userData?.subscriptionData.start_date).format("MM/DD/YYYY")
        : "N/A",
      //detail: userData?.subscriptionData?.length>0 ? moment(userData?.subscriptionData[0]?.start_date).format('MM/DD/YYYY'):"NA"
    },
    {
      title: "Plan Expiry",
      //detail: moment(userData?.subscriptionData[0]?.end_date).format('MM/DD/YYYY')
      //detail: userData?.subscriptionData?.length>0 ? moment(userData?.subscriptionData[0]?.end_date).format('MM/DD/YYYY'):"NA"
      detail: userData?.subscriptionData
        ? moment(userData?.subscriptionData.end_date).format("MM/DD/YYYY")
        : "N/A",
    },
  ];
  return (
    <>
      <Row className="py-4">
        {detailsData.map((data) => {
          const { title, detail } = data;
          return (
            <Col key={title} sm={6} lg={4} xl={3}>
              {/* <pre>{JSON.stringify(userData?.subscriptionData?.length,null,2)}</pre> */}
              <Form.Group className="pb-1 mb-5 lh-sm">
                <Form.Label className="mb-1 fw-normal labelColor fs-13">
                  {title}
                </Form.Label>
                <div className="static-data fs-16">{detail}</div>
              </Form.Group>
            </Col>
          );
        })}
      </Row>

      <h5 className="mb-0 position-relative fs-18">User Added Events</h5>
      <MatDataGrid controlId={"id"} columns={columns} data={userEventData} />
      <Loader isLoading={loading} />
    </>
  );
};

import Axios from "axios"
import storage from "../helpers/storage"



/**
 * Axios Interceptor
 * @param {*} config
 * @returns {*}
 */
function authRequestInterceptor(config) {
  const token = storage.getToken()
  if (token) {
    config.headers.authorization = `bearer ${token}`
  }
  config.headers.Accept = "application/json"
  return config
}

/**
 * Axios Object to use
 * @type {*}
 */
export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response.status === 401) {
      
      if (storage.getToken() !== null) {
        localStorage.removeItem('user-info');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('LH_refreshToken');
        localStorage.removeItem('LH_token');
        window.location.reload();
      }else{
        localStorage.removeItem('user-info');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('LH_refreshToken');
        localStorage.removeItem('LH_token');
        window.location=process.env.REACT_APP_BASE_URL;
      }
    }
    return Promise.reject(error)
  }
)

import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';

import { ViewUser } from './View/View';
import { EditOnbordingQuestions } from './Edit/Edit';
import { AddOnbordingQuestions } from './Add/Add';
import { ViewOnbordingQuestions } from './View/View';


export const OnbordingQuestions = () => {

    
    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
            {/* <Route path="/list" element={<AllList />} />
            <Route path="/view/:id" element={<ViewUser />} />
             <Route path="/edit/:id" element={<EditOnbordingQuestions />} />  */}
            <Route path="/view/:onbording_id" element={<ViewOnbordingQuestions />} /> 
            <Route path="/edit/:onbording_id" element={<AddOnbordingQuestions />} /> 
            <Route path="/add" element={<AddOnbordingQuestions  exect={true} />} />
        </Routes>
    )
};
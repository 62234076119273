import {
  Button,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar
} from "react-bootstrap"
import { FaAngleDown, FaBell } from "react-icons/fa"
import { Link } from "react-router-dom"
import "./header.scss"
import Logo from "../../../assets/images/logo.svg"
import { useNavigate } from "react-router-dom" 
import Cookies from "universal-cookie";

/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 12:56:12 PM
 *
 * @param {{ isActiveSidebar: any; toggleSidebarButton: any; }} {
  isActiveSidebar, toggleSidebarButton
}
 * @returns {*}
 */

export const Header = ({ isActiveSidebar, toggleSidebarButton }) => {

  const navigate = useNavigate()
  const cookies = new Cookies();

  const handleLogout = () => {
      localStorage.removeItem("LH_refreshToken");
      localStorage.removeItem("LH_token");
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("user-info");
      navigate('/');
  }

  const UserProfile = "https://love-habit-qa-public.s3.amazonaws.com/mylo-logo-icon.png"
  const userName = "John Due"
  return (
    <Navbar bg="" variant="light" className="py-0 px-md-1">
      <Container fluid className="px-3 mh-60">
        <Button
          onClick={toggleSidebarButton}
          variant="link"
          className="align-items-center d-flex justify-content-center navMenuBtn p-0 bg-white rounded-circle h-40 w-40"
        >
          <span
            className={`bg-black d-inline-block menuTrigger position-relative text-center ${
              isActiveSidebar ? "active" : ""
            }`}
          ></span>
        </Button>
        <h1 className="fw-bold h4 mb-0 ms-2 ps-1 text-black">
          {/* { cookies.get("page_heading") ? cookies.get("page_heading") : '' } */}
        </h1>
        <Link to="/" className="d-none mx-2 mx-md-3 px-1">
          <Image
            className="img-fluid"
            src={Logo}
            alt="Logo"
            width={80}
            height={32}
          />
        </Link>
        <Nav className="ms-auto align-items-center">
          
          
          <Dropdown className="profileDropdown ms-md-2 ms-1">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-profile"
              className="border-0 fs-14 fw-semibold text-decoration-none p-0 text-light"
            >
              <Image
                className="object-fit-cover rounded-circle"
                src={UserProfile}
                alt="Profile Image"
                width={40}
                height={40}
              />
              <span className="align-middle d-none d-md-inline-block ms-1 px-2 text-truncate mw-150">
                {userName}
              </span>
              {/*<FaAngleDown size={16} />*/}
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="fs-14 shadow-sm">
              <Dropdown.Item as={Link} to="/app/profile" className="fw-medium">
                Change Password
              </Dropdown.Item>
              <Dropdown.Divider className="mb-1" />
              <Dropdown.Item className="fw-medium" onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className="divider mx-1 mx-md-3 vr opacity-100"></div>

          {/* <Dropdown className="notificationDropdown">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-notification"
              className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-light p-2"
            >
              <FaBell size={22} />
              <span className="align-items-center badge bg-danger border border-white customBadge d-flex fs-11 fw-semibold justify-content-center position-absolute px-1 rounded-pill start-100 top-0">
                5<span className="visually-hidden">unread messages</span>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" className="fs-14 shadow-sm px-3">
              Coming soon...
            </Dropdown.Menu>
          </Dropdown> */}



        </Nav>
      </Container>
    </Navbar>
  )
}

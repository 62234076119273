import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import { onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { AddCmsSchema } from '../../../validations/ValidationSchema';
import { addCmsAPI, viewCmsAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const AddCms = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let { cms_id } = useParams();
    


    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };
    

    // Submit onboarding handleChange
    const handleSubmit = async (value, actions) => {
        if (cms_id) {
            editCms(value)
        } else {
            addCms(value)
        }
    }

    const editCms = async (value) => {

        setLoading(true)

        const title = value.title
        const content = value.content
        const page_type = value.page_type
        const cms_id = value.cms_id

        addCmsAPI({
            cms_id: cms_id,
            title: title.toString(),
            content: content.toString(),
            page_type: page_type.toString(),
        })
            .then(response => {
                setLoading(false)
                toast.success(response.message)
                navigate("/app/cms");
            })
            .catch(error => {
                setLoading(false)
                toast.error(error.response.data.message)
            })
    }

    const addCms = async (value) => {


        const title = value.title
        const content = value.content
        const page_type = value.page_type

        addCmsAPI({
            title: title.toString(),
            content: content.toString(),
            page_type: page_type.toString(),
        })
            .then(response => {
                setLoading(false)
                toast.success(response.message)
                navigate("/app/cms");
            })
            .catch(error => {
                setLoading(false)
                toast.error(error.response.data.message)
            })
    }


    // Get onboarding 
    useEffect(() => {
        if (cms_id) {
            viewCmsAPI({ cms_id: cms_id })
                .then(response => {
                    setData(response?.data)
                    setLoading(false)

                })
        } else {
            setLoading(false)
        }
    }, []);


    const modules = {
        toolbar: [
            //   [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            //   [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            //   ['link', 'image',],
            ['link'],
            [{ 'script': 'sub' }, { 'script': 'super' }], // Add super and sub options here
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };

    const formats = [
        // 'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent',
        // 'link', 'image', 'video',
        'link',
        'script', // Include the script format
    ];


    return (
        <>
            {loading ? <Loader isLoading={loading} /> : <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
                <Formik
                    validationSchema={AddCmsSchema}
                    initialValues={
                        {

                            page_type: data.page_type ? data.page_type : "",
                            title: data.title ? data.title : "",
                            content: data.content ? data.content : ""
                        }
                    }
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false)
                        handleSubmit(values, actions)
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue,
                        touched,
                        isValid,
                        errors
                    }) => (
                        <div className="flex-grow-1 pageContent position-relative pt-4">
                            {/* {JSON.stringify(data)} */}
                            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                                <div className="theme-card-header px-1">
                                    <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                                        <h5 className="mb-0 position-relative fw-medium">
                                            <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                                <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                            </div>
                                            {cms_id ? 'Edit CMS Page' : 'Add CMS Page'}

                                        </h5>
                                    </Stack>
                                </div>
                                <div className="flex-grow-1 d-flex flex-column px-1">
                                    <div className="p-3 d-flex flex-column h-100">
                                        <div className="d-flex flex-column flex-grow-1 theme-from">

                                            <Row className="gx-xl-5">
                                                {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
                                                <Col sm={6} md={6} lg={8} className="mb-4 pb-1">

                                                    <CustomSelect
                                                        options={[
                                                            { label: 'ABOUT APP', value: 'ABOUT_APP' },
                                                            { label: 'ABOUT ARTICLE TEXT', value: 'ABOUT_ARTICLE_TEXT' },
                                                            { label: 'ABOUT VIDEO TEXT', value: 'ABOUT_VIDEO_TEXT' },
                                                            { label: 'ABOUT THE PROGRAM', value: 'HOW_IT_WORKS' },
                                                            { label: 'ABOUT US', value: 'ABOUT_US' },
                                                            { label: 'ADD PROFILE INFO POP-UP', value: 'ADD_PROFILE_INFO_POPUP' },
                                                            { label: 'ASSESSMENT CATEGORY INFO POP-UP', value: 'ASSESSMENT_CATEGORY_INFO_POPUP' },
                                                            { label: 'CONTINUE ASSESSMENT', value: 'CONTINUE_ASSESSMENT_APP' },
                                                            { label: 'CONTINUE ASSESSMENT SCHEDULE TEXT', value: 'CONTINUE_ASSESSMENT_SCHEDULE_TEXT' },
                                                            { label: 'DELETE ACCOUNT', value: 'DELETE_ACCOUNT_APP' },
                                                            { label: 'DEPRESSION CATEGORY INFO POP-UP', value: 'DEPRESSION_CATEGORY_INFO_POPUP' },
                                                            { label: 'HOME RELATIONSHIP LIST TEXT', value: 'HOME_RELATIONSHIP_LIST_TEXT' },
                                                            { label: 'INFO ABOUT FAVORITES SPECIFICS ON RELATIONSHIP', value: 'INFO_FAVORITES_ON_RELATIONSHIP' },
                                                            { label: 'INFO ABOUT INTERESTS SPECIFICS ON RELATIONSHIP', value: 'INFO_SPECIFICS_ON_RELATIONSHIP' },
                                                            { label: 'INFO ABOUT INTERESTS ICON ON RELATIONSHIP', value: 'INFO_INTERESTS_ICON_ON_RELATIONSHIP' },
                                                            
                                                            { label: 'INFO POPUP BUSINESS CATEGORY ASSESSMENT', value: 'INFO_POPUP_BUSINESS_CATEGORY_ASSESSMENT' },
                                                            { label: 'INFO POPUP DEPRESSION CATEGORY ASSESSMENT', value: 'INFO_POPUP_DEPRESSION_CATEGORY_ASSESSMENT' },
                                                            { label: 'INFO POPUP EXECUTIVE CATEGORY ASSESSMENT', value: 'INFO_POPUP_EXECUTIVE_CATEGORY_ASSESSMENT' },
                                                            { label: 'INFO POPUP NEURODIVERSITY CATEGORY ASSESSMENT', value: 'INFO_POPUP_NEURODIVERSITY_CATEGORY_ASSESSMENT' },
                                                            
                                                            { label: 'MARK COMPLETED CONFIRM POPUP', value: 'MARK_COMPLETED_CONFIRM_POPUP' },
                                                            { label: 'MARK COMPLETED POP-UP', value: 'MARK_COMPLETED_POPUP' },
                                                            { label: 'MARK COMPLETED REASON', value: 'MARK_COMPLETED_REASON' },
                                                            { label: 'MARK COMPLETED RETRY POP-UP', value: 'MARK_COMPLETED_RETRY_POPUP' },
                                                            { label: 'ONBOARDING AFTER 5 QUESTION POP-UP', value: 'ONBOARDING_AFTER_5_QUESTION_POPUP' },
                                                            { label: 'PAYMENT PLAN SCREEN TEXT', value: 'PAYMENT_PLAN_SCREEN_TEXT' },
                                                            { label: 'PRIVACY POLICY', value: 'PRIVACY_POLICY' },
                                                            { label: 'RE-ASSESSMENT', value: 'RE_ASSESSMENT_APP' },
                                                            { label: 'RE-ASSESSMENT COMPLETED', value: 'REASSESSMENT_COMPLETED' },
                                                            { label: 'RE-ASSESSMENT NOT COMPLETED', value: 'REASSESSMENT_NOT_COMPLETED' },
                                                            { label: 'RELATIONSHIP EVENT SCREEN POP-UP', value: 'ADD_RELATIONSHIP_ADD_EVENT_POPUP' },
                                                            { label: 'REMINDER RESPONSE CHECK TEXT', value: 'REMINDER_RESPONSE_CHECK_TEXT' },
                                                            { label: 'RESULT APP', value: 'TEXT_WITH_ASSESSMENT_RESULT_APP' },
                                                            { label: 'RESULT SCREEN POP-UP', value: 'RESULT_POP' },
                                                            { label: 'SKIP 2 ASSESSMENT QUESTION POP-UP', value: 'SKIP_QUESTION_OF_ASSESSMENT' },
                                                            { label: 'START ASSESSMENT', value: 'START_ASSESSMENT' },
                                                            { label: 'START ASSESSMENT POP-UP', value: 'START_ASSESSMENT_POPUP' },
                                                            { label: 'SUGGESTED MESSAGE DETAIL TEXT', value: 'SUGGESTED_MESSAGE_DETAIL_TEXT' },
                                                            { label: 'SUGGESTED MESSAGE LIST TEXT', value: 'SUGGESTED_MESSAGE_LIST_TEXT' },
                                                            { label: 'TERMS CONDITIONS', value: 'TERMS_CONDITIONS' },
                                                        ]}
                                                        controlId="page_type"
                                                        label="Page Type*"
                                                        value={values.page_type}
                                                        name="page_type"
                                                        errorsField={errors.page_type}
                                                        touched={touched.page_type}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        onChange={handleChange}

                                                    />
                                                    <span className='text-danger answer-type-error fs-11'>{getIn(errors, `page_type`)}</span>
                                                </Col>

                                            </Row>

                                            <Row className="gx-xl-5">
                                                {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                                <Col sm={6} md={6} lg={8} className="mb-4 pb-1">
                                                    <InputText
                                                        controlId="title"
                                                        label="Page Title*"
                                                        value={values.title}
                                                        name="title"
                                                        maxLength="300"
                                                        errorsField={errors.title}
                                                        touched={touched.title}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        className="w-100"
                                                    />
                                                </Col>

                                            </Row>

                                            <Row className="gx-xl-5">
                                                {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                                    
                                                    {
                                                    values.page_type == 'MARK_COMPLETED_POPUP' || values.page_type == 'MARK_COMPLETED_REASON' 
                                                    || values.page_type == 'MARK_COMPLETED_RETRY_POPUP' || values.page_type == 'ONBOARDING_AFTER_5_QUESTION_POPUP'
                                                    || values.page_type == 'ADD_RELATIONSHIP_ADD_EVENT_POPUP' || values.page_type == 'RESULT_POP' || values.page_type == 'SKIP_QUESTION_OF_ASSESSMENT'
                                                    || values.page_type == 'ADD_PROFILE_INFO_POPUP' || values.page_type == 'START_ASSESSMENT_POPUP' || values.page_type == 'DEPRESSION_CATEGORY_INFO_POPUP'
                                                    || values.page_type == 'ABOUT_ARTICLE_TEXT' || values.page_type == 'ABOUT_VIDEO_TEXT'
                                                    || values.page_type == 'CONTINUE_ASSESSMENT_SCHEDULE_TEXT' || values.page_type == 'REMINDER_RESPONSE_CHECK_TEXT'
                                                    || values.page_type == 'HOME_RELATIONSHIP_LIST_TEXT' || values.page_type == 'PAYMENT_PLAN_SCREEN_TEXT'
                                                    || values.page_type == 'SUGGESTED_MESSAGE_LIST_TEXT' || values.page_type == 'SUGGESTED_MESSAGE_DETAIL_TEXT'
                                                    || values.page_type == 'INFO_FAVORITES_ON_RELATIONSHIP' || values.page_type == 'INFO_SPECIFICS_ON_RELATIONSHIP'
                                                    || values.page_type == 'INFO_INTERESTS_ICON_ON_RELATIONSHIP'
                                                    || values.page_type == 'INFO_POPUP_BUSINESS_CATEGORY_ASSESSMENT' || values.page_type == 'INFO_POPUP_DEPRESSION_CATEGORY_ASSESSMENT'
                                                    || values.page_type == 'INFO_POPUP_EXECUTIVE_CATEGORY_ASSESSMENT' || values.page_type == 'INFO_POPUP_NEURODIVERSITY_CATEGORY_ASSESSMENT'
                                                    || values.page_type == 'MARK_COMPLETED_CONFIRM_POPUP' || values.page_type == 'INFO_POPUP_ATTACHMENT_CATEGORY_ASSESSMENT'
                                                    || values.page_type == 'ASSESSMENT_CATEGORY_INFO_POPUP' || values.page_type == 'REASSESSMENT_COMPLETED' || values.page_type == 'REASSESSMENT_NOT_COMPLETED' ?

                                                            <Col sm={6} md={6} lg={8} className="mb-4 pb-1">
                                                                    <InputText
                                                                        controlId="content"
                                                                        label="Description"
                                                                        value={values.content}
                                                                        name="content"
                                                                        maxLength="300"
                                                                        //maxLength={values.page_type == 'ABOUT_ARTICLE_TEXT' || values.page_type == 'ABOUT_VIDEO_TEXT' ? '180' : '105'}
                                                                        errorsField={errors.content}
                                                                        touched={touched.content}
                                                                        handleChange={handleChange}
                                                                        handleBlur={handleBlur}
                                                                        className={`w-100 ${errors.content? `textRedErr`:`ppppp`}`}
                                                                        multiline={true}
                                                                    /> 
                                                                    <span id="charCount" className={`fs-11 ${errors.content && touched.content ? `text-danger answer-type-error` : `` } `} >Characters Count: {values.content?.length}</span>
                                                            </Col>        
                                                        : 
                                                            <>
                                                                <Col sm={6} md={6} lg={8} className="mb-4 pb-1">
                                                                <Form.Label className="mb-1 fw-normal labelColor fs-13">Description</Form.Label>
                                                                    <ReactQuill
                                                                        modules={modules}
                                                                        formats={formats}
                                                                        name="content"
                                                                        theme="snow"
                                                                        value={values.content}
                                                                        onChange={(value) => setFieldValue('content', value)}
                                                                    />
                                                                    <span className='text-danger answer-type-error fs-11'>{getIn(errors, `content`)}</span>
                                                                </Col>    
                                                            </>
                                                            

                                                    }
                                                    


                                                
                                            </Row>


                                            {errors && errors.weight_type && typeof (errors.weight_type) == 'string' && <span className='text-danger'>{errors?.weight_type}</span>}

                                            <Stack direction="horizontal" gap={3} className="my-3">
                                                <Button
                                                    variant="primary"
                                                    onClick={handleSubmit}
                                                    className="rounded-xs fs-15"
                                                    type="submit"
                                                >Save</Button>
                                                <Link to="/app/cms" className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )}
                </Formik>

            </div>}
        </>
    )
}
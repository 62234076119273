import React, { useState } from 'react'
import { Breadcrumb, Card, Nav, Stack, Tab } from 'react-bootstrap'
import { MdSupervisorAccount } from 'react-icons/md'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DetailsTenantManagement } from './details/details';
import { ManageTenantManagement } from './manage/List';
import { DetailsAssessment } from './assessment/details';
import { DetailsNotification } from './notification/details';

export const ViewUser = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
  const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
  const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;

  const userTabsData = [
    {
      id: 1,
      name: 'Basic Info',
      Component: <DetailsTenantManagement />
    },
    {
      id: 2,
      name: 'Payment History',
      Component: <ManageTenantManagement />
    },
    {
      id: 3,
      name: 'Assessment Details',
      Component: <DetailsAssessment />
    },
    {
      id: 4,
      name: 'Notification Settings',
      Component: <DetailsNotification />
    },
  ]
  const [activeTab, setActiveTab] = useState('tab-1');
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const handleCancel = () =>{
      navigate(`/app/users`, { state: {pageNumber, searchedValue, rowPerPage}});
  }

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
              <h5 className="mb-0 position-relative fs-18">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <MdSupervisorAccount size={40} />
                </div>
                {activeTab ==='tab-1' ? 'User Details' : 'User Details'}
              </h5>
              <div className='d-flex justify-content-end'>
                    <div  onClick={handleCancel}  className='btn btn-primary ms-2'  >Back</div>
              </div>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column h-100 overflow-auto">
            <Tab.Container
              id="user-tab-container"
              defaultActiveKey={activeTab}
              mountOnEnter
              unmountOnExit
              onSelect={handleTabSelect}
            >
              <div className="d-flex flex-column h-100 fs-15">
                <div className="theme-tab-header">
                  <Nav variant="tabs" className="px-3 flex-nowrap text-nowrap overflow-x-auto overflow-y-hidden border-light">
                    {userTabsData.map((tabNames) => {
                      const { id, name } = tabNames;
                      return (
                        <Nav.Item key={id}>
                          <Nav.Link eventKey={`tab-${id}`} className="border-4 border-end-0 border-start-0 border-top-0 fw-semibold text-center fs-18 text-primary fw-medium">{name}</Nav.Link>
                        </Nav.Item>
                      )
                    })}
                  </Nav>
                </div>

                <Tab.Content className="theme-tab-content flex-grow-1 px-3 overflow-auto">
                  {userTabsData.map((tabNames) => {
                    const { id, Component } = tabNames;
                    return (<Tab.Pane className="h-100" key={id} eventKey={`tab-${id}`}>
                      <div className="p-1 h-100 d-flex flex-column">{Component}</div>
                    </Tab.Pane>
                    )
                  })}
                </Tab.Content>

              </div>
            </Tab.Container>
          </div>
        </Card>
      </div>
    </div>
  )
}

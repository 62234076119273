import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Row, Stack } from 'react-bootstrap'
import { MdHandshake, MdSupervisorAccount, MdClear } from 'react-icons/md'
import { MatDataGrid } from '../../../components/common/dataGrid/DataGrid'
import { MatDataGridWithPagination } from '../../../components/common/dataGrid/DataGridWithPagination'
import { ActionCell } from '../../../components/common/dataGrid/ActionCell';
import { SwitchCell } from '../../../components/common/dataGrid/SwitchCell';
import { TextField } from '@mui/material';
import CustomSelect from '../../../components/common/CustomSelect';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getCmsListAPI, deleteUser } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from 'react-router-dom';
import { nameBasedProtectedRoutes } from '../../../router/protected';


const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: '#17B0B2',
    },
    text: {
      primary:'#000000',
      secondary: '#777777',
    }
  }
});




export const AllList = () => {

  const cookies = new Cookies();

  
  const location = useLocation();
  const [selectedValue, setSelectedValue] = useState(location?.state?.searchedValue ? location?.state?.searchedValue : '');
  const [page, setPage] = useState(location?.state?.pageNumber ? location?.state?.pageNumber : '1');
  const [rowPerPage, setRowPerPage] = useState(location?.state?.rowPerPage ? location?.state?.rowPerPage : 10);
  const [currentPage,setCurrentPage] = useState(1)
  const [sortColumn,setSortColumn] = useState();
  const [sortDirection,setSortDirection] = useState();
  const [loading, setLoading] = useState(true)
  const [userStatus,setUserStatus] = useState();
  const [userData,setUserData] = useState([])
  const [mutation, setMutation] = useState(true);
  const [totalReults, setTotalResults] = useState(0);
  const [totalPages,setTotalPages] = useState(0);
  const navigate = useNavigate();
  

  const columns = [
    {
      field: 'sr_no',
      headerName: 'S. No.',
      editable: false,
      sortable: false,
      flex: 0.05,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: 'action',
      headerName: 'Action',
      editable: false,
      flex: 0.20,
      minWidth: 130,
      maxWidth: 130,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => { return <ActionCell controlId='app/cms' isViewEnable={true} isEditEnable={false} isDeleteEnable={false}  deleteFunctionName={deleteRow} isLogInEnable={true} redirectID={props.row.id} rowData={props.row} pageNumber={page}  searchedValue={selectedValue} rowPerPage={rowPerPage}/> }
    },
    {
      field: 'category_type',
      headerName: 'Category',
      editable: false,
      flex: 0.60,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: 'title',
      headerName: 'Title',
      editable: false,
      flex: 0.60,
      minWidth: 350,
      maxWidth: 350,
    },
    {
      field: 'content',
      headerName: 'Content',
      editable: false,
      flex: 0.50,
      minWidth: 450,
      maxWidth: 450,
      renderCell: (props) => { return <span  dangerouslySetInnerHTML={{ __html: props.row.content }} />  },
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      editable: false,
      flex: 0.50,
      minWidth: 150,
      maxWidth: 150,
    }
    
  ];
 
  // Delete
  const deleteRow = (userId) => {
    setLoading(true)
    console.log(userId);
    deleteUser({
      userId: userId.toString(),
    })
    .then(response => {
       setMutation(!mutation)
       toast.success(response.data.message)
       setLoading(false)
    })
    .catch(error => {
       setLoading(false)
      toast.error(error.message)
    })

  }

  // Searching and listing user list
  const handleValueChange = (value) => {
    setSelectedValue(value);    
    fetchUsers({
       page: page,
       limit: rowPerPage,
       ...(value ? { search: value } : {}),
       });
  };

  const handleClear = ()=>{
    setSelectedValue("")
    fetchUsers({
      page: page,
      limit: rowPerPage,
      search: "",
      });
  }

  
  // Get list details
  const fetchUsers = (params) =>{
    getCmsListAPI({...params})
    .then(response => {
        setUserData(response.userResult)
        setPage(response.page)
        setCurrentPage(response.page)
        setRowPerPage(response.limit)
        setTotalResults(response.totalResults);
        setTotalPages(response.totalPages)
        setLoading(false)
    })
  }

  // Pagination work Start
  const handlePageChange = (params) => {
    setLoading(true);
    setPage(params.page + 1);
    setCurrentPage(params.page + 1);
    setRowPerPage(params.pageSize);
  };
  const handleSortModelChange = (params) => {
    setLoading(true)
    console.log("params",params[0])

    setSortColumn(params[0]?.field);
    setSortDirection(params[0]?.sort);
  }
  const handlePageSizeChange = (params) => {
    setLoading(true)
    setRowPerPage(params.pageSize);
  };
  // Pagination work End  

  // On page load fetch user list 
  useEffect(() => {
    
    console.log('location',location);
    //setLoading(true)
    fetchUsers({page : page,
                limit: rowPerPage,
                sortColumn: sortColumn,
                sortDirection: sortDirection,
                ...(selectedValue ? { search: selectedValue } : {}),
              })
              
  },[mutation,page,rowPerPage, sortColumn, sortDirection, currentPage]);

  return (
    <>
    <div className="d-flex flex-column pageContainer p-3 h-100">
      {loading ? "Loading..." : 
      
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          <div className="theme-card-header px-1">
            <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
              <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                  <MdSupervisorAccount size={40} />
                </div>
                CMS Pages  
              </h5>
              <button className='btn btn-primary' onClick={()=>{navigate('/app/cms/add')}} variant="primary">Add New</button>
            </Stack>
          </div>
          <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
            <div className="theme-data-table-filter px-1">
              <ThemeProvider theme={customTheme}>
                <Row className="justify-content-end">
                  <Col sm={5} lg={2} className="mb-3 pb-1">
                    <TextField
                      id="search-filter"
                      label="Search"
                      variant="standard"
                      fullWidth
                      value={selectedValue}
                      onChange={(e)=>{handleValueChange(e.target.value)}}
                    />
                    <MdClear className='clear-postion' onClick={handleClear}></MdClear>
                  </Col>
                  
                </Row>
              </ThemeProvider>
            </div>
            {/* <MatDataGrid controlId={'id'} columns={columns} data={userData} /> */}
              <MatDataGridWithPagination 
                controlId={'id'} 
                columns={columns}
                data={userData}
                onPaginationModelChange={handlePageChange}
                handleSortModelChange={handleSortModelChange}
                pageSize={rowPerPage}
                totalReults={totalReults}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageSizeChange={handlePageSizeChange}
                sortModel={sortColumn ? [
                  {
                      "field": sortColumn,
                      "sort": sortDirection
                  }
                ] : []}
              />
           
          </div>
        </Card>
      </div>
      }
    </div>
    <Loader isLoading={loading} />
    </>
    
  )
}

import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { AssessmentSchema } from '../../../validations/ValidationSchema';
import { submitAssessmentAPI, viewAssessmentAPI, levelsListingAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';

export const Edit = () => {

    // data
    const [data, setData] = useState([]);
    const [MessageCategoryData, setMessageCategoryData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    //const history = useHistory();
    let { cms_id } = useParams();
    const location = useLocation();
    const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
    const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
    const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;
    const backUrl = location?.state?.backUrl ? location?.state?.backUrl : '';
    

    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
            if(cms_id){
                editOnboarding(value)
            }else{
                addOnboarding(value)
            }
    }

    const editOnboarding = async(value) =>{

        setLoading(true)
        
        const category_id = value.category_id
        const level = value.level
        const question = value.question
        const answer_1 = value.weight_type[0].answer
        const answer_2 = value.weight_type[1].answer
        const answer_3 = value.weight_type[2].answer
        const answer_4 = value.weight_type[3].answer

        submitAssessmentAPI({
                category_id : category_id.toString(),
                level : level.toString(),
                question : question,
                answer_1 : answer_1.toString(),
                answer_2 : answer_2.toString(),
                answer_3 : answer_3.toString(),
                answer_4 : answer_4.toString(),
                row_id : cms_id.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate(backUrl, { state: {pageNumber, searchedValue, rowPerPage}});
            // navigate(-1)
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const addOnboarding = async(value) =>{ 

        setLoading(true)

        const category_id = value.category_id
        const level = value.level
        const question = value.question
        const answer_1 = value.weight_type[0].answer
        const answer_2 = value.weight_type[1].answer
        const answer_3 = value.weight_type[2].answer
        const answer_4 = value.weight_type[3].answer

        submitAssessmentAPI({
                category_id : category_id,
                level : level,
                question : question,
                answer_1 : answer_1.toString(),
                answer_2 : answer_2.toString(),
                answer_3 : answer_3.toString(),
                answer_4 : answer_4.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate(backUrl, { state: {pageNumber, searchedValue, rowPerPage}});
            // navigate(-1)
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const handleCancel = () =>{
        navigate(backUrl, { state: {pageNumber, searchedValue, rowPerPage}});
    }

    
    // Get onboarding 
    useEffect(() => {
        if(cms_id){
            viewAssessmentAPI({row_id : cms_id})
                .then(response => {
                setData(response?.data)
                setLoading(false)
            
            })
        }else{
            setLoading(false)
        }

        levelsListingAPI({search : ''})
                .then(response => {
                    setMessageCategoryData(response?.data)
                    setLoading(false)
                }) 
    },[]);


    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={AssessmentSchema}
          initialValues={
            {   weight_type: [  
                    {answerType : data?.answer_1_text?data?.answer_1_text :"", answer : data?.answer_1?data?.answer_1 : 1},
                    {answerType : data?.answer_2_text?data?.answer_2_text :"", answer : data?.answer_2?data?.answer_2 : 1},
                    {answerType : data?.answer_3_text?data?.answer_3_text :"", answer : data?.answer_3?data?.answer_3 : 1},
                    {answerType : data?.answer_4_text?data?.answer_4_text :"", answer : data?.answer_4?data?.answer_4 : 1}],
                question :data.question ?data.question : "" , 
                category_id :data.category_id ?data.category_id : "" ,  
                level :data.level ?data.level : ""  
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                    { cms_id ? 'Edit Assessment Questions' : 'Add Assessment Questions'}
                                  
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                    <Row className="gx-xl-5">
                                        {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
                                            <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                                                    <CustomSelect
                                                            options={[
                                                                { label: 'Attachment', value: '1' },
                                                                { label: 'Busyness', value: '2' },
                                                                { label: 'Depression', value: '3' },
                                                                { label: 'Executive Function', value: '4' },
                                                                { label: 'Neurodiversity', value: '5' },
                                                            ]}
                                                            controlId="category_id"
                                                            label="Select the category"
                                                            value={values.category_id}
                                                            name= "category_id"
                                                            errorsField={errors.category_id}
                                                            touched={touched.category_id}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            onChange={handleChange}
                                                    />
                                                    <span className='text-danger answer-type-error fs-11'>{getIn(errors,`category_id`)}</span>
                                            </Col>
                                            
                                        </Row>

                                        <Row className="gx-xl-5">
                                        {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
                                            <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                                                
                                                    <CustomSelect
                                                        options={ MessageCategoryData.map((categorysObj) => ({
                                                            label: categorysObj.level,
                                                            value: categorysObj.id,
                                                        }))}
                                                        controlId="level"
                                                        label="Select the level"
                                                        value={values.level}
                                                        name= "level"
                                                        errorsField={errors.level}
                                                        touched={touched.level}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        onChange={handleChange}

                                                    />
                                                    <span className='text-danger answer-type-error fs-11'>{getIn(errors,`level`)}</span>
                                            </Col>
                                            
                                        </Row>
                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={6} className="mb-4 pb-1">
                                          <InputText
                                              controlId="question"
                                              label="Questions Title*"
                                              value={values.question}
                                              name= "question"
                                              maxLength= "300"
                                              errorsField={errors.question}
                                              touched={touched.question}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                              multiline={true}
                                          />
                                      </Col>
                                  </Row>
                                  <FieldArray
                                      name="weight_type"
                                      render={arrayHelpers => (
                                          <div>
                                              {values.weight_type && values.weight_type.length > 0 ? (
                                                  values.weight_type.map((weightType, index) => (
                                                      <div key={index}>
                                                          <Row className="gx-xl-5">
                                                              <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                                                  <CustomSelect
                                                                      label="Answer Type*"
                                                                      options={[
                                                                          { label: onboardingAnswerType.FREQUENTLY, value: onboardingAnswerType.FREQUENTLY },
                                                                          { label: onboardingAnswerType.RARELY, value: onboardingAnswerType.RARELY },
                                                                          { label: onboardingAnswerType.SOMETIMES, value: onboardingAnswerType.SOMETIMES },
                                                                          { label: onboardingAnswerType.IDONTKNOW, value: onboardingAnswerType.IDONTKNOW },
                                                                      ]}
                                                                      // value={selectedValue}                                                                            
                                                                      name={`weight_type[${index}].answerType`}
                                                                      errorsField={getIn(errors,`weight_type[${index}].answerType`)}
                                                                      touched={getIn(touched,`weight_type[${index}].answerType`)}
                                                                      onChange={handleChange}
                                                                      value={getIn(values,`weight_type[${index}].answerType`)}

                                                                  />
                                                                  
                                                                  
                                                                  <span className='text-danger answer-type-error fs-11'>{getIn(errors,`weight_type[${index}].answerType`)}</span>
                                                                  {/* {getIn(touched,`weight_type[${index}].answerType`)} */}
                                                                  {/* {getIn(values,`weight_type[${index}].answerType`)} */}

                                                              </Col>
                                                              <Col sm={4} md={4} lg={2} className="mb-4 pb-1">
                                                                  <InputText 
                                                                      label="Add Weightage"
                                                                      type="number"
                                                                      handleChange={handleChange}
                                                                      handleBlur={handleBlur}
                                                                      errorsField={getIn(errors,`weight_type[${index}].answer`)}
                                                                      touched={getIn(touched,`weight_type[${index}].answer`)}
                                                                      className="w-100 fs-16 mb-4 custom-text-field"
                                                                      InputLabelProps={{
                                                                          className: "fs-15 grey-color"
                                                                      }}
                                                                      value={getIn(values,`weight_type[${index}].answer`)}
                                                                      controlId={`weight_type[${index}].answer`}
                                                                      
                                                                  />
                                                              </Col>
                                                              <Col sm={1} md={1} lg={1} className="mb-4 pb-1">
                                                                  {    
                                                                  // index==0 ?   
                                                                  //onClick={() => arrayHelpers.insert(index, '')}
                                                                     // <Button variant="primary" disabled>< FaPlus size={15}  /></Button> 
                                                                     // :
                                                                      // <Button variant="primary" onClick={() => arrayHelpers.remove(index, '')}><FaMinus size={15}  /></Button>
                                                                     // ''
                                                                  }
                                                                 
                                                              </Col>
                                                              
                                                              

                                                            
                                                          </Row>

                                                      </div>
                                                  ))
                                              ) : (
                                                  <button type="button" onClick={() => arrayHelpers.push('')}>
                                                      {/* show this when user has removed all weight_type from the list */}
                                                      Add a weight
                                                  </button>
                                              )}
                                              
                                          </div>
                                      )}
                                  />
                                  {errors && errors.weight_type && typeof(errors.weight_type)=='string' && <span className='text-danger'>{errors?.weight_type}</span>}

                                  <Stack direction="horizontal" gap={3} className="my-3">
                                      <Button 
                                          variant="primary"
                                          onClick={handleSubmit}
                                          className="rounded-xs fs-15"
                                          type="submit"
                                      >Save</Button>
                                      {/* <Link to="/app/assessment-category"  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                                      <div className="btn btn-outline-secondary rounded-xs fs-15" onClick={handleCancel}>Cancel</div>
                                  </Stack>
                              </div>
                          </div>
                      </div>
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}
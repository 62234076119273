import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Card, Col, Row, Stack } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { CustomTextField } from "../../components/common/CustomTextField";
import { CustomSelect } from "../../components/common/CustomSelect";
import { AiOutlineFieldTime } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik";
import { onboardingAnswerType } from "../../constants/commonValue";
import InputText from "../../components/common/InputText";
import { DefaultNotificationAlertSchema } from "../../validations/ValidationSchema";
import {
  submitDefaultNotificationAlertAPI,
  viewDefaultNotificationAlertAPI,
} from "../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import { useNavigate } from "react-router-dom";

export const DefaultNotificationAlert = () => {
  // data
  const [data, setData] = useState([]);
  const [dataWatch, setDataWatch] = useState([]);
  const [MessageCategoryData, setMessageCategoryData] = useState([]);
  const [mutation, setMutation] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  //const history = useHistory();
  let { cms_id } = useParams();

  // Custom Select handling
  const [selectedValue, setSelectedValue] = useState("");
  const handleValueChange = (value) => {
    setSelectedValue(value);
  };

  const [deviceType, setDeviceType] = useState("");
  const handleDeviceTypeChange = (value) => {
    setDeviceType(value);
  };

  const [storageLimit, setStorageLimit] = useState("");
  const handleStorageLimit = (value) => {
    setStorageLimit(value);
  };

  // Submit onboarding
  const handleSubmit = async (value, actions) => {
    if (cms_id) {
      editOnboarding(value);
    } else {
      addOnboarding(value);
    }
  };

  const editOnboarding = async (value) => {
    setLoading(true);

    const hour = value.hour;
    const minute = value.minute;
    const format = value.format;

    submitDefaultNotificationAlertAPI({
      hour: hour.toString(),
      minute: minute.toString(),
      format: format.toString(),
    })
      .then((response) => {
        // setLoading(false)
        setMutation(!mutation);
        toast.success(response.message);
        //navigate("/app/default-notification-alert");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const addOnboarding = async (value) => {
    setLoading(true);

    const hour = value.hour;
    const minute = value.minute;
    const format = value.format;
    const day = value.day;

    const message_hour = value.message_hour;
    const message_minute = value.message_minute;
    const message_format = value.message_format;
    const message_day = value.message_day;

    submitDefaultNotificationAlertAPI({
      hour: hour.toString(),
      minute: minute.toString(),
      format: format.toString(),
      day: day.toString(),
      video_type: "WATCH_ARTICLE_AND_VIDEO",
      message_hour: message_hour.toString(),
      message_minute: message_minute.toString(),
      message_format: message_format.toString(),
      message_day: message_day.toString(),
      message_type: "SEND_MESSAGE",
    })
      .then((response) => {
        // setLoading(false);
        setMutation(!mutation);
        toast.success(response.message);
        //navigate("/app/default-notification-alert");
        //navigate(-1)
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    setLoading(true);

    // Define the API calls
    const sendMessageAPI = viewDefaultNotificationAlertAPI({
      type: "SEND_MESSAGE",
    });
    const watchArticleAndVideoAPI = viewDefaultNotificationAlertAPI({
      type: "WATCH_ARTICLE_AND_VIDEO",
    });

    // Use Promise.all to execute both API calls concurrently
    Promise.all([sendMessageAPI, watchArticleAndVideoAPI])
      .then(([sendMessageResponse, watchArticleAndVideoResponse]) => {
        console.log(sendMessageResponse?.data);
        console.log(watchArticleAndVideoResponse?.data);

        // Update state with the data from the responses
        setData(sendMessageResponse?.data);
        setDataWatch(watchArticleAndVideoResponse?.data);

        // Set loading to false after both API calls are completed
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors here if needed
        console.error("Error in API calls:", error);
        setLoading(false);
      });
  }, [mutation]);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
          <Formik
            validationSchema={DefaultNotificationAlertSchema}
            initialValues={{
              message_hour: data.hour ? data.hour : "",
              message_minute: data.minute ? data.minute : "",
              message_format: data.format ? data.format : "",
              message_day: data.specific_day ? data.specific_day : "",

              hour: dataWatch.hour ? dataWatch.hour : "",
              minute: dataWatch.minute ? dataWatch.minute : "",
              format: dataWatch.format ? dataWatch.format : "",
              day: dataWatch.specific_day ? dataWatch.specific_day : "",
            }}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              handleSubmit(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                {/* {JSON.stringify(dataWatch.hour)} */}
                <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                  <div className="theme-card-header px-1">
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                    >
                      <h5 className="mb-0 position-relative fw-medium">
                        <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                          <span className="theme-icon-box-inner">
                            <AiOutlineFieldTime size={36} />
                          </span>
                        </div>
                        Default Notification Time
                      </h5>
                    </Stack>
                  </div>
                  <div className="flex-grow-1 d-flex flex-column px-1">
                    <div className="p-3 d-flex flex-column h-100">
                      <div className="d-flex flex-column flex-grow-1 theme-from">
                        <h5 className="mb-0 position-relative fw-medium">
                          Send Message
                        </h5>
                        <br></br>
                        <Row className="gx-xl-5">
                          {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
                          <Col sm={2} md={2} lg={2} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: "01", value: "1" },
                                { label: "02", value: "2" },
                                { label: "03", value: "3" },
                                { label: "04", value: "4" },
                                { label: "05", value: "5" },
                                { label: "06", value: "6" },
                                { label: "07", value: "7" },
                                { label: "08", value: "8" },
                                { label: "09", value: "9" },
                                { label: "10", value: "10" },
                                { label: "11", value: "11" },
                                { label: "12", value: "12" },
                              ]}
                              controlId="message_hour"
                              label="Select the hour"
                              value={values.message_hour}
                              name="message_hour"
                              errorsField={errors.message_hour}
                              touched={touched.message_hour}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <span className="text-danger answer-type-error fs-11">
                              {getIn(errors, `message_hour`)}
                            </span>
                          </Col>
                          <Col sm={2} md={2} lg={2} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: "00", value: "00" },
                                { label: "15", value: "15" },
                                { label: "30", value: "30" },
                                { label: "45", value: "45" },
                              ]}
                              controlId="message_minute"
                              label="Select the minute"
                              value={values.message_minute}
                              name="message_minute"
                              errorsField={errors.message_minute}
                              touched={touched.message_minute}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <span className="text-danger answer-type-error fs-11">
                              {getIn(errors, `message_minute`)}
                            </span>
                          </Col>

                          <Col sm={2} md={2} lg={2} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: "AM", value: "AM" },
                                { label: "PM", value: "PM" },
                              ]}
                              controlId="message_format"
                              label="Select AM/PM"
                              value={values.message_format}
                              name="message_format"
                              errorsField={errors.message_format}
                              touched={touched.message_format}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <span className="text-danger answer-type-error fs-11">
                              {getIn(errors, `message_format`)}
                            </span>
                          </Col>
                          <Col sm={2} md={2} lg={2} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: "01", value: "1" },
                                { label: "02", value: "2" },
                                { label: "03", value: "3" },
                                { label: "04", value: "4" },
                                { label: "05", value: "5" },
                                { label: "06", value: "6" },
                                { label: "07", value: "7" },
                              ]}
                              controlId="message_day"
                              label="Select the frequency"
                              value={values.message_day}
                              name="message_day"
                              errorsField={errors.message_day}
                              touched={touched.message_day}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <span className="text-danger answer-type-error fs-11">
                              {getIn(errors, `message_day`)}
                            </span>
                          </Col>
                        </Row>

                        <h5 className="mb-0 position-relative fw-medium">
                          Watch video and article
                        </h5>
                        <br></br>
                        <Row className="gx-xl-5">
                          <Col sm={2} md={2} lg={2} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: "01", value: "1" },
                                { label: "02", value: "2" },
                                { label: "03", value: "3" },
                                { label: "04", value: "4" },
                                { label: "05", value: "5" },
                                { label: "06", value: "6" },
                                { label: "07", value: "7" },
                                { label: "08", value: "8" },
                                { label: "09", value: "9" },
                                { label: "10", value: "10" },
                                { label: "11", value: "11" },
                                { label: "12", value: "12" },
                              ]}
                              controlId="hour"
                              label="Select the hour"
                              value={values.hour}
                              name="hour"
                              errorsField={errors.hour}
                              touched={touched.hour}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <span className="text-danger answer-type-error fs-11">
                              {getIn(errors, `hour`)}
                            </span>
                          </Col>
                          <Col sm={2} md={2} lg={2} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: "00", value: "00" },
                                { label: "15", value: "15" },
                                { label: "30", value: "30" },
                                { label: "45", value: "45" },
                              ]}
                              controlId="minute"
                              label="Select the minute"
                              value={values.minute}
                              name="minute"
                              errorsField={errors.minute}
                              touched={touched.minute}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <span className="text-danger answer-type-error fs-11">
                              {getIn(errors, `minute`)}
                            </span>
                          </Col>

                          <Col sm={2} md={2} lg={2} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: "AM", value: "AM" },
                                { label: "PM", value: "PM" },
                              ]}
                              controlId="format"
                              label="Select AM/PM"
                              value={values.format}
                              name="format"
                              errorsField={errors.format}
                              touched={touched.format}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <span className="text-danger answer-type-error fs-11">
                              {getIn(errors, `format`)}
                            </span>
                          </Col>
                          <Col sm={2} md={2} lg={2} className="mb-4 pb-1">
                            <CustomSelect
                              options={[
                                { label: "01", value: "1" },
                                { label: "02", value: "2" },
                                { label: "03", value: "3" },
                                { label: "04", value: "4" },
                                { label: "05", value: "5" },
                                { label: "06", value: "6" },
                                { label: "07", value: "7" },
                              ]}
                              controlId="day"
                              label="Select the frequency"
                              value={values.day}
                              name="day"
                              errorsField={errors.day}
                              touched={touched.day}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <span className="text-danger answer-type-error fs-11">
                              {getIn(errors, `day`)}
                            </span>
                          </Col>
                        </Row>

                        {errors &&
                          errors.weight_type &&
                          typeof errors.weight_type == "string" && (
                            <span className="text-danger">
                              {errors?.weight_type}
                            </span>
                          )}

                        <Stack direction="horizontal" gap={3} className="my-3">
                          <Button
                            variant="primary"
                            onClick={handleSubmit}
                            className="rounded-xs fs-15"
                            type="submit"
                          >
                            Update
                          </Button>
                          {/* <div className="btn btn-outline-secondary rounded-xs fs-15" onClick={handleCancel}>Cancel</div> */}
                        </Stack>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

import { Card, Col, Row } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import { HiUsers } from "react-icons/hi2";
import {FaBlogger, FaConciergeBell,} from "react-icons/fa"
import { IoChatbubbles } from "react-icons/io5";
import { ImVideoCamera } from "react-icons/im";
import { MdVideoLibrary, MdSupervisorAccount } from "react-icons/md"
import { useEffect, useState } from "react";
import { dashboardTabCount } from "../../services/dashboardService";
import Cookies from "universal-cookie";




export const Dashboard = () => {

  const cookies = new Cookies();

  cookies.set("page_heading", 'Dashboard'); // page heading

  const [dashboardCardData,setDashboardData]=useState()
  const [totalUser,setTotalUser]=useState()
  const [totalVideo,setTotalVideo]=useState()
  const [totalArtical,setTotalArtical]=useState()
  const [totalContactQuery,setTotalContactQuery]=useState()
  const [totalTemplate,setTotalTemplate]=useState()

  // Get dashboard count 
  useEffect(() => {

    dashboardTabCount({})
    .then(response => {
      setDashboardData(response)
      setTotalUser(response?.data.userResults)
      setTotalVideo(response?.data.videosResult)
      setTotalArtical(response?.data.articalResult)
      setTotalContactQuery(response?.data.contactusResult)
      setTotalTemplate(response?.data.messageTemplateResult)
    })
    
  },[]);
  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="flex-grow-1 overflow-auto pageContent">
        <Card className="h-100 p-3 w-100 pageContentInner bg-transparent border-0">
          <div className="text-center pb-3">
            <div className="row g-3 justify-content-center">
              <div className="col-xl col-md-4 col-sm-6 h-100">
                 <Card className="bg-purple text-white">
                    <Card.Body>
                      <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center"><MdSupervisorAccount className="text-white" size={38} /></div>
                      <Card.Title className="fs-30 mt-3 mb-2">
                        {totalUser}
                      </Card.Title>
                      <Card.Text className="fs-16 fw-medium">
                        Total Users
                      </Card.Text>
                    </Card.Body>
                  </Card>
              </div>
              <div className="col-xl col-md-4 col-sm-6 h-100">
                <Card className="bgred text-white">
                  <Card.Body>
                    <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center"><span className="material-symbols-outlined fs-30">edit_document</span></div>
                    <Card.Title className="fs-30 mt-3 mb-2">{totalArtical}</Card.Title>
                    <Card.Text className="fs-16 fw-medium">
                      Total Articles
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xl col-md-4 col-sm-6 h-100">
                <Card className="bggreen text-white">
                  <Card.Body>
                    <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center"><MdVideoLibrary className="text-white" size={36} /></div>
                    <Card.Title className="fs-30 mt-3 mb-2">{totalVideo}</Card.Title>
                    <Card.Text className="fs-16 fw-medium">
                      Total Videos
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xl col-md-4 col-sm-6 h-100">
                <Card className="bgorange text-white">
                  <Card.Body>
                    <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center"><IoChatbubbles className="text-white" size={36} /></div>
                    <Card.Title className="fs-30 mt-3 mb-2">{totalTemplate}</Card.Title>
                    <Card.Text className="fs-16 fw-medium">
                      Message Templates
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xl col-md-4 col-sm-6 h-100">
                <Card className="bgdarkgreen text-white">
                  <Card.Body>
                    <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center"><FaConciergeBell className="text-white" size={36} /></div>
                    <Card.Title className="fs-30 mt-3 mb-2">{totalContactQuery}</Card.Title>
                    <Card.Text className="fs-16 fw-medium">
                      Total Inquiries
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              
            </div>
          </div>         
        </Card>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack, Spinner } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { AddCategoryTypeSchema } from '../../../validations/ValidationSchema';
import { submitCategoryTypeAPI, viewCategoryTypeAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios"
import storage from "../../../helpers/storage"
import { IMAGE_UPLOAD_API } from '../../../constants/paths';
import { FaUserAlt, FaImage } from "react-icons/fa";


export const Edit = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let { cms_id } = useParams();
    const [fileKey, setFileKey] = useState()
    const [fileFullUrl, setFileFullUrl] = useState()
    const [imageLoading,setImageLoading] = useState(false);
    // icon
    const [iconLoading,setIconLoading] = useState(false);
    const [fileIconKey, setFileIconKey] = useState()
    const [fileIconFullUrl, setFileIconFullUrl] = useState();

    const location = useLocation();
    const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
    const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
    const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;
     

    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };
    
   

    
   

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
            if(cms_id){
                editFormData(value)
            }else{
                addFormData(value)
            }
    }

    const editFormData = async(value) =>{

        setLoading(true)
        
        const category_type = value.category_type

        submitCategoryTypeAPI({
            row_id : cms_id,
            category_type : category_type.toString(),  
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate(`/app/message-category-type`, { state: {pageNumber, searchedValue, rowPerPage}});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const addFormData = async(value) =>{

        const category_type = value.category_type
        
        submitCategoryTypeAPI({
            category_type : category_type.toString(),  
          })
          .then(response => {
            
            setLoading(false)
            toast.success(response.message)
            navigate(`/app/message-category-type`, { state: {pageNumber, searchedValue, rowPerPage}});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const handleCancel = () =>{
        navigate(`/app/message-category-type`, { state: {pageNumber, searchedValue, rowPerPage}});
    }

    // Get onboarding 
    useEffect(() => {
        if(cms_id){
            viewCategoryTypeAPI({row_id : cms_id})
                .then(response => {
                setData(response?.data)
                setLoading(false)
            
            })
        }else{
            setLoading(false)
        }
    },[]);


    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={AddCategoryTypeSchema}
          initialValues={
            {   
               category_type :data.category_type ?data.category_type : "" , 
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                    { cms_id ? 'Edit Message Category Type' : 'Add Message Category Type'}
                                  
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                  
                                  

                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(values,null,2)}</pre>
                                  <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          <InputText
                                              controlId="category_type"
                                              label="Category Type"
                                              value={values.category_type}
                                              name= "category_type"
                                              maxLength= "50"
                                              errorsField={errors.category_type}
                                              touched={touched.category_type}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                          />
                                      </Col>
                                    </Row>
                                    
                                    <Stack direction="horizontal" gap={3} className="my-3">
                                        <Button 
                                            variant="primary"
                                            onClick={handleSubmit}
                                            className="rounded-xs fs-15"
                                            type="submit"
                                        >Save</Button>
                                        <div className="btn btn-outline-secondary rounded-xs fs-15" onClick={handleCancel}>Cancel</div>
                                        
                                    </Stack>
                              </div>
                          </div>
                      </div>
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}

import { DataGrid, GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import React, { useState } from 'react'
import './datagrid.scss';
import { createTrue } from 'typescript';

export const MatDataGridWithPagination = ({ controlId,
                              columns,
                              data,
                              sortModel,
                              columnGroupingModel,
                              handlePageSizeChange,
                              currentPage,
                              pageSize,
                              totalReults,
                              totalPages,
                              onPaginationModelChange,
                              handleSortModelChange
                             }) => {
    // const [pageSize, setPageSize] = useState(10);

    const NoRowsFoundMessage = () => {
        return (
          <div style={{ textAlign: 'center', padding: '16px' }}>
            No records found.
          </div>
        );
      };
      

    return (
        <div className="dataGridMain flex-fill w-100">
            {/* <pre>{JSON.stringify({ 
                            
                            
                              currentPage,
                              pageSize,
                              totalReults,
                              totalPages,

                            }, null, 2 )}</pre> */}
            <DataGrid
               
                rows={data}
                columns={columns}
                initialState={{
                    sorting: {
                        sortModel: sortModel,
                    },
                    pagination: {
                        paginationModel: {
                          page: currentPage - 1,
                          pageSize: pageSize,
                        },
                      },
                }}
                onPageSizeChange={handlePageSizeChange}
                // pageSizeOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                pageSizeOptions={[10, 25, 50, 100 ]}
                disableSelectionOnClick
                getRowId={(row) => row[controlId]}
                onPageChange={onPaginationModelChange}
                onPaginationModelChange={onPaginationModelChange}
                onSortModelChange={handleSortModelChange} 
                disableColumnMenu={true}
                components={{
                    NoRowsOverlay: NoRowsFoundMessage,
                }}
                localeText={{
                    noRowsLabel: '', // Set an empty string to hide the default message
                }}
                paginationMode="server"
                sortingMode='server'
                pagination={true}
                pageSize={pageSize}
                rowCount={totalReults} 
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={columnGroupingModel}
                sortModel={sortModel}
            />
        </div>
    )
}

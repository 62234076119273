import { GET_PAYMENT_HISTORY_API } from "../constants/paths";
import { axios } from "./axios";

export const getPaymentHistoryAPI = (params) => {
  return axios.get(`${GET_PAYMENT_HISTORY_API}`, { params });
};

export const getUserPaymentHistoryAPI = (params, userId) => {
  return axios.get(`/users/${userId}${GET_PAYMENT_HISTORY_API}`, { params });
};

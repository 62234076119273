import { axios } from "./axios"
import {
  FORGOT_API,
  GET_DASHBOARD_COUNT_API,
} from "../constants/paths"

/**
 * Dashboard Tab Counts API Call
 * @param  data
 * @returns {Promise<any>}
 */
export const dashboardTabCount = data => {
  return axios.get(GET_DASHBOARD_COUNT_API, data)
}



/**
 * Forgot Password API Call
 * @param  data
 * @returns {Promise<any>}
 */
export const forgotPassword = data => {
  return axios.post(FORGOT_API, data)
}


import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack, Spinner } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { AddMessageTemplateSchema } from '../../../validations/ValidationSchema';
import { viewMessageAPI, addMessageAPI, viewMessageCategoryListingAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import axios from "axios"
import storage from "../../../helpers/storage"
import { IMAGE_UPLOAD_API } from '../../../constants/paths';


export const Add = () => {

    // data
    const [data, setData] = useState([]);
    const [MessageCategoryData, setMessageCategoryData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    
    const params = new URLSearchParams(location.search);
    const mainCategoryId = params.get('mainCategoryId');
    const mainCategoryName = params.get('mainCategoryName');
    let { cms_id } = useParams();


    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };
    

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
            if(cms_id){
                editFormData(value)
            }else{
                addFormData(value)
            }
    }

    const editFormData = async(value) =>{

        setLoading(true)
        
        const message_category_id = value.message_category_id
        const message = value.message
        const cms_id = value.cms_id

        addMessageAPI({
            row_id : cms_id,
            message_category_id : message_category_id.toString(),
            message : message.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            //navigate("/app/message-category");
            navigate(-1);
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const addFormData = async(value) =>{

        const message_category_id = value.message_category_id
        const message = value.message
        
        addMessageAPI({
            message_category_id : message_category_id.toString(),
            message : message.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            //navigate("/app/message-category");
            navigate(-1);
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    // Get onboarding 
    useEffect(() => {

        if(cms_id){
            viewMessageAPI({row_id : cms_id})
                .then(response => {
                setData(response?.data)
                setLoading(false)
            
            })
        }else{
            setLoading(false)
        }

        viewMessageCategoryListingAPI({search : '', message_category_type_id : mainCategoryId})
                .then(response => {
                    setMessageCategoryData(response?.data)
                    setLoading(false)
                })   

    },[]);

    const handleCancel = () =>{
        navigate(-1)
    }

    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={AddMessageTemplateSchema}
          initialValues={
            {   
                message_category_id :data.message_category_id ?data.message_category_id : "" , 
                message :data.message ?data.message : ""  ,
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                    { cms_id ?  mainCategoryName +' > Edit Message' : mainCategoryName +' > Add Message'} 
                                  
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                  
                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">

                                            <CustomSelect
                                                options={ MessageCategoryData.map((categorysObj) => ({
                                                    label: categorysObj.category,
                                                    value: categorysObj.id,
                                                }))}
                                                controlId="message_category_id"
                                                label="Message  Sub-Category"
                                                value={values.message_category_id}
                                                name= "message_category_id"
                                                errorsField={errors.message_category_id}
                                                touched={touched.message_category_id}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                onChange={handleChange}

                                            />
                                            <span className='text-danger answer-type-error fs-11'>{getIn(errors,`message_category_id`)}</span>
                                      </Col>
                                      
                                  </Row>

                                  
                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          <InputText
                                              controlId="message"
                                              label="Message"
                                              value={values.message}
                                              name= "message"
                                              maxLength= "300"
                                              errorsField={errors.message}
                                              touched={touched.message}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                              multiline={true}
                                          />
                                      </Col>
                                    </Row>
                                    

                                  <Stack direction="horizontal" gap={3} className="my-3">
                                      <Button 
                                          variant="primary"
                                          onClick={handleSubmit}
                                          className="rounded-xs fs-15"
                                          type="submit"
                                      >Save</Button>
                                      {/* <Link to="/app/message-category"  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                                      <div className="btn btn-outline-secondary rounded-xs fs-15" onClick={handleCancel}>Cancel</div>
                                  </Stack>
                              </div>
                          </div>
                      </div>
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}
export const USER_NAME_REQUIRED = "Username is required.";
export const EMAIL_REQUIRED = "Email address is required.";
export const CURRENT_PASSWORD_REQUIRED = "Current password is required.";
export const NEW_PASSWORD_REQUIRED = "New password is required.";
export const PASSWORD_REQUIRED = "Password is required.";
export const CONFIRM_PASSWORD_REQUIRED = "Confirm password is required.";
export const CONFIRM_PASSWORD_MATCH = "The confirm password must match the new password";
export const MINIMUM_8_CHAR = "Must be a minimum of 8 characters.";
export const MAXIMUM_30_CHAR = "Can not be a maximum than 14 characters.";
export const PASSWORD_REGEX = "Must includes at least 1 capital letter, 1 lowercase letter, 1 special character,1 number and no blank space.";
export const PASSWORD_MUST_MATCH = "Confirm password must match with password.";
export const VALID_EMAIL = "Email must be a valid email.";
export const SITE_CODE_REQUIRED = "Site code is required.";
export const SITE_NAME_REQUIRED = "Site name is required.";
export const CONTACT_FIRST_NAME_REQUIRED = "Person first name is required.";
export const CONTACT_LAST_NAME_REQUIRED = "Person last name is required.";
export const PHONE_NO_REQUIRED = "Phone no is required.";
export const CHAR_MAX_10 = "Can exeed upto maximum 10 characters.";
export const CHAR_MAX_20 = "Can exeed upto maximum 20 characters.";
export const CHAR_MAX_50 = "Can exeed upto maximum 50 characters.";
export const CHAR_MAX_300 = "Can exeed upto maximum 300 characters.";
export const PHONE_NUM_LENGTH = "Phone no should have 10 characters.";
export const EMPLOYEE_CODE_REQUIRED = "Employee code is required.";
export const SITES_CODE_REQUIRED = "Sites selection is required.";
export const REQUIRED = "Field is Required.";
export const NUMERIC_10 = "Should be less than 10";
export const NUMERIC_PERCENTAGE = "Should be valid percentage";
export const CAPTCH_INVALID = "Please Enter Captcha";
export const ONBOARDING_QUESTION_REQUIRED = "Question is required.";
export const ANSWER_TYPE_REQUIRED = "Answer type is required.";
export const WEIGHTAGE_REQUIRED = "Weightage is required.";
export const TITLE_REQUIRED = "Page title is required.";
export const CONTENT_REQUIRED = "Description is required.";
export const PAGETYPE_REQUIRED = "Page type is required.";
export const REPLY_REQUIRED = "Reply is required.";
export const CATEGORY_REQUIRED = "Sub-Category is required.";
export const CATEGORY_TYPE_REQUIRED = "Category type is required.";
export const LEVEL_REQUIRED = "Level is required.";
export const URL_REQUIRED = "Url is required.";
export const ICON_REQUIRED = "Icon is required.";
export const IMAGE_REQUIRED = "Image is required.";
export const QOUTES_REQUIRED = "Quotes name is required.";
export const VIDEO_REQUIRED = "Video is required.";
export const VIDEO_THUM_REQUIRED = "Video thumb is required.";
export const MESSAGE_REQUIRED = "Message is required.";
export const TEXT_COLOR_REQUIRED = "Text color is required";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { CustomSelect } from "../../../../components/common/CustomSelect";
import { MatDataGrid } from "../../../../components/common/dataGrid/DataGrid";
import { ActionCell } from "../../../../components/common/dataGrid/ActionCell";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import storage from "../../../../helpers/storage";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getUserTransactionListAPI } from "../../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getUserPaymentHistoryAPI } from "../../../../services/paymentHistory.service";

const customTheme = createTheme({
  typography: {
    fontFamily: '"Source Sans Pro", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#EB0024",
    },
    text: {
      primary: "#000000",
      secondary: "#999999",
    },
  },
});

const columns = [
  {
    field: "id",
    headerName: "ID",
    editable: false,
    flex: 1,
    minWidth: 80,
    maxWidth: 80,
  },
  {
    field: "start_date",
    headerName: "Purchased Date",
    editable: false,
    flex: 0.6,
    minWidth: 160,
  },
  {
    field: "plan_title",
    headerName: "Plan Name",
    editable: false,
    flex: 0.6,
    minWidth: 160,
  },
  {
    field: "amount",
    headerName: "Plan Cost",
    editable: false,
    flex: 0.5,
    minWidth: 150,
  },
  {
    field: "end_date",
    headerName: "Purchased Expiry Date",
    editable: false,
    flex: 0.3,
    minWidth: 160,
  },
  // {
  //   field: "action",
  //   headerName: "Action",
  //   editable: false,
  //   flex: 0.3,
  //   minWidth: 150,
  //   maxWidth: 150,
  //   disableColumnMenu: true,
  //   sortable: false,
  //   renderCell: (props) => {
  //     return (
  //       <ActionCell
  //         controlId="tenant-management/"
  //         isViewEnable={true}
  //         isLogInEnable={false}
  //         isDeleteEnable={false}
  //         redirectID={props.row.site_id}
  //       />
  //     );
  //   },
  // },
];

export const ManageTenantManagement = () => {
  const [selectedValue, setSelectedValue] = useState("");

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [userStatus, setUserStatus] = useState();
  const [userData, setUserData] = useState([]);
  const [mutation, setMutation] = useState(true);
  const { id } = useParams();
  const [userId, setUserId] = useState(id);

  // Searching and listing user list
  const handleValueChange = (value) => {
    const date = moment(new Date(value)).format("YYYY-MM-DD");

    setSelectedValue(value);
    fetchUsersTransactions({
      page: page,
      limit: rowPerPage,
      ...(value ? { date } : {}),
    });
  };

  // Get user list details
  const fetchUsersTransactions = (params) => {
    getUserPaymentHistoryAPI({ ...params }, userId).then((response) => {
      setUserData(response.userResult);
      setPage(response.page);
      setCurrentPage(response.page);
      setRowPerPage(response.limit);
      setLoading(false);
    });
  };

  // On page load fetch user list
  useEffect(() => {
    fetchUsersTransactions({
      page: page,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    });
  }, [mutation]);

  return (
    <>
      <div className="theme-data-table-filter px-1">
        <ThemeProvider theme={customTheme}>
          <Row className="justify-content-end">
            <Col sm={5} lg={2} className="mb-3 pb-1">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    className="custom-datepicker"
                    value={selectedValue}
                    onChange={(e) => {
                      handleValueChange(e);
                    }}
                    // format='DD-MM-YYYY'
                    format="MM-DD-YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Col>
          </Row>
        </ThemeProvider>
      </div>
      <MatDataGrid controlId={"id"} columns={columns} data={userData} />
      <Loader isLoading={loading} />
    </>
  );
};

import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';
import { ViewUser } from './View/View';
import { EditUser } from './Edit/Edit';
import {AvoidanceList} from './Avoidance-List/List'

export const Assessment = () => {
    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
            <Route path="list" element={<AllList />} />
            <Route path="view/:id" element={<ViewUser />} />
            <Route path="edit/:id" element={<EditUser />} />
            {/* Avoidance List */}
            <Route path="avoidance" element={<AvoidanceList />} />
        </Routes>
    )
};
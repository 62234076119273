import { Formik } from "formik"
import { Button, Stack } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { ResetPasswordSchema } from "../../../validations/Auth"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import { AuthHeader } from "../authHeader/AuthHeader"
import { Link } from "react-router-dom"
import { useRef, useState } from "react"
import { toast } from "react-toastify"
import { resetPassword } from "../../../services/authService"
import InputText from "../../../components/common/InputText"
import { useNavigate } from "react-router-dom"
import { nameBasedRoutes, publicRoutes } from "../../../router/public"
import TextField from "@mui/material/TextField"

import {useSearchParams} from "react-router-dom";

export const ResetPassword = () => {

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get("token")
  const [showEye, setShowEye] = useState(false)
  const [showConfirmPasEye, setShowConfirmPasEye] = useState(false)

  const initialValues = {
    password: "",
    confirmPassword: "",
  }

  /**
   * Submit Form
   *
   * @async
   * @param {string} password
   * @param {string} confirmPassword
   * @param {*} actions
   * @returns {*}
   */
  const handleSubmit = async (values) => {
   
    setLoading(true)
    const formData = {
      password  : values.password
    }
    resetPassword(formData,token)
      .then(response => {
        setLoading(false)
        toast.success(response.message)
        navigate(nameBasedRoutes.login.path)
      })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.message)
      })
  }

  // Show/hide password input field
  const showToggle = event => {
    setShowEye(event)
  }

  // Show/hide confirm password input field
  const onConfirmPasswordShowToggle = event => {
    setShowConfirmPasEye(event)
  }
  

  return (
    <Formik
      validationSchema={ResetPasswordSchema}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false)
        handleSubmit(values)
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors
      }) => (
        <div className="d-flex flex-column p-3 min-vh-100 w-100 theme-body-bg">
          <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
            <div className="bg-white d-inline-block mt-3 p-4 rounded-3 text-start w-100">
              <Form className="p-2" noValidate onSubmit={handleSubmit}>
                <div className="fw-normal text-center mb-3">
                  <AuthHeader />
                </div>
                <h6 className="fw-normal text-center mb-3 h5 fw-bold heading">
                  Reset Password
                </h6>
                <p className="fw-normal text-center mb-3 fs-14 mw-290 m-auto lh-sm subheading">
                  Now you can create a new password.
                </p>
                 <div className={`position-relative ${showEye ? "form-right-icon" : ""}`} >
                    <InputText
                      controlId="password"
                      label='Password *'
                      type={showEye ? "text" :"password" }
                      touched={touched.password}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.password}
                      value={values.password}
                      isPasswordHintVisible={true}
                      className="w-100 fs-16 mb-3 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color"
                      }}
                    />
                    <div onClick={() => setShowEye(!showEye)}>
                      {showEye ? (
                            <FaEye size="22" 
                            className="eyePosition"
                            onClick={() => showToggle(true)} />
                          ) : (
                            <FaEyeSlash
                              size="22"
                              className="eyePosition"
                              onClick={() => showToggle(true)}
                            />
                          )}
                    </div>
                  </div>
                  <div className={`position-relative ${showConfirmPasEye ? "form-right-icon" : ""}`} >
                    <InputText
                      controlId="confirmPassword"
                      label='Confirm Password *'
                      type={showConfirmPasEye ? "text" :"password" }
                      touched={touched.confirmPassword}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errorsField={errors.confirmPassword}
                      value={values.confirmPassword}
                      isPasswordHintVisible={true}
                      className="w-100 fs-16 mb-3 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color"
                      }}
                    />
                    <div onClick={() => setShowConfirmPasEye(!showConfirmPasEye)}>
                      {showConfirmPasEye ? (
                            <FaEye size="22" 
                            className="eyePosition"
                            onClick={() => onConfirmPasswordShowToggle(true)} />
                          ) : (
                            <FaEyeSlash
                              size="22"
                              className="eyePosition"
                              onClick={() => onConfirmPasswordShowToggle(true)}
                            />
                          )}
                    </div>
                  </div>
                <Stack gap={4} className="justify-content-center">
                  <Button
                    className="fw-semibold fs-18 text-white min-h-48 mt-2 w-100"
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Stack>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
    
  )
}

import React from 'react'
import { Button, OverlayTrigger, Stack, Tooltip, Image } from 'react-bootstrap';
import { MdDelete, MdRemoveRedEye, MdModeEdit,MdReply } from 'react-icons/md';
import { useNavigate, Link } from 'react-router-dom';
import AssessmentList from '../../../assets/images/assessment-list.svg';

export const MessageCategoryActionCell = ({ mainCategoryId, mainCategoryName, controlId, subSection, isViewEnable, isEditEnable, isDeleteEnable, deleteFunctionName, isLogInEnable, redirectID, disabled, rowData, isReplyEnable,isReplyDone, pageNumber='1', rowPerPage='10', searchedValue='', backUrl=''  }) => {
    const navigate = useNavigate();
    const GoToList = () => {
       
        navigate(`/${controlId}`, {
            state: {rowData, pageNumber, searchedValue, backUrl, rowPerPage}
        });
    }
    const GoToSubSectionView = () => {
        navigate(`/${subSection}?category=${rowData.category ? rowData.category : rowData.category_type}&id=${rowData.id}&mainCategoryId=${mainCategoryId}&mainCategoryName=${mainCategoryName}`, {
            // state: {rowData, pageNumber, searchedValue, backUrl}
            // state: {rowData, pageNumber, searchedValue, backUrl}
            state: {rowData, pageNumber, backUrl, rowPerPage}
        });
    }
    const GoToView = () => {
        navigate(`/${controlId}/view/${redirectID}`, {
            state: {rowData, pageNumber, searchedValue, backUrl, rowPerPage}
        });
    }
    const GoToEdit = () => {
        navigate(`/${controlId}/edit/${redirectID}`, {
            state: {rowData, pageNumber, searchedValue, backUrl, rowPerPage}
        });
    }
    return (
        <Stack direction="horizontal" gap={3} className="justify-content-center">
            {isEditEnable ?
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="edit-tooltip">List</Tooltip>}
                >
                    < Button onClick={GoToSubSectionView} variant="link" disabled={disabled} className="p-0 text-light">
                        <Image className="img-fluid" src={AssessmentList} alt="assessment" width={19} height={15} />
                    </Button>
                </OverlayTrigger>
                : null}
            {isViewEnable ?
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="view-tooltip">View</Tooltip>}
                >
                    <Button onClick={GoToView} variant="link" className="p-0 text-light">
                        <MdRemoveRedEye size={25} />
                    </Button>
                </OverlayTrigger>
                : null}
            
                {isLogInEnable ?
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
                >
                    < Button onClick={GoToEdit} variant="link" disabled={disabled} className="p-0 text-light">
                        <MdModeEdit size={22} />
                    </Button>
                </OverlayTrigger>
                : null}
            {isDeleteEnable ?
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
                >
                    <Button  variant="link" onClick={ () => deleteFunctionName(redirectID)} className="p-0 text-light">
                        <MdDelete size={21} />
                    </Button>
                </OverlayTrigger>
                : null}

            {isReplyEnable ?
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="view-tooltip">Reply</Tooltip>}
                >
                    <Button onClick={GoToEdit} variant="link" className="p-0 text-light">
                        <MdReply size={25} className={`${isReplyDone=='0'?'replyActive':''}`}/>
                    </Button>
                </OverlayTrigger>
                : null}
        </Stack >
    )
}

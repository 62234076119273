import { Button, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { FaInfoCircle } from "react-icons/fa"
import TextField from "@mui/material/TextField"

/**
 * Input Type Text Reusable Component
 * @date 4/13/2023 - 1:57:35 PM
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; rightIcon: any; size: any; name: any; isPasswordHintVisible: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, rightIcon, size, name, isPasswordHintVisible
}
 * @returns
 */

const InputText = ({
  controlId,
  type, 
  label,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  value,
  rightIcon,
  size,
  name,
  isPasswordHintVisible,
  className,
  maxLength,
  max,
  multiline,
  ...rest
  
}, ) => {
  const tooltipMessage = [
    "Must contain 1 Capital letter.",
    "Must contain 1 lower case letter.",
    "Must contain at least 2 numbers.",
    "Must contain at least 1 of the following special characters !$%^()_+*#",
    "Must be a minimum of 15 characters.",
    "No spaces.",
    "Must be different than previous passwords."
  ]
  const renderTooltip = props => (
    <Tooltip {...props}>
      <ListGroup as="ul" className="text-start tooltipDottedList fs-12 mb-1">
        {tooltipMessage.map((msg, index) => {
          return (
            <ListGroup.Item
              key={index}
              as="li"
              className="mt-1 bg-transparent border-0 list-group-item p-0 position-relative ps-3 text-white"
            >
              {msg}
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </Tooltip>
  )
  return (
    <>
      
      <div
        className={`position-relative ${rightIcon ? "form-right-icon" : ""}`}
      >
        
        <TextField
          onBlur={handleBlur}
          placeholder={placeholder}
          defaultValue={value}
          onChange={handleChange}
          type={type}
          error={errorsField && touched}
          label={label}
          variant="standard"  
          helperText={errorsField && touched ? errorsField : ""}
          name={controlId}
          // readOnly={readOnly}
          disabled={disabled}
          inputProps={{ maxLength: maxLength ? maxLength : "30",  max: max ?  max : "10", min : "1", rest }}
          className={className}
          multiline={ type=='password' ? false : true }  // Add this prop to enable multiline text input
         
        />
        {/* {JSON.stringify(className,null,2)} */}
        {rightIcon ? (
          <span
            className={`align-items-center d-flex end-0 justify-content-center pe-none position-absolute px-3 text-light top-0 ${
              size ? "h-35" : "h-40"
            }`}
          >
            {rightIcon}
          </span>
        ) : null}
        <Form.Control.Feedback type="invalid">
          {errorsField}
        </Form.Control.Feedback>
      </div>
    </>
  )
}

export default InputText

import { axios } from "./axios";
import {
  GET_USER_LIST_API,
  GET_TRANSACTIONS_LIST_API,
  GET_EVENT_LIST_API,
  CHANGE_USER_STATUS_API,
  DELETE_USER_API,
  GET_USER_DETAILS_API,
  GET_ONBOARDING_LIST_API,
  ADD_ONBOARDING_API,
  VIEW_ONBOARDING_API,
  SET_NEW_PASSWORD_API,
  GET_CMS_LIST_API,
  ADD_CMS_API,
  VIEW_CMS_API,
  GET_PAYMENT_LIST_API,
  GET_CONTACTUS_LIST_API,
  REPLY_CONTACTUS_API,
  VIEW_CONTACTUS_API,
  GET_QOUTES_LIST_API,
  SUBMIT_QOUTES_API,
  VIEW_QOUTES_API,
  DELETE_QOUTES_API,
  STATUS_ARTICAL_API,
  DELETE_ARTICAL_API,
  VIEW_ARTICAL_API,
  SUBMIT_ARTICAL_API,
  GET_ARTICAL_LIST_API,
  VIDEO_UPLOAD_API,
  GET_VIDEO_LIST_API,
  SUBMIT_VIDEO_API,
  VIEW_VIDEO_API,
  STATUS_VIDEO_API,
  DELETE_VIDEO_API,
  GET_MESSAGE_CATEGORY_LIST_API,
  SUBMIT_MESSAGE_CATEGORY_API,
  VIEW_MESSAGE_CATEGORY_API,
  GET_MESSAGE_CATEGORY_LISTING_API,
  GET_MESSAGE_LIST_API,
  SUBMIT_MESSAGE_API,
  VIEW_MESSAGE_API,
  GET_ASSESSMENT_CATEGORY_LIST_API,
  LIST_ASSESSMENT_API,
  SUBMIT_ASSESSMENT_API,
  VIEW_ASSESSMENT_API,
  SUBMIT_NOTIFICATION_SETTINGS_API,
  VIEW_NOTIFICATION_SETTINGS_API,
  VIEW_ASSESSMENT_COMPLETED_QUESTIONS_API,
  GET_ASSESSMENT_COMPLETED_SCORE_API,
  VIEW_LEVEL_API,
  LIST_LEVEL_API,
  SUBMIT_LEVEL_API,
  LISTING_LEVEL_API,
  LIST_CATEGORY_TYPE_API,
  SUBMIT_CATEGORY_TYPE_API,
  LISTING_CATEGORY_TYPE_API,
  VIEW_CATEGORY_TYPE_API,
  SUBMIT_DEFAULT_ALERT_TIME_API,
  VIEW_DEFAULT_ALERT_TIME_API,
  GET_EMAIL_CONTENT_API,
  VIEW_EMAIL_CONTENT_API,
  SUBMIT_EMAIL_CONTENT_API,
} from "../constants/paths";

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getEmailContentListAPI = (params) => {
  return axios.get(`${GET_EMAIL_CONTENT_API}`, { params });
};

/******
 * Add cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addEmailContentAPI = (data) => {
  return axios.post(SUBMIT_EMAIL_CONTENT_API, data);
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewEmailContentAPI = (data) => {
  return axios.post(VIEW_EMAIL_CONTENT_API, data);
};

/******
 * Add Default Notification Alert Time
 * @param  data
 * @returns {Promise<any>}
 */
export const submitDefaultNotificationAlertAPI = (data) => {
  return axios.post(SUBMIT_DEFAULT_ALERT_TIME_API, data);
};

/******
 * Get Default Notification Alert Time
 * @param  data
 * @returns {Promise<any>}
 */
export const viewDefaultNotificationAlertAPI = (data) => {
  return axios.post(VIEW_DEFAULT_ALERT_TIME_API, data);
};

/******
 * Get category type list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewCategoryTypeAPI = (data) => {
  return axios.post(VIEW_CATEGORY_TYPE_API, data);
};

/******
 * Get category type listing api call
 * @param  data
 * @returns {Promise<any>}
 */
export const categoryTypeListingAPI = (data) => {
  return axios.post(LISTING_CATEGORY_TYPE_API, data);
};

/******
 * Add category type
 * @param  data
 * @returns {Promise<any>}
 */
export const submitCategoryTypeAPI = (data) => {
  return axios.post(SUBMIT_CATEGORY_TYPE_API, data);
};

/******
 * Get category type list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getCategoryTypeListAPI = (params) => {
  return axios.get(`${LIST_CATEGORY_TYPE_API}`, { params });
};

/******
 * Get level listing api call
 * @param  data
 * @returns {Promise<any>}
 */
export const levelsListingAPI = (data) => {
  return axios.post(LISTING_LEVEL_API, data);
};

/******
 * Add level
 * @param  data
 * @returns {Promise<any>}
 */
export const submitLevelAPI = (data) => {
  return axios.post(SUBMIT_LEVEL_API, data);
};

/******
 * Get level list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewLevelAPI = (data) => {
  return axios.post(VIEW_LEVEL_API, data);
};

/******
 * Get level list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getLevelListAPI = (params) => {
  return axios.get(`${LIST_LEVEL_API}`, { params });
};

/**
 * Get user transaction list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getAssessmentCompletedScoreListAPI = (params) => {
  return axios.get(`${GET_ASSESSMENT_COMPLETED_SCORE_API}`, { params });
};

/******
 * Notification Settings
 * @param  data
 * @returns {Promise<any>}
 */
export const viewCompletedAssessmentQuestionsAPI = (data) => {
  return axios.post(VIEW_ASSESSMENT_COMPLETED_QUESTIONS_API, data);
};

/******
 * Notification Settings
 * @param  data
 * @returns {Promise<any>}
 */
export const viewSettingsAPI = (data) => {
  return axios.post(VIEW_NOTIFICATION_SETTINGS_API, data);
};

/******
 * Notification Settings
 * @param  data
 * @returns {Promise<any>}
 */
export const submitSettingsAPI = (data) => {
  return axios.post(SUBMIT_NOTIFICATION_SETTINGS_API, data);
};

/******
 * Get Assessmentlist api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getAssessmentListAPI = (params) => {
  return axios.get(`${LIST_ASSESSMENT_API}`, { params });
};

/******
 * Add Assessment list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const submitAssessmentAPI = (data) => {
  return axios.post(SUBMIT_ASSESSMENT_API, data);
};

/******
 * Get Assessment  list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewAssessmentAPI = (data) => {
  return axios.post(VIEW_ASSESSMENT_API, data);
};

/******
 * Get Categroy list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getAssessmentCategoryListAPI = (params) => {
  return axios.get(`${GET_ASSESSMENT_CATEGORY_LIST_API}`, { params });
};

/******
 * Get Message category list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getMessageListAPI = (params) => {
  return axios.get(`${GET_MESSAGE_LIST_API}`, { params });
};

/******
 * Add Message category list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addMessageAPI = (data) => {
  return axios.post(SUBMIT_MESSAGE_API, data);
};

/******
 * Get Message category list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewMessageAPI = (data) => {
  return axios.post(VIEW_MESSAGE_API, data);
};

/******

/******
 * Get Message category list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getMessageCategoryListAPI = (params) => {
  return axios.get(`${GET_MESSAGE_CATEGORY_LIST_API}`, { params });
};

/******
 * Add Message category list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addMessageCategoryAPI = (data) => {
  return axios.post(SUBMIT_MESSAGE_CATEGORY_API, data);
};

/******
 * Get Message category list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewMessageCategoryAPI = (data) => {
  return axios.post(VIEW_MESSAGE_CATEGORY_API, data);
};

/******
 * Get Message category list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewMessageCategoryListingAPI = (data) => {
  return axios.post(GET_MESSAGE_CATEGORY_LISTING_API, data);
};

/******
 * Get Video list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getVideoListAPI = (params) => {
  return axios.get(`${GET_VIDEO_LIST_API}`, { params });
};

/******
 * Add Video list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addVideoAPI = (data) => {
  return axios.post(SUBMIT_VIDEO_API, data);
};

/******
 * Get Video list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewVideoAPI = (data) => {
  return axios.post(VIEW_VIDEO_API, data);
};

/**
 * Delete
 * @param  data
 * @returns {Promise<any>}
 */
export const deleteVideoStatus = (data) => {
  return axios.post(DELETE_VIDEO_API, data);
};

/**
 * Change user status
 * @param  data
 * @returns {Promise<any>}
 */
export const changeVideoStatus = (data) => {
  return axios.post(STATUS_VIDEO_API, data);
};

/**
 * Delete
 * @param  data
 * @returns {Promise<any>}
 */
export const deleteQoutesStatus = (data) => {
  return axios.post(DELETE_QOUTES_API, data);
};

/******
 * Add qoutes
 * @param  data
 * @returns {Promise<any>}
 */
export const submitQoutesAPI = (data) => {
  return axios.post(SUBMIT_QOUTES_API, data);
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewQoutesAPI = (data) => {
  return axios.post(VIEW_QOUTES_API, data);
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getQoutesListAPI = (params) => {
  return axios.get(`${GET_QOUTES_LIST_API}`, { params });
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getArticalListAPI = (params) => {
  return axios.get(`${GET_ARTICAL_LIST_API}`, { params });
};

/******
 * Add cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addArticalAPI = (data) => {
  return axios.post(SUBMIT_ARTICAL_API, data);
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewArticalAPI = (data) => {
  return axios.post(VIEW_ARTICAL_API, data);
};

/**
 * Delete
 * @param  data
 * @returns {Promise<any>}
 */
export const deleteArticalStatus = (data) => {
  return axios.post(DELETE_ARTICAL_API, data);
};

/**
 * Change user status
 * @param  data
 * @returns {Promise<any>}
 */
export const changeArticalStatus = (data) => {
  return axios.post(STATUS_ARTICAL_API, data);
};

/******
 * Get contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getContactusListAPI = (params) => {
  return axios.get(`${GET_CONTACTUS_LIST_API}`, { params });
};

/******
 * Add contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const updateContactusAPI = (data) => {
  return axios.post(REPLY_CONTACTUS_API, data);
};

/******
 * Get contactus list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewContactusAPI = (data) => {
  return axios.post(VIEW_CONTACTUS_API, data);
};

/******
 * Update profile/ password
 * @param  data
 * @returns {Promise<any>}
 */
export const updateProfile = (data) => {
  return axios.post(SET_NEW_PASSWORD_API, data);
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getPaymentListAPI = (params) => {
  return axios.get(`${GET_PAYMENT_LIST_API}`, { params });
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getCmsListAPI = (params) => {
  return axios.get(`${GET_CMS_LIST_API}`, { params });
};

/******
 * Add cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addCmsAPI = (data) => {
  return axios.post(ADD_CMS_API, data);
};

/******
 * Get cms list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewCmsAPI = (data) => {
  return axios.post(VIEW_CMS_API, data);
};

/******
 * Get onboarding list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getOnboardingListAPI = (params) => {
  return axios.get(`${GET_ONBOARDING_LIST_API}`, { params });
};

/******
 * Add onboarding list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const addOnboardingAPI = (data) => {
  return axios.post(ADD_ONBOARDING_API, data);
};

/******
 * Get onboarding list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const viewOnboardingAPI = (data) => {
  return axios.post(VIEW_ONBOARDING_API, data);
};

/******
 * Get user list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getUserListAPI = (params) => {
  return axios.get(`${GET_USER_LIST_API}`, { params });
};

/**
 * Get user transaction list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getUserTransactionListAPI = (params) => {
  return axios.get(`${GET_TRANSACTIONS_LIST_API}`, { params });
};

/**
 * Get user transaction list api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getUserEventListAPI = (params) => {
  return axios.get(`${GET_EVENT_LIST_API}`, { params });
};

/**
 * Get user details api call
 * @param  data
 * @returns {Promise<any>}
 */
export const getUserDetailsAPI = (id) => {
  return axios.get(`${GET_USER_DETAILS_API}/${id}`);
};

/**
 * Change user status
 * @param  data
 * @returns {Promise<any>}
 */
export const changeUserStatus = (data) => {
  return axios.post(CHANGE_USER_STATUS_API, data);
};

/**
 * Delete user
 * @param  data
 * @returns {Promise<any>}
 */
export const deleteUser = (data) => {
  return axios.post(DELETE_USER_API, data);
};

import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';

import { View } from './View/View';
import { Edit } from './Edit/Edit';
import { Add } from './Add/Add';


export const Levels = () => {
    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
            <Route path="/view/:cms_id" element={<View />} /> 
            <Route path="/edit/:cms_id" element={<Edit />} /> 
            <Route path="/add" element={<Add  exect={true} />} />
        </Routes>
    )
};

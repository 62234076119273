import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { replyContactusSchema } from '../../../validations/ValidationSchema';
import { updateContactusAPI, viewContactusAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';

export const EditContact = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    let { contactus_id } = useParams();
    const location = useLocation();
    const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
    const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
    const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;
    
  

    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
            if(contactus_id){
                editContactus(value)
            }
    }

    const handleCancel = () =>{
        navigate(`/app/contactus`, { state: {pageNumber, searchedValue, rowPerPage}});
    }


    const editContactus = async(value) =>{

        setLoading(true)
        
        const reply = value.reply
        
        updateContactusAPI({
            contactus_id : contactus_id,
            reply : reply.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            //navigate("/app/contactus");
            navigate(`/app/contactus`, { state: {pageNumber, searchedValue, rowPerPage}});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    
    // Get contact-us query 
    useEffect(() => {
        if(contactus_id){
            viewContactusAPI({contactus_id : contactus_id})
                .then(response => {
                setData(response?.data)
                setLoading(false)
            })
        }else{
            setLoading(false)
        }
    },[]);


    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={replyContactusSchema}
          initialValues={
            {   
               reply :data.reply ? data.reply : ""
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                    { contactus_id ? 'Contact Detail' : 'Contact Detail'}
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                  
                                  <Row className="gx-xl-5">
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                            <InputText
                                              controlId="name"
                                              label="Name"
                                              value={data.name}
                                              name= "name"
                                              maxLength= "300"
                                              touched={touched.title}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                              disabled="true"
                                            />
                                      </Col>
                                      
                                  </Row>

                                  <Row className="gx-xl-5">
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          <InputText
                                              controlId="email"
                                              label="Email Address"
                                              value={data.email}
                                              name= "email"
                                              maxLength= "300"
                                              touched={touched.title}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                              disabled="true"
                                          />
                                      </Col>
                                      
                                  </Row>

                                  <Row className="gx-xl-5">
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          <InputText
                                              controlId="subject"
                                              label="Subject"
                                              value={data.subject}
                                              name= "subject"
                                              maxLength= "300"
                                              touched={touched.title}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                              disabled="true"
                                          />
                                      </Col>
                                      
                                  </Row>

                                  <Row className="gx-xl-5">
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          <InputText
                                              controlId="message"
                                              label="Message"
                                              value={data.message}
                                              name= "message"
                                              maxLength= "300"
                                              touched={touched.title}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                              disabled="true"
                                          />
                                      </Col>
                                      
                                  </Row>

                                  <Row className="gx-xl-5">
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                        
                                            <InputText
                                                controlId="reply"
                                                label="Reply"
                                                value={values.reply}
                                                name= "reply"
                                                maxLength= "3000"
                                                errorsField={errors.reply}
                                                touched={touched.reply}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                className="w-100"
                                            />
                                           
                                      </Col>
                                      
                                  </Row>

                                  
                                  {errors && errors.weight_type && typeof(errors.weight_type)=='string' && <span className='text-danger'>{errors?.weight_type}</span>}

                                  <Stack direction="horizontal" gap={3} className="my-3">
                                    
                                            <Button 
                                                variant="primary"
                                                onClick={handleSubmit}
                                                className="rounded-xs fs-15"
                                                type="submit"
                                            >Reply</Button>
                                    
                                      {/* <Link to="/app/contactus"  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                                      <div onClick={handleCancel}  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</div>
                                  </Stack>
                              </div>
                          </div>
                      </div>
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}
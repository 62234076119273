
import { DataGrid, GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import React, { useState } from 'react'
import './datagrid.scss';

export const MatDataGrid = ({ controlId,
                              columns,
                              data,
                              sortModel,
                              columnGroupingModel
                             }) => {
    const [pageSize, setPageSize] = useState(10);

    const NoRowsFoundMessage = () => {
        return (
          <div style={{ textAlign: 'center', padding: '16px' }}>
            No records found.
          </div>
        );
      };
      

    return (
        <div className="dataGridMain flex-fill w-100">
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: sortModel,
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: pageSize },
                    },
                }}
                rows={data}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // pageSizeOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                pageSizeOptions={[10, 25, 50, 100 ]}
                disableSelectionOnClick
                getRowId={(row) => row[controlId]}
                pagination={false}
                disableColumnMenu={true}
                components={{
                    NoRowsOverlay: NoRowsFoundMessage,
                }}
                localeText={{
                    noRowsLabel: '', // Set an empty string to hide the default message
                }}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={columnGroupingModel}
            />
        </div>
    )
}

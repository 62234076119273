import React, { useState, useEffect } from 'react'
import { Breadcrumb, Button, Form, Card, Col, Row, Stack, Spinner } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CustomTextField } from '../../../components/common/CustomTextField';
import { CustomSelect } from '../../../components/common/CustomSelect';
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Formik, FieldArray, Field, getIn } from "formik"
import {onboardingAnswerType } from "../../../constants/commonValue"
import InputText from "../../../components/common/InputText"
import { AddVideoSchema } from '../../../validations/ValidationSchema';
import { addVideoAPI, viewVideoAPI, levelsListingAPI } from '../../../services/userService';
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios"
import storage from "../../../helpers/storage"
import { IMAGE_UPLOAD_API, VIDEO_UPLOAD_API } from '../../../constants/paths';
import { FaUserAlt, FaImage } from "react-icons/fa";
import { MdVideoLibrary } from "react-icons/md";


export const Edit = () => {

    // data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [MessageCategoryData, setMessageCategoryData] = useState([]);
    const navigate = useNavigate();
    let { cms_id } = useParams();
    const location = useLocation();console.log('location',location)
    const pageNumber = location?.state?.pageNumber ? location?.state?.pageNumber : '1';
    const searchedValue = location?.state?.searchedValue ? location?.state?.searchedValue : '';
    const rowPerPage = location?.state?.rowPerPage ? location?.state?.rowPerPage : 10;
    const [fileKey, setFileKey] = useState() // Image
    const [fileFullUrl, setFileFullUrl] = useState() // Image
    const [videoFileKey, setVideoFileKey] = useState() // Video

    const [videoFileFullUrl, setVideoFileFullUrl] = useState() // Video
    const [videothumKey, setVideothumKey] = useState() // Video
    
    
    const [imageLoading,setImageLoading] = useState(false); // Image
    const [iconLoading,setIconLoading] = useState(false); // video
     

    // Custom Select handling
    const [selectedValue, setSelectedValue] = useState('');
    const handleValueChange = (value) => {
        setSelectedValue(value);
    };

    const [deviceType, setDeviceType] = useState('');
    const handleDeviceTypeChange = (value) => {
        setDeviceType(value);
    };

    const [storageLimit, setStorageLimit] = useState('');
    const handleStorageLimit = (value) => {
        setStorageLimit(value);
    };
    
    const ImageUplodUrl = process.env.REACT_APP_API_URL+'/api'+IMAGE_UPLOAD_API;
    const VideoUplodUrl = process.env.REACT_APP_API_URL+'/api'+VIDEO_UPLOAD_API;
    
    // add header
    function authRequestInterceptor(config) {
        const token = storage.getToken()
        if (token) {
          config.headers.authorization = `bearer ${token}`
        }
        config.headers.Accept = "application/json"
        return config
    }

    // upload Video
    const handleUploadVideo = async (event, setFieldValue ) =>{
        
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        
        // Apply authorization header to formData using the interceptor function
        const config = authRequestInterceptor({
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        
        setIconLoading(true)
        setFieldValue('video','video')
        axios.interceptors.request.use(authRequestInterceptor)
        
       
        formData.append('video_file', selectedFile);
        formData.append('userId', '1');
        
        try {
            const response = await axios.post(VideoUplodUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
            },
        });
            
            if(response.data){

                // Handle the server response here.
                setVideothumKey(response.data.data.Key)
                setVideoFileKey(response.data.data.Key)
                //setVideoFileKey(response.data.image_key)
                setVideoFileFullUrl(response.data.full_image)
               
                setIconLoading(false)
                 console.log('videoFileKey',videoFileKey)
            }
        } catch (error) {
            setIconLoading(false)
            toast.error('Uploaded file is not a valid video. Only mp4 files are allowed.')
        }
    }

    // upload Image
    const handleUploadImage = async (event ) =>{
        
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        
        // Apply authorization header to formData using the interceptor function
        const config = authRequestInterceptor({
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        
        setImageLoading(true);
        
        axios.interceptors.request.use(authRequestInterceptor)
        
        formData.append('file', selectedFile);
        formData.append('image', selectedFile);
        formData.append('userId', '46');
        
        try {
            const response = await axios.post(ImageUplodUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
           // console.log("response",response)
            if(response.data){
                
                // Handle the server response here.
                setFileKey(response.data.data.key)
                setFileFullUrl(response.data.full_image);
                setImageLoading(false)
                
            }
        } catch (error) {
            setImageLoading(false)
            toast.error('Uploaded file is not a valid image. Only JPG, PNG and JPEG files are allowed.')
            // Handle errors here.
        }
    }

    // Submit onboarding
    const handleSubmit = async (value, actions) => {
            if(cms_id){
                editFormData(value)
            }else{
                addFormData(value)
            }
    }

    const editFormData = async(value) =>{

        setLoading(true)
        
        const title = value.title
        const content = value.content
        const category_id = value.category_id
        const level = value.level
        const url = value.url
        

        addVideoAPI({
            row_id : cms_id,
            name : title.toString(),
            description : content.toString(),
            video : videoFileKey,
            video_thum : fileKey,
            category_id : category_id.toString(),
            level : level.toString(),
            url : url.toString(),    
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate(`/app/video`, { state: {pageNumber, pageNumber, searchedValue, rowPerPage  }});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const addFormData = async(value) =>{

        const title = value.title
        const content = value.content
        const category_id = value.category_id
        const level = value.level
        const url = value.url
        
        addVideoAPI({
                name : title.toString(),
                description : content.toString(),
                video : videoFileKey,
                video_thum : fileKey,
                category_id : category_id.toString(),
                level : level.toString(),
                url : url.toString(),
          })
          .then(response => {
            setLoading(false)
            toast.success(response.message)
            navigate(`/app/video`, { state: {pageNumber, pageNumber, searchedValue, rowPerPage  }});
          })
          .catch(error => {
            setLoading(false)
            toast.error(error.response.data.message)
        })
    }

    const handleCancel = () =>{
        navigate(`/app/video`, { state: {pageNumber, pageNumber, searchedValue, rowPerPage  }});
    }

    // Get onboarding 
    useEffect(() => {
        if(cms_id){
            viewVideoAPI({row_id : cms_id})
                .then(response => {
                setData(response?.data)
                setFileKey(response.data.video_thum) // Image
                setFileFullUrl(response.data.full_video_thum_url); // Image

                setVideoFileKey(response.data.video) // video
                setVideoFileFullUrl(response.data.full_video_url) // video
                setLoading(false)
            })
        }else{
            setLoading(false)
        }


        levelsListingAPI({search : ''})
                .then(response => {
                    setMessageCategoryData(response?.data)
                    setLoading(false)
                }) 
    },[]);

    const modules = {
        toolbar: [
        //   [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        //   [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        //   ['link', 'image',],
        ['link'],
        [{ 'script': 'sub' }, { 'script': 'super' }], // Add super and sub options here
        ['clean']
        ],
        clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
        },
    };

    const formats = [
        // 'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'indent',
        // 'link', 'image', 'video',
        'link',
        'script', // Include the script format
    ];

    return (
      <>
      {loading ?  <Loader isLoading={loading} /> :   <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Formik
          validationSchema={AddVideoSchema}
          initialValues={
            {   
               
                category_id :data.category_id ?data.category_id : "" , 
                level :data.level ?data.level : ""  ,
                title :data.title ?data.title : ""  ,
                url :data.url ?data.url : ""  ,
                video_thum :data.video_thum ?data.video_thum : ""  ,
                video :data.video ?data.video : ""  ,
                content :data.content ?data.content : ""  
            }
          }
          onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              handleSubmit(values, actions)
          }}
      >
          {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors
          }) => (
              <div className="flex-grow-1 pageContent position-relative pt-4">
                  {/* {JSON.stringify(data)} */}
                  <Card className="h-100 bg-white shadow border-0 theme-card-cover">
                      <div className="theme-card-header px-1">
                          <Stack direction="horizontal" gap={2} className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start">
                              <h5 className="mb-0 position-relative fw-medium">
                                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                                      <span className="theme-icon-box-inner"><AiFillQuestionCircle size={36} /></span>
                                  </div>
                                    { cms_id ? 'Edit Video' : 'Add Video'}
                                  
                              </h5>
                          </Stack>
                      </div>
                      <div className="flex-grow-1 d-flex flex-column px-1">
                          <div className="p-3 d-flex flex-column h-100">
                              <div className="d-flex flex-column flex-grow-1 theme-from">
                                  
                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          
                                            <CustomSelect
                                                options={[
                                                    { label: 'Attachment', value: '1' },
                                                    { label: 'Busyness', value: '2' },
                                                    { label: 'Executive Function', value: '4' },
                                                    { label: 'Neurodiversity', value: '5' },
                                                ]}
                                                controlId="category_id"
                                                label="Select the category"
                                                value={values.category_id}
                                                name= "category_id"
                                                errorsField={errors.category_id}
                                                touched={touched.category_id}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <span className='text-danger answer-type-error fs-11'>{getIn(errors,`category_id`)}</span>
                                      </Col>
                                      
                                  </Row>

                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          
                                            <CustomSelect
                                                options={ MessageCategoryData.map((categorysObj) => ({
                                                    label: categorysObj.level,
                                                    value: categorysObj.id,
                                                }))}
                                                controlId="level"
                                                label="Select the level"
                                                value={values.level}
                                                name= "level"
                                                errorsField={errors.level}
                                                touched={touched.level}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                onChange={handleChange}

                                            />
                                            <span className='text-danger answer-type-error fs-11'>{getIn(errors,`level`)}</span>
                                      </Col>
                                      
                                  </Row>

                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          <InputText
                                              controlId="title"
                                              label="Title"
                                              value={values.title}
                                              name= "title"
                                              maxLength= "70"
                                              errorsField={errors.title}
                                              touched={touched.title}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                          />
                                      </Col>
                                    </Row>
                                    <Row className="gx-xl-5">
                                      <Col sm={6} md={6} lg={4} className="mb-4 pb-1">
                                          <InputText
                                              controlId="url"
                                              label="URL"
                                              value={values.url}
                                              name= "url"
                                              maxLength= "300"
                                              errorsField={errors.url}
                                              touched={touched.url}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              className="w-100"
                                          />
                                      </Col>
                                    </Row>
                                    <Row className="gx-xl-5">
                                      
                                      
                                        
                                            <Col sm={12} md={12} lg={12} className="pt-5 pb-1 d-flex align-items-end profileTop">
                                                    <div id='' className='height-100 width-100 d-flex justify-content-center align-items-center profilebg rounded-2 me-3'>
                                                        {!fileFullUrl &&  <FaImage size={30} className='profileuser' /> }
                                                        {imageLoading ? <Spinner size='sm' /> : <img src={fileFullUrl}  className='img-thumb'/>}
                                                    </div>
                                                    <label className='position-relative'>
                                                        <span variant="contained" component="label" className='text-primary text-decoration-underline fs-13' >
                                                            Upload video thumb                                         
                                                        </span>
                                                        <input 
                                                            type="file"  
                                                            name="video_thum"
                                                            
                                                            onChange={(e) => { handleUploadImage(e); setFieldValue('video_thum','ok');} }
                                                            className='opacity-0 position-absolute start-0 w-64' 
                                                            id='browse'
                                                        />
                                                    </label>
                                                    <p className='m-0 ms-3 fs-12 labelColor'>
                                                        Maximum size to upload a image is 100 mb. Supported format are png, jpeg and jpg
                                                    </p>
                                                    
                                                
                                            </Col>
                                            <Col sm={12} md={12} lg={12} className="mb-3">
                                                    <span className='text-danger answer-type-error fs-11'>{getIn(errors,`video_thum`)}</span>
                                            </Col>
                                            <Col sm={12} md={12} lg={12} className="">
                                                <p className='m-0 fs-12 labelColor p-0'>
                                                <b>Note:</b> Image dimensions should be more than (990 pixels in height x 582 pixels in width).
                                                </p>
                                            </Col>
                                            <Col sm={12} md={12} lg={12} className="pt-5 pb-1 d-flex align-items-end profileTop">
                                                    <div id='' className='height-200 width-200 d-flex justify-content-center align-items-center profilebg rounded-2 me-3'>
                                                        
                                                        {iconLoading ? <Spinner size='sm' /> 
                                                        : <video controls className='img-thumb w-400 height-200 '>
                                                                <source src={videoFileFullUrl} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                        </video>
                                                         }
                                                    </div>
                                                    <label className='position-relative'>
                                                        <span variant="contained" component="label" className='text-primary text-decoration-underline fs-13' >
                                                            Upload Video                                           
                                                        </span>
                                                        <input 
                                                            type="file"  
                                                            name="video"
                                                            onChange={(e) => { handleUploadVideo(e,setFieldValue);} }
                                                            className='opacity-0 position-absolute start-0 w-64' 
                                                            id='browse'
                                                        />
                                                    </label>
                                                    <p className='m-0 ms-3 fs-12 labelColor'>
                                                        Maximum size to upload a video is 200 mb.
                                                    </p>
                                                    
                                            </Col>
                                            <Col sm={12} md={12} lg={12} className="mb-3">
                                                <span className='text-danger answer-type-error fs-11'>{getIn(errors,`video`)}</span>
                                            </Col>
                                        
                                    </Row>


                                  <Row className="gx-xl-5">
                                  {/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
                                      <Col sm={6} md={6} lg={7} className="mb-4 pb-1">
                                          <Form.Label className="mb-1 fw-normal labelColor fs-13">Description</Form.Label>
                                          <ReactQuill 
                                            modules={modules}
                                            formats={formats}
                                            name="content"
                                            theme="snow"
                                            value={values.content}
                                            onChange={(value)=>setFieldValue('content', value)}
                                            />
                                            <span className='text-danger answer-type-error fs-11'>{getIn(errors,`content`)}</span>
                                      </Col>
                                  </Row>


                                  {errors && errors.weight_type && typeof(errors.weight_type)=='string' && <span className='text-danger'>{errors?.weight_type}</span>}

                                  <Stack direction="horizontal" gap={3} className="my-3">
                                      <Button 
                                          variant="primary"
                                          onClick={handleSubmit}
                                          className="rounded-xs fs-15"
                                          type="submit"
                                          >Save</Button>
                                      {/* <Link to="/app/artical"  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</Link> */}
                                      <div onClick={handleCancel}  className="btn btn-outline-secondary rounded-xs fs-15">Cancel</div>
                                  </Stack>
                              </div>
                          </div>
                      </div>
                    </Card>
              </div>
          )}
      </Formik>

  </div>}
      </>
    )
}
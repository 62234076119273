import React, { useEffect, useState } from "react";

import moment from "moment";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Placeholder,
  Row,
  Stack,
} from "react-bootstrap";
import { MdHandshake, MdSupervisorAccount, MdClear } from "react-icons/md";
import { MatDataGrid } from "../../../components/common/dataGrid/DataGrid";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { SwitchCell } from "../../../components/common/dataGrid/SwitchCell";
import { TextField } from "@mui/material";
import CustomSelect from "../../../components/common/CustomSelect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { deleteUser } from "../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import Datepicker from "../../../components/common/Datepicker";
import { getPaymentHistoryAPI } from "../../../services/paymentHistory.service";

const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#17B0B2",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

export const AllList = () => {
  const cookies = new Cookies();

  cookies.set("page_heading", "CMS Pages"); // page heading
  const location = useLocation();
  const [selectedValue, setSelectedValue] = useState(
    location?.state?.searchedValue ? location?.state?.searchedValue : ""
  );
  const [selectedStartDate, setSelecteStartDate] = useState("");
  const [selectedEndDate, setSelecteEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [userStatus, setUserStatus] = useState();
  const [userData, setUserData] = useState([]);
  const [mutation, setMutation] = useState(true);

  const [filter, setFilter] = useState({
    search: "",
  });
  const navigate = useNavigate();
  const columns = [
    {
      field: "showing_id",
      headerName: "ID",
      editable: false,
      flex: 0.05,
      minWidth: 100,
    },
    {
      field: "first_name",
      headerName: "User Name",
      editable: false,
      flex: 0.6,
      minWidth: 150,
      maxWidth: 150,
    },

    {
      field: "email",
      headerName: "Email Address",
      editable: false,
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "start_date",
      headerName: "Purchased Date",
      editable: false,
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "plan_title",
      headerName: "Plan Name",
      editable: false,
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "Plan Cost",
      editable: false,
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "end_date",
      headerName: "Plan Expiry Date",
      editable: false,
      flex: 0.5,
      minWidth: 150,
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   editable: false,
    //   flex: 0.20,
    //   minWidth: 120,
    //   disableColumnMenu: true,
    //   sortable: false,
    //   renderCell: (props) => { return <ActionCell controlId='app/cms' isViewEnable={true} isEditEnable={false} isDeleteEnable={false}  deleteFunctionName={deleteRow} isLogInEnable={true} redirectID={props.row.id} rowData={props.row} /> }
    // }
  ];

  // Delete
  const deleteRow = (userId) => {
    setLoading(true);
    console.log(userId);
    deleteUser({
      userId: userId.toString(),
    })
      .then((response) => {
        setMutation(!mutation);
        toast.success(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  // Searching and listing user list
  const handleValueChange = (value) => {
    setSelectedValue(value);
    setFilter({
      ...filter,
      search: value,
    });
    fetchUsers({
      page: page,
      limit: rowPerPage,
      ...filter,
      search: value,
    });
  };

  // Handle clear text from searchbox
  const handleClear = () => {
    setSelectedValue("");
    setFilter({
      search: "",
    });
    fetchUsers({
      page: page,
      limit: rowPerPage,
      search: "",
    });
  };

  //date change list date
  const handleDateStartChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    setSelecteStartDate(date);
    setFilter({
      ...filter,
      startDate: formattedDate,
    });
    fetchUsers({
      page: page,
      limit: rowPerPage,
      startDate: formattedDate,
      ...filter,
    });
  };

  const handleDateEndChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    setSelecteStartDate(date);
    setFilter({
      ...filter,
      endDate: formattedDate,
    });
    fetchUsers({
      page: page,
      limit: rowPerPage,
      endDate: formattedDate,
      ...filter,
    });
  };

  // Get list details
  const fetchUsers = (params) => {
    getPaymentHistoryAPI({ search: "", ...params }).then((response) => {
      setUserData(response.userResult);
      setPage(response.page);
      setCurrentPage(response.page);
      setRowPerPage(response.limit);
      setLoading(false);
    });
  };

  // On page load fetch user list
  useEffect(() => {
    fetchUsers({
      page: page,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      ...(selectedValue ? { search: selectedValue } : {}),
    });
  }, [mutation]);

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        {loading ? (
          "Loading..."
        ) : (
          <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <MdSupervisorAccount size={40} />
                    </div>
                    Payment List
                  </h5>
                </Stack>
              </div>
              <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
                <div className="theme-data-table-filter px-1">
                  <ThemeProvider theme={customTheme}>
                    <Row className="justify-content-end">
                      <Col sm={5} lg={2} className="mb-3 pb-1">
                        <TextField
                          id="search-filter"
                          label="Search"
                          variant="standard"
                          fullWidth
                          value={selectedValue}
                          onChange={(e) => {
                            handleValueChange(e.target.value);
                          }}
                        />
                        <MdClear
                          className="clear-postion"
                          onClick={handleClear}
                        ></MdClear>
                      </Col>
                      <Col sm={5} lg={2} className="mb-3 pb-1 datePicker">
                        <Datepicker
                          id="date-filter"
                          name="Date"
                          date={selectedStartDate}
                          variant="standard"
                          handleDateChange={handleDateStartChange}
                          value={selectedStartDate}
                          maxDate={selectedStartDate}
                          placeholder="Start Date"
                          slotProps={{
                            textField: { Placeholder: Placeholder },
                          }}
                        />
                      </Col>
                      <Col sm={5} lg={2} className="mb-3 pb-1 datePicker">
                        <Datepicker
                          id="date-filter"
                          name="Date"
                          date={selectedEndDate}
                          variant="standard"
                          handleDateChange={handleDateEndChange}
                          value={selectedEndDate}
                          minDate={selectedStartDate}
                        />
                      </Col>
                    </Row>
                  </ThemeProvider>
                </div>
                <MatDataGrid
                  controlId={"id"}
                  columns={columns}
                  data={userData}
                />
              </div>
            </Card>
          </div>
        )}
      </div>
      <Loader isLoading={loading} />
    </>
  );
};

import React, { useState } from 'react'
import { Breadcrumb, Card, Nav, Stack, Tab } from 'react-bootstrap'
import { MdSupervisorAccount } from 'react-icons/md'
import { Link } from 'react-router-dom';
import { WatchVideo } from './video';
import { SendMessage } from './message';

export const DetailsNotification = () => {
  const notificationTabsData = [
    {
      id: 1,
      name: 'Send Message',
      Component: <SendMessage />
    },
    {
      id: 2,
      name: 'Watch Video and Article',
      Component: <WatchVideo />
    },
  ]
  const [activeTab, setActiveTab] = useState('tab-1');
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      
      <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
        <Card className="h-100 bg-white shadow border-0 theme-card-cover">
          
          <div className="flex-grow-1 d-flex flex-column h-100 overflow-auto">
            <Tab.Container
              id="user-tab-container"
              defaultActiveKey={activeTab}
              mountOnEnter
              unmountOnExit
              onSelect={handleTabSelect}
            >
              <div className="d-flex flex-column h-100 fs-15">
                <div className="theme-tab-header">
                  <Nav variant="tabs" className="px-3 flex-nowrap text-nowrap overflow-x-auto overflow-y-hidden border-light">
                    {notificationTabsData.map((tabNames) => {
                      const { id, name } = tabNames;
                      return (
                        <Nav.Item key={id}>
                          <Nav.Link eventKey={`tab-${id}`} className="border-4 border-end-0 border-start-0 border-top-0 fw-semibold text-center fs-18 text-primary fw-medium">{name}</Nav.Link>
                        </Nav.Item>
                      )
                    })}
                  </Nav>
                </div>

                <Tab.Content className="theme-tab-content flex-grow-1 px-3 overflow-auto">
                  {notificationTabsData.map((tabNames) => {
                    const { id, Component } = tabNames;
                    return (<Tab.Pane className="h-100" key={id} eventKey={`tab-${id}`}>
                      <div className="p-1 h-100 d-flex flex-column">{Component}</div>
                    </Tab.Pane>
                    )
                  })}
                </Tab.Content>

              </div>
            </Tab.Container>
          </div>
        </Card>
      </div>
    </div>
  )
}

import * as yup from "yup";
import {
  ONBOARDING_QUESTION_REQUIRED,
  ANSWER_TYPE_REQUIRED,
  WEIGHTAGE_REQUIRED,
  MAXIMUM_30_CHAR,
  MINIMUM_8_CHAR,
  PASSWORD_REGEX,
  CONFIRM_PASSWORD_REQUIRED,
  CURRENT_PASSWORD_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_MATCH,
  TITLE_REQUIRED,
  CONTENT_REQUIRED,
  PAGETYPE_REQUIRED,
  REPLY_REQUIRED,
  CATEGORY_REQUIRED,
  IMAGE_REQUIRED,
  URL_REQUIRED,
  LEVEL_REQUIRED,
  QOUTES_REQUIRED,
  VIDEO_REQUIRED,
  VIDEO_THUM_REQUIRED,
  ICON_REQUIRED,
  MESSAGE_REQUIRED,
  TEXT_COLOR_REQUIRED,
  CATEGORY_TYPE_REQUIRED,
} from "./ValidationErrors";

// https://chat.openai.com
// const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

// https://chat.openai.com  and www.chat.openai.com
const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^www\.[^\s/$.?#].[^\s]*$/;

//const paswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d.*\d)(?=.*[!$%^()_+<>#*@])\S+$/;
const paswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d.*\d)(?=.*[!$%^()_+<>#*@`~&=\|'";:/?.,])\S+$/;


// Onboarding
export const OnboardingSchema = yup.object().shape({
  question: yup.string().required('Pleaes enter questions title'),
  weight_type: yup
    .array()
    .of(
      yup.object().shape({
        answer: yup.number().min(1,"Please enter a value between 1 to 10").max(10,"Please enter a value between 1 to 10").required('Pleae enter value'),
        answerType: yup.string().min(1, 'Pleae select option').required('Pleae select option'),
      })
    )
    .required('Must have answers')
    .min(4, 'Minimum of 4 answers')
    .max(4, 'Maximum of 4 answers')
    .test('uniqueAnswerTypes', 'Answer types must be unique', function (value) {
      const answerTypes = value.map((item) => item.answerType);
      const uniqueAnswerTypes = [...new Set(answerTypes)];
      return answerTypes.length === uniqueAnswerTypes.length;
    })
    .test('answer', 'Answer weightage value must be unique', function (value) {
      const answer = value.map((item) => item.answer);
      const uniqueAnswer = [...new Set(answer)];
      return answer.length === uniqueAnswer.length;
    }),
});

// Profile
export const ProfileSchema = yup.object().shape({
  current_password: yup
    .string()
    .required(CURRENT_PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR)
    .matches(
      paswordRegex,
      PASSWORD_REGEX
    ),
  new_password: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR)
    .matches(
      paswordRegex,
      PASSWORD_REGEX
    ),  
    confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("new_password"), ""], CONFIRM_PASSWORD_MATCH),
    //.oneOf([yup.ref('new_password'), null], CONFIRM_PASSWORD_MATCH),
});

// Add CMS
export const AddCmsSchema = yup.object().shape({
  title: yup.string().required(TITLE_REQUIRED),
  page_type: yup.string().required(PAGETYPE_REQUIRED),
  content: yup.string().required(CONTENT_REQUIRED)
    .when('page_type', {
      is: 'TEXT_WITH_ASSESSMENT_RESULT_APP',
      then: yup.string().required(CONTENT_REQUIRED).max(157, 'Result app page type must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'CONTINUE_ASSESSMENT_APP',
      then: yup.string().required(CONTENT_REQUIRED).max(112, 'Assessment page type must be 105 characters or less'),
    })
    .when('page_type', {
      is: 'RESULT_POP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Result pop must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'ADD_RELATIONSHIP_ADD_EVENT_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Result pop must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'ABOUT_ARTICLE_TEXT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'About article text must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'ABOUT_VIDEO_TEXT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'About video text must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'ADD_PROFILE_INFO_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Profile info popup must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'ASSESSMENT_CATEGORY_INFO_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Assessment info popup must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'DEPRESSION_CATEGORY_INFO_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Depression popup must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'MARK_COMPLETED_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Mark completed popup must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'MARK_COMPLETED_REASON',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Mark completed reason popup must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'MARK_COMPLETED_RETRY_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Mark completed retry popup must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'ONBOARDING_AFTER_5_QUESTION_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Onboarding popup must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'SKIP_QUESTION_OF_ASSESSMENT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Skip question popup must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'REASSESSMENT_COMPLETED',
      then: yup.string().required(CONTENT_REQUIRED).max(250, 'Re-Assessment completed popup must be 250 characters or less.'),
    })
    .when('page_type', {
      is: 'REASSESSMENT_NOT_COMPLETED',
      then: yup.string().required(CONTENT_REQUIRED).max(250, 'Re-Assessment not completed popup must be 250 characters or less.'),
    })
    .when('page_type', {
      is: 'RE_ASSESSMENT_APP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Re-Assessment must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'START_ASSESSMENT_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Start assessment must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'CONTINUE_ASSESSMENT_SCHEDULE_TEXT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Continue assessment schedule must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'REMINDER_RESPONSE_CHECK_TEXT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Reminder must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'HOME_RELATIONSHIP_LIST_TEXT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Relationship list must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'PAYMENT_PLAN_SCREEN_TEXT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Payment plan must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'SUGGESTED_MESSAGE_LIST_TEXT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Suggested message must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'SUGGESTED_MESSAGE_DETAIL_TEXT',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Suggested message must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'MARK_COMPLETED_CONFIRM_POPUP',
      then: yup.string().required(CONTENT_REQUIRED).max(150, 'Mark completed must be 150 characters or less.'),
    })
    .when('page_type', {
      is: 'INFO_FAVORITES_ON_RELATIONSHIP',
      then: yup.string().required(CONTENT_REQUIRED).max(105, 'Info about favourite specific must be 105 characters or less.'),
    })
    .when('page_type', {
      is: 'INFO_SPECIFICS_ON_RELATIONSHIP',
      then: yup.string().required(CONTENT_REQUIRED).max(105, 'Info about interest specific must be 105 characters or less.'),
    })
    .when('page_type', {
      is: 'INFO_INTERESTS_ICON_ON_RELATIONSHIP',
      then: yup.string().required(CONTENT_REQUIRED).max(105, 'Info about interest must be 105 characters or less.'),
    })
    .when('page_type', {
      is: 'INFO_POPUP_BUSINESS_CATEGORY_ASSESSMENT',
      then: yup.string().required(CONTENT_REQUIRED).max(105, 'Info about business category must be 105 characters or less.'),
    })
    .when('page_type', {
      is: 'INFO_POPUP_DEPRESSION_CATEGORY_ASSESSMENT',
      then: yup.string().required(CONTENT_REQUIRED).max(105, 'Info about depression category must be 105 characters or less.'),
    })
    .when('page_type', {
      is: 'INFO_POPUP_EXECUTIVE_CATEGORY_ASSESSMENT',
      then: yup.string().required(CONTENT_REQUIRED).max(105, 'Info about excutive category must be 105 characters or less.'),
    })
    .when('page_type', {
      is: 'INFO_POPUP_NEURODIVERSITY_CATEGORY_ASSESSMENT',
      then: yup.string().required(CONTENT_REQUIRED).max(105, 'Info about neurodiversity category must be 105 characters or less.'),
    })
    .when('page_type', {
      is: 'INFO_POPUP_ATTACHMENT_CATEGORY_ASSESSMENT',
      then: yup.string().required(CONTENT_REQUIRED).max(105, 'Info about attachment category must be 105 characters or less.'),
    })
    .when('page_type', {
      is: 'START_ASSESSMENT',
      then: yup.string().required(CONTENT_REQUIRED).max(157, 'Assessment start must be 150 characters or less.'),
      otherwise: yup.string().required(CONTENT_REQUIRED),
    }),
});


// Add Reply in contact-us
export const replyContactusSchema = yup.object().shape({
  reply: yup.string().required(REPLY_REQUIRED),
});

// Add Quotes
export const AddQuotesSchema = yup.object().shape({
  name: yup.string().required(QOUTES_REQUIRED).max(70, 'Quotes title should not be more than 70 characters.'),
  image: yup.string().required(IMAGE_REQUIRED),
  content: yup.string().required(CONTENT_REQUIRED),
  text_color: yup.string().required(TEXT_COLOR_REQUIRED),
});

// Add Artical
export const AddArticalSchema = yup.object().shape({
  category_id: yup.string().required(CATEGORY_REQUIRED),
  level: yup.string().required(LEVEL_REQUIRED),
  title: yup.string().required(TITLE_REQUIRED).max(300, 'Article title should not be more than 300 characters.'),
  image: yup.string().optional(),
  content: yup.string().required(CONTENT_REQUIRED),
  //url: yup.string().required(URL_REQUIRED),
  // url: yup
  //   .string()
  //   .required(URL_REQUIRED)
  //   .matches(urlRegex, 'Invalid URL format. Please enter a valid URL.'),
});

// Add Video
export const AddVideoSchema = yup.object().shape({
  category_id: yup.string().required(CATEGORY_REQUIRED),
  level: yup.string().required(LEVEL_REQUIRED),
  title: yup.string().required(TITLE_REQUIRED).max(300, 'Video title should not be more than 300 characters.'),
  video: yup.string().required(VIDEO_REQUIRED),
  video_thum: yup.string().required(VIDEO_THUM_REQUIRED),
  content: yup.string().required(CONTENT_REQUIRED),
  //url: yup.string().required(URL_REQUIRED),
  url: yup
    .string()
    .optional()
    .matches(urlRegex, 'Invalid URL format. Please enter a valid URL.'),
  
});

// Add Message Category
export const AddCategorySchema = yup.object().shape({
  category_type: yup.string().required(CATEGORY_TYPE_REQUIRED),
  category: yup.string().required(CATEGORY_REQUIRED),
  image: yup.string().required(IMAGE_REQUIRED),
  icon: yup.string().required(ICON_REQUIRED),
});

// Add Message Template
export const AddMessageTemplateSchema = yup.object().shape({
  message_category_id: yup.string().required(CATEGORY_REQUIRED),
  message: yup.string().required(MESSAGE_REQUIRED),
});

// Assessment
export const AssessmentSchema = yup.object().shape({
  category_id: yup.string().required(CATEGORY_REQUIRED),
  level: yup.string().required(LEVEL_REQUIRED),
  question: yup.string().required('Pleaes enter questions title'),
  weight_type: yup
    .array()
    .of(
      yup.object().shape({
        answer: yup.number().min(1,"Please enter a value between 1 to 10").max(10,"Please enter a value between 1 to 10").required('Pleae enter value'),
        answerType: yup.string().min(1, 'Pleae select option').required('Pleae select option'),
      })
    )
    .required('Must have answers')
    .min(4, 'Minimum of 4 answers')
    .max(4, 'Maximum of 4 answers')
    .test('uniqueAnswerTypes', 'Answer types must be unique', function (value) {
        const answerTypes = value.map((item) => item.answerType);
        const uniqueAnswerTypes = [...new Set(answerTypes)];
        return answerTypes.length === uniqueAnswerTypes.length;
    })
    .test('answer', 'Answer weightage value must be unique', function (value) {
        const answer = value.map((item) => item.answer);
        const uniqueAnswer = [...new Set(answer)];
        return answer.length === uniqueAnswer.length;
    }),
});

// Add Level
export const AddLevelSchema = yup.object().shape({
    level: yup.string().required(LEVEL_REQUIRED).min(1, 'Minimum of level 1'),
});

// Add Message Category Type
export const AddCategoryTypeSchema = yup.object().shape({
  category_type: yup.string().required(CATEGORY_TYPE_REQUIRED)
});

// Default Notification Alert Time
export const DefaultNotificationAlertSchema = yup.object().shape({
  hour: yup.string().required('Select hour'),
  minute: yup.string().required('Select minute'),
  format: yup.string().required('Select AM/PM'),
  day: yup.string().optional(),

  message_hour: yup.string().required('Select hour'),
  message_minute: yup.string().required('Select minute'),
  message_format: yup.string().required('Select AM/PM'),
  message_day: yup.string().optional(),
});

// Add Quotes
export const AddEmailContentSchema = yup.object().shape({
  email_type: yup.string().required('Pleae select email type'),
  content: yup.string().required(CONTENT_REQUIRED),
});





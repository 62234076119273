import { Route, Routes } from 'react-router-dom';
import { AllList } from './List/List';

import { ViewCms } from './View/View';
import { EditCms } from './Edit/Edit';
import { AddCms } from './Add/Add';


export const EmailContent = () => {

    return (  
        <Routes>
            <Route path="/" element={<AllList />} />
            <Route path="/view/:cms_id" element={<ViewCms />} /> 
            <Route path="/edit/:cms_id" element={<EditCms />} /> 
            <Route path="/add" element={<AddCms  exect={true} />} />
        </Routes>
    )
};
export const roles  = {
    CUSTOMER  :'customer',
    SUPERADMIN  :'SUPER_ADMIN'
}

export const onboardingAnswerType  = {
    FREQUENTLY  :'Frequently',
    RARELY  :'Rarely',
    SOMETIMES  :'Sometimes',
    IDONTKNOW  :'I don\'t know'
}

